import React, { ReactElement, ReactNode } from 'react'
import { Panel, Loader } from 'rsuite'

import './Card.css'

type Props = {
  children: ReactNode,
  loading?: boolean,
  appearance?: 'centred',
  span?: number,
}

/**
 * A card component
 * @param {Props} props
 * @return {ReactElement}
 */
function Card (props: Props): ReactElement {
  const {
    children,
    loading,
    appearance,
    span = 1,
  } = props

  const classes = ['card', `-span-${span}`]
  if (appearance) {
    classes.push(`-${appearance}`)
  }

  return (
    <Panel bordered bodyFill className={classes.join(' ')}>
      {loading && <Loader center backdrop />}
      {children}
    </Panel>
  )
}

export {
  Card
}
