/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  DatePicker, SelectPicker, Table
} from 'rsuite'

import dayjs from 'dayjs'
import moment from 'moment-timezone'
import parsePhoneNumber from 'libphonenumber-js'
import humanize from 'humanize-plus'

import { getAdminLocations, getPaymentsReport } from '../../services/graphql/queries'
import { useApi, usePaginatedApi } from '../../app/hooks'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'
import { ProductTypes } from './options'

const { Column, HeaderCell, Cell } = Table

const defaultColumns = [
  {
    key: 'processed',
    label: 'Transaction date',
  },
  {
    key: 'location',
    label: 'Car park',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'surcharge',
    label: 'Surcharge',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
]

function PaymentsReport () {
  const [sortColumn, setSortColumn] = React.useState('name')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [startdate, setStartdate] = React.useState<Date | null>(null)
  const [enddate, setEnddate] = React.useState<Date | null>(null)
  const [carpark, setCarpark] = React.useState<String | null>(null)
  const [type, setType] = React.useState<String | null>(null)

  const dataQuery = useApi(getPaymentsReport)
  const res = dataQuery.getResponse()

  const locationsApi = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 1000,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  React.useEffect(() => {
    if (carpark && startdate && enddate) {
      dataQuery.sendRequest({
        location: carpark,
        type,
        from: startdate ? moment.parseZone(startdate).startOf('day').utc().format() : null,
        to: enddate ? moment.parseZone(enddate).endOf('day').utc().format() : null,
      })
    }
    return () => {
      dataQuery.cleanup()
    }
  }, [carpark, startdate, enddate, type])

  const formatPhone = (val: string) => {
    const ret = parsePhoneNumber(val, 'AU')
    if (ret) {
      return ret.formatInternational()
    }
    return val
  }

  const data = res.data ? res.data.map((p: any) => ({
    ...p,
    created: dayjs(p.entrytime).format('YYYY-MM-DD'),
    phone: formatPhone(p.phone || ''),
    amount: humanize.formatNumber(p.amount || 0, 2),
    surcharge: humanize.formatNumber(p.surcharge || 0, 2),
  })) : []

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return data.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return data
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }
  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `TransactionsReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div>
      <div style={{
        display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
      }}
      >
        <div style={{ width: 200 }}>
          Car park
          <SelectPicker
            onChange={setCarpark}
            value={carpark}
            data={(locationsApi.currentPage || []).map((l) => ({ label: l.name, value: l.id }))}
            loading={locationsApi.loading}
          />
        </div>
        <div style={{ width: 200 }}>
          Type
          <SelectPicker
            onChange={setType}
            value={type}
            data={ProductTypes}
          />
        </div>
        <div style={{ width: 200 }}>
          From date
          <DatePicker
            onChange={(date: Date | null) => setStartdate(date)}
            value={startdate}
          />
        </div>
        <div style={{ width: 200 }}>
          To date
          <DatePicker onChange={setEnddate} value={enddate} />
        </div>
        <DownloadTableToCsv
          style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
          url={URL.createObjectURL(dataExport)}
          fileName={dataExport.name}
          text="Download to file"
        />
      </div>
      {(carpark) ? (
        <Table
          loading={res.loading || loading}
          hover={false}
          showHeader
          autoHeight
          data={getData()}
          sortColumn={sortColumn}
          sortType={sortType}
          defaultSortType="asc"
          onSortColumn={handleSortColumn}
          bordered
          headerHeight={40}
          rowHeight={46}
        >
          {defaultColumns.map((column) => {
            const { key, label, ...rest } = column
            return (
              <Column {...rest} key={key} flexGrow={1} fullText sortable>
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={key} />
              </Column>
            )
          })}
        </Table>
      ) : <p style={{ margin: 20 }}>Car park, to and from are not selected</p>}
    </div>
  )
}

export {
  PaymentsReport
}
