import { VoucherType } from '../../types/enums'

const voucherOptions = [
  {
    label: 'Single Use',
    value: VoucherType.SINGLE,
  },
  {
    label: 'Period',
    value: VoucherType.PERIOD,
  },
]

const voucherFilters = [
]

export {
  voucherOptions,
  voucherFilters
}
