import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button, Input, Panel, TagPicker
} from 'rsuite'

import { useApi, usePaginatedApi } from '../../app/hooks'
import { getLocation, getLocationVehicles } from '../../services/graphql/queries'
import './LocationOccupancy.css'
import { LocationOccupancyTable } from './LocationOccupancyTable'
import { VehicleAccess } from '../../types/enums'

/**
 * Location Occupancy
 * @return {ReactElement}
 */
function LocationOccupancy (): ReactElement {
  const { locationId } = useParams()

  const fetchApi = useApi(getLocation)
  const fetchRes = fetchApi.getResponse()
  const [searchString, setSearchString] = React.useState('')
  const [flags, setFlags] = React.useState<VehicleAccess[]>([])

  useEffect(() => {
    if (locationId) {
      fetchApi.sendRequest({ locationId })
    }
    return () => {
      fetchApi.cleanup()
    }
  }, [])

  const locationQuery = usePaginatedApi({
    query: getLocationVehicles,
    fetchParams: {
      location: locationId || '',
      rego: searchString ?? undefined,
      access: flags as any ?? undefined,
    },
    itemsPerPage: 20,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const tagPickerOptions = Object.values(VehicleAccess).map((e) => ({
    label: e,
    value: e,
  }))

  /**
   *  Resets the query data and makes a new request with
   *  the search string in the search bar
   *  @param {String?} query          - The search string
   *  @param {String[]?} filterFlags  - The flags to filter users by, leave null if all flags are wanted
   */
  const performSearch = (rego: string | null, accessFlags: string[] | null) => {
    locationQuery.reset()
    const hook = locationQuery.apiHook
    hook.sendRequest({
      location: locationId || '',
      rego: rego ?? undefined,
      access: accessFlags as any ?? undefined,
    })
  }

  /**
   *  Cb when the search button is clicked
   */
  const searchOccupancy = () => {
    performSearch(searchString, flags)
  }

  /**
   *  Cb when the tag picker value changes
   *  @param {String[]} value - The values chosen in the TagPicker
   */
  const onTagPickerChange = (value:string[]) => {
    setFlags(value as any)
  }

  /**
   * Render title for location occupancy
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderName = (location: any): ReactElement => (
    <div>
      List of vehicles in&nbsp;
      {location.name}
      &nbsp; car park at&nbsp;
      {new Date().toLocaleString()}
    </div>
  )

  const locationQueryPage = locationQuery.currentPage

  return (
    <Panel
      className="location-occupancy-list location-panel"
      header={(
        <h2>Car park occupancy</h2>
      )}
    >
      { fetchRes.data && renderName(fetchRes.data) }
      <br />
      <div className="search">
        <div className="flag-filter">
          {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
          <label htmlFor="flags">Filter by access: </label>
          <TagPicker
            id="flags"
            name="flags"
            preventOverflow
            value={flags}
            data={tagPickerOptions}
            onChange={onTagPickerChange}
          />
        </div>
        <div className="search-bar">
          <div className="input">
            {/* eslint-disable-next-line  jsx-a11y/label-has-associated-control */}
            <label htmlFor="search">Search rego:</label>
            <Input
              onKeyDown={(e) => {
                if (e.code === 'Enter') searchOccupancy()
              }}
              value={searchString}
              onChange={(s) => setSearchString(s)}
              name="search"
              id="search"
              type="text"
            />
          </div>
          <div className="button">
            <Button onClick={searchOccupancy}>Search</Button>
          </div>
        </div>
      </div>
      <LocationOccupancyTable
        vehicles={locationQueryPage || []}
        loading={locationQuery.loading}
        nextPage={locationQuery.next || undefined}
        prevPage={locationQuery.prev || undefined}
      />
    </Panel>
  )
}

export {
  LocationOccupancy
}
