import React, { ReactElement } from 'react'
import { Whisper, Tooltip } from 'rsuite'

type Props = {
  children: ReactElement<any, any>|string,
}

/**
 * A tooltip to mark a UI element as inoperative during development
 * @param {Props} props
 * @return {ReactElement}
 */
function Inop (props: Props): ReactElement {
  let { children } = props
  const tooltip = (
    <Tooltip>Inoperative</Tooltip>
  )

  if (typeof children === 'string') {
    children = <span>{children}</span>
  }

  return (
    <Whisper placement="right" trigger="hover" speaker={tooltip}>
      {children}
    </Whisper>
  )
}

export {
  Inop
}
