import React from 'react'
import './AdminDashboardSection.css'

type SectionType = 'profile' | 'subscriptions' | 'vehicles'

/**
 * Prop types for {@link CustomerDashboardSection}
 */
type AdminDashboardSectionProps = {
  /** Specifies the section of the dashboard */
  sectionType: SectionType
  /** The href of the 'manage' link displayed in the header */
  // manageRoute: string
  /** Section contents */
  children?: React.ReactNode
  description: string
  title: string
}

const cssVariant: Record<SectionType, string> = {
  profile: '-profile',
  subscriptions: '-subscription',
  vehicles: '-vehicle',
}

/**
 * A layout helper for sections of the customer dashboard
 */
function AdminDashboardSection (props: AdminDashboardSectionProps): React.ReactElement {
  const {
    title, description, sectionType, children,
  } = props
  return (
    <div className="dashboard-section">
      <div className="dashboard-section-header">
        <div>
          <div className="title">
            {title}
          </div>
          <div className="description">
            {description}
          </div>
        </div>
      </div>
      <div className={`dashboard-section-content ${cssVariant[sectionType]}`}>
        {children}
      </div>
    </div>
  )
}

export {
  AdminDashboardSection,
  type AdminDashboardSectionProps
}
