import React, { ReactElement } from 'react'

import { useUser } from '../../app/hooks'
import { UserRole } from '../../types/enums'
import { NotAuthorised } from '../error-pages/NotAuthorised'

type Props = {
  children: JSX.Element|Array<JSX.Element>,
  role?: UserRole,
  roles?: Array<UserRole>,
}

/**
 * Show an error message if the user doesn't have one of the required roles
 * @return {ReactElement}
 */
function RequireRole (props: Props): ReactElement {
  const { children, role, roles } = props
  const user = useUser()
  const roleList = role ? [role] : roles || []

  if (!user.hasRole(...roleList)) return <NotAuthorised />

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export {
  RequireRole
}
