import React from 'react'
import { Button, ButtonGroup } from 'rsuite'

export type PaginationControlProps = {
  prevPage?: () => void
  nextPage?: () => void
}

/**
 * Controls for pagination
 *
 * @param {PaginationControlProps} props
 * @param {()=>{}?} props.nextPage  - Callback when next button is clicked
 *                                    next button will be disabled if not provided
 * @param {()=>{}?} props.prevPage  - Callback when prev button is clicked
 *                                    prev button will be disabled if not provided
 */
export function PaginationControls (props:PaginationControlProps): React.ReactElement {
  const { prevPage, nextPage } = props
  return (
    <ButtonGroup>
      <Button disabled={!prevPage} onClick={() => prevPage?.()}>
        Prev
      </Button>
      <Button disabled={!nextPage} onClick={() => nextPage?.()}>
        Next
      </Button>
    </ButtonGroup>
  )
}
