import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { LoginPage } from './routes/login'
import { CustomerRouter } from './routes/customer'
import { AdminRouter } from './routes/admin'
import { useSelector, useDispatch } from './app/hooks'
import { userSelectors, userActions } from './store/user/slice'
import { OperatorRouter } from './routes/operator'
import { AttendantRouter } from './routes/attendant'

function Router () {
  const dispatch = useDispatch()
  const user = useSelector(userSelectors.getUser)

  useEffect(() => {
    if (!user) dispatch(userActions.hydrate())
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<CustomerRouter />} />
        <Route path="/admin/*" element={<AdminRouter />} />
        <Route path="/operator/*" element={<OperatorRouter />} />
        <Route path="/attendant/*" element={<AttendantRouter />} />
        <Route path="/login/*" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export {
  Router
}
