import React from 'react'
import { Modal, Button, ButtonToolbar } from 'rsuite'

import { SubscriptionDTO } from '../../types/subscription'

type Props = {
  onConfirm: () => void,
  onClose: () => void,
  subscription?: SubscriptionDTO,
  endDate: string|null,
  saving: boolean,
}

/**
 * Modal component displayed when user tries to
 * subscribe without any payment methods
 * @param {Props} props
 * @param {boolean} props.open - Whether this modal is opened or not
 * @param {Function} props.onClose - Cb called when modal is closed
 * @param {Function} props.onOk - Cb called when user clicks ok
 */
function SubscriptionCancellationModal (props: Props) {
  const {
    onConfirm, onClose, subscription, endDate = '[loading]', saving,
  } = props

  const formattedEndDate = endDate && (new Date(endDate)).toLocaleDateString()
  const locationName = subscription?.location?.name

  return (
    <>
      <Modal.Header>
        <Modal.Title>Are you sure you want to cancel your subscription?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`If you continue, your subscription for ${locationName} will be cancelled and you will
          no longer be billed, however, you will still be able to continue parking at ${locationName}
          until the end of the billing period, on ${formattedEndDate}.`}
        </p>
        <p>
          You can resubscribe at any time, however, due to limited car park spaces, a new subscription
          request may not be approved straight away.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={onConfirm} appearance="primary" loading={saving}>
            Cancel my subscription
          </Button>
          <Button onClick={onClose} appearance="subtle">Don&apos;t cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  SubscriptionCancellationModal
}
