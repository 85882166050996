import { State } from '../../types/enums'

const states = [
  {
    label: 'Tasmania',
    value: State.TAS,
  },
  {
    label: 'Victoria',
    value: State.VIC,
  },
  {
    label: 'New South Wales',
    value: State.NSW,
  },
  {
    label: 'South Australia',
    value: State.SA,
  },
  {
    label: 'Australian Capital Territory',
    value: State.ACT,
  },
  {
    label: 'Queensland',
    value: State.QLD,
  },
  {
    label: 'Western Australia',
    value: State.WA,
  },
  {
    label: 'Northern Territory',
    value: State.NT,
  },
]

export {
  states
}
