import React, { ReactElement } from 'react'
import { Panel } from 'rsuite'

import { usePaginatedApi } from '../../app/hooks'
import { getLocationVehicles } from '../../services/graphql/queries'
import { LocationOccupancyTable } from './LocationOccupancyTable'

/**
 * Location Occupancy
 * @return {ReactElement}
 */
function LocationOccupancyAttendant (): ReactElement {
  const locationQuery = usePaginatedApi({
    query: getLocationVehicles,
    fetchParams: {
      location: '',
    },
    itemsPerPage: 20,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const locationQueryPage = locationQuery.currentPage

  return (
    <Panel
      className="location-occupancy-list location-panel"
      header={(
        <h2>Car park occupancy</h2>
      )}
    >
      <LocationOccupancyTable
        vehicles={locationQueryPage || []}
        loading={locationQuery.loading}
        nextPage={locationQuery.next || undefined}
        prevPage={locationQuery.prev || undefined}
      />
    </Panel>
  )
}

export {
  LocationOccupancyAttendant
}
