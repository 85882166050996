import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, ButtonToolbar } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { UserErrorCode as Errors } from '../types/enums'
import type { FormProps } from '../types/form'

/**
 * Login form component
 * @return {ReactEelement}
 */
function LoginForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    loading,
    error,
  } = props

  const validator = new Validator({
    email: {
      model: schemas.email('Please enter your email address to log in'),
      errors: [Errors.BAD_USERNAME],
    },
    password: {
      model: schemas.required('Please enter your account password'),
      errors: [Errors.BAD_PASSWORD],
    },
  })

  return (
    <ValidatedForm
      fluid
      validator={validator}
      formValue={formValue}
      onSubmit={onSubmit}
      onChange={onChange}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="email">
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="email" type="email" />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.ControlLabel>Password</Form.ControlLabel>
        <Form.Control name="password" type="password" autoComplete="off" />
        <p>
          Forgot your password?
          <Button appearance="link" as={Link} to="reset-password">Reset it</Button>
        </p>
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" loading={loading} type="submit">Sign in</Button>
        </ButtonToolbar>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  LoginForm
}
