/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  Panel, SelectPicker
} from 'rsuite'

import { UserRole } from '../../types/enums'
import { RequireRole } from '../access/RequireRole'
import { VehicleAccessReport } from './VehicleAccessReport'
import { AccessListReport } from './AccessListReport'
import { PostpaidVehicleAccessReport } from './PostpaidVehicleAccessReport'
import { VehicleReadExceptionsReport } from './VehicleReadExceptionsReport'

const reportdata = [
  'Access list',
  'Chargeback access',
  'Vehicle access',
  'Read exceptions',
].map(
  (item) => ({ label: item, value: item })
)

function AdminOperationsReportsDetails () {
  const [report, setReport] = React.useState(null)

  // eslint-disable-next-line react/jsx-filename-extension
  let component = <div style={{ margin: 20 }}>No report selected</div>
  if (report === 'Vehicle access') {
    component = <VehicleAccessReport />
  }
  if (report === 'Chargeback access') {
    component = <PostpaidVehicleAccessReport />
  }
  if (report === 'Read exceptions') {
    component = <VehicleReadExceptionsReport />
  }
  if (report === 'Access list') {
    component = <AccessListReport />
  }

  return (
    <RequireRole roles={[UserRole.ORGANISATION, UserRole.ADMIN]}>
      <Panel header={(<h2>Operations reports</h2>)}>
        <div style={{ width: 200, marginBottom: 12 }}>
          Select a report:
          <SelectPicker onChange={setReport} value={report} data={reportdata as any} block />
        </div>
        <hr />

        {component}

      </Panel>
    </RequireRole>
  )
}

export {
  AdminOperationsReportsDetails
}
