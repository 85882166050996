import React, { ReactElement, useEffect } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Panel, Loader } from 'rsuite'

import {
  useApi, useApiGroup, useFormState, usePageTitle, useUser
} from '../../app/hooks'
import { PanelHeader } from '../../components'
import {
  createTouchscreen,
  getTouchscreen,
  updateTouchscreen
} from '../../services/graphql/queries/touchscreens'
import { CreateTouchscreenParams } from '../../types/touchscreen'
import { LocationTouchscreenForm } from '../../forms/LocationTouchscreenForm'
import { NotFound } from '../error-pages/NotFound'

function LocationTouchscreenDetails () : ReactElement {
  const { locationId, touchscreenId } = useParams()
  const user = useUser()
  const navigate = useNavigate()

  const api = useApiGroup({
    fetch: useApi(getTouchscreen),
    create: useApi(createTouchscreen),
    update: useApi(updateTouchscreen),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = touchscreenId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  useEffect(() => {
    if (touchscreenId) {
      api.requests.fetch.sendRequest({ touchscreenId })
    }
    return api.cleanup
  }, [])

  const initialValues = touchscreenId ? fetchResponse.data : { name: '' }

  const [isLoading, setIsLoading] = React.useState(true)
  const [formData, setField, resetForm] = useFormState(initialValues)

  // returns loading = false when its still loading?
  useEffect(() => {
    if (!api.requests.fetch.getResponse().loading) {
      setIsLoading(false)
      resetForm(touchscreenId ? fetchResponse.data : { name: '' })
    }
  }, [api.requests.fetch.getResponse().loading])

  const pageTitle = touchscreenId ? 'Edit touchscreen' : 'New touchscreen'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/${user.getRoleForRoute()}/locations/${locationId}`} />
  }

  const save = () => {
    const touchscreen = { ...formData, location: locationId } as CreateTouchscreenParams

    if (touchscreenId) {
      api.requests.update.sendRequest({ id: touchscreenId, ...touchscreen })
    } else {
      api.requests.create.sendRequest(touchscreen)
    }
  }

  const cancel = () => {
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
  }

  const formId = 'touchscreen-details-form'
  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {isLoading
        ? <Loader center content="Loading..." />
        : (
          <LocationTouchscreenForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={touchscreenId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  LocationTouchscreenDetails
}
