import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { VehicleList } from '../../features/vehicles/VehicleList'
import { VehicleDetails } from '../../features/vehicles/VehicleDetails'

/**
 * Vechicles route
 * @return {ReactElement}
 */
function VehiclesPage (): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<VehicleList />} />
      <Route path="/new" element={<VehicleDetails />} />
      <Route path="/:vehicleId" element={<VehicleDetails />} />
    </Routes>
  )
}

export {
  VehiclesPage
}
