import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SITE_TITLE = 'Noover'

/**
 * A hook for updating the current page title
 * @param {string} title - The page title to set
 */
const usePageTitle = (title: string) => {
  const path = useLocation().pathname
  let prefix = SITE_TITLE
  if (path.startsWith('/admin')) prefix += ' Admin'
  useEffect(() => {
    document.title = `${prefix} - ${title}`
    return () => { document.title = SITE_TITLE }
  }, [path, title])
}

export {
  usePageTitle
}
