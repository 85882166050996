import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import { CustomerCreateSubscription } from '../../features/subscriptions/CustomerCreateSubscription'
import { CustomerSubscriptionList } from '../../features/subscriptions/CustomerSubscriptionsList'
import { SubscriptionDatesBilled } from '../../features/subscriptions/SubscriptionDatesBilled'
import { SubscriptionInvoice } from '../../features/subscriptions/SubscriptionInvoice'

/**
 * Subscription route for customers
 * @return {ReactElement}
 */
function SubscriptionsPage (): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<CustomerSubscriptionList />} />
      <Route path="/new" element={<CustomerCreateSubscription />} />
      <Route path="/invoice/:id/:billingDate" element={<SubscriptionInvoice />} />
      <Route path="/invoice/:id" element={<SubscriptionDatesBilled />} />
    </Routes>
  )
}

export {
  SubscriptionsPage
}
