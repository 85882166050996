import { Dispatch, useReducer } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { makeToastFn } from './helpers'
import { ApiError, PaginatedResponse, Query } from '../../types/api'

type tokenState = {
  tokens: (string|null)[],
  cursor: number,
}

type tokensAction =
  | { type: 'increment', token: string | null }
  | { type: 'decrement' }

function tokenReducer (state: tokenState, action: tokensAction) {
  if (action.type === 'increment') {
    const index = state.cursor + 1
    const newTokens = state.tokens
    newTokens[index] = action.token
    return {
      ...state,
      cursor: index,
      tokens: newTokens,
    }
  }
  if (action.type === 'decrement') {
    const index = state.cursor - 1
    return {
      ...state,
      cursor: index,
    }
  }
  throw new Error('Undefined action')
}

/**
 * Manage a paginated query via react-query
 * @returns [reactQuery, [tokenState, tokenDispatch]]
 */
export function usePaginatedQuery <T, Data extends PaginatedResponse<T>, Params> ({
  query,
  params,
}: {
  query: Query<Data, Params>,
  params: Params,
}): [UseQueryResult<Data, ApiError>, tokenState, Dispatch<tokensAction>] {
  const [state, dispatch] = useReducer(tokenReducer, {
    tokens: [null],
    cursor: 0,
  })

  const pQuery = useQuery({
    queryKey: [
      query.name,
      {
        /* defaults */
        limit: 20,
        next: state.tokens[state.cursor],
        ...params,
      }],
    queryFn: makeToastFn(query),
    staleTime: 1000,
  })

  return [pQuery, state, dispatch]
}
