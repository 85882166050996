import React, { ReactElement, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Panel, Loader } from 'rsuite'

import {
  usePageTitle, useFormState, useApi, useUser
} from '../../app/hooks'
import { PanelHeader } from '../../components'
import { AccessListForm } from '../../forms/AccessListForm'
import {
  AccessListDetailsFormValue, AccessListItem
} from '../../types/access-list'
import { NotFound } from '../error-pages/NotFound'

import {
  createAccessList, getAccessList,
  updateAccessList
} from '../../services/graphql/queries/access-lists'
import { AccessListType } from '../../types/enums'

/**
 * Create/edit access list component
 * @return {ReactElement}
 */
function AccessListDetails (): ReactElement {
  const navigate = useNavigate()
  const user = useUser()
  const { accessListId, locationId } = useParams()

  const fetchQuery = useApi(getAccessList)
  const createQuery = useApi(createAccessList)
  const updateQuery = useApi(updateAccessList)

  const fetchResponse = fetchQuery.getResponse()
  const saveResponse = accessListId
    ? updateQuery.getResponse()
    : createQuery.getResponse()

  const initialFormValue: AccessListDetailsFormValue = {
    type: AccessListType.ALLOW,
    name: '',
    items: [] as AccessListItem[],
  }

  const [formData, setField] = useFormState(initialFormValue)
  useEffect(() => {
    if (fetchResponse.data) {
      setField(
        {
          type: fetchResponse.data.type,
          name: fetchResponse.data.name,
          items: fetchResponse.data.items.map((e: any, i) => ({
            value: e,
            description: fetchResponse.data?.descriptions?.[i] || '',
          })),
        }
      )
    }
  }, [fetchResponse.data])

  useEffect(() => {
    if (accessListId) {
      fetchQuery.sendRequest({ accessListId })
    }
    return () => {
      [fetchQuery, createQuery, updateQuery].forEach((e) => e.cleanup())
    }
  }, [])

  const pageTitle = accessListId ? 'Edit access list' : 'New access list'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/${user.getRoleForRoute()}/locations/${locationId}`} />
  }

  /**
   * Create or update a access list using the API
   */
  const save = () => {
    if (accessListId) {
      updateQuery.sendRequest({
        id: accessListId,
        name: formData.name || '',
        type: formData.type,
        items: formData.items.map((e) => e.value),
        location: locationId || '',
        descriptions: formData.items.map((e) => e.description),
      })
    } else {
      createQuery.sendRequest({
        ...formData,
        items: formData.items.map((e) => e.value),
        name: formData.name || '',
        type: formData.type,
        location: locationId || '',
        descriptions: formData.items.map((e) => e.description),
      })
    }
  }

  /**
   * Return to the car park screen
   */
  const cancel = () => {
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
  }

  const formId = 'access-list-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {fetchQuery.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <AccessListForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={accessListId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  AccessListDetails
}
