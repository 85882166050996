import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { OrganisationList } from '../../features/organisations/OrganisationList'
import { OrganisationDetails } from '../../features/organisations/OrganisationDetails'
import { OrganisationCreate } from '../../features/organisations/OrganisationCreate'
import { OrganisationGroupDetails } from '../../features/organisations/OrganisationGroupDetails'
import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { LocationOccupancyOrganisation } from '../../features/locations/LocationOccupancyOrganisation'

/**
 * OrganisationsPage route
 * @return {ReactElement}
 */
function OrganisationsPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN, UserRole.ORGANISATION, UserRole.OPERATOR]}>
      <Routes>
        <Route path="/" element={<OrganisationList />} />
        <Route path="/new" element={<OrganisationCreate />} />
        <Route path="/:organisationId" element={<OrganisationDetails />} />
        <Route path="/:organisationId/groups/new" element={<OrganisationGroupDetails />} />
        <Route path="/:organisationId/groups/:groupId" element={<OrganisationGroupDetails />} />
        <Route
          path="/:organisationId/groups/:groupId/v/new"
          element={<OrganisationGroupDetails />}
        />
        <Route
          path="/:organisationId/groups/:groupId/v/:vehicleId"
          element={<OrganisationGroupDetails />}
        />
        <Route path="/:organisationId/occupancy/*" element={<LocationOccupancyOrganisation />} />
      </Routes>
    </RequireRole>
  )
}

export {
  OrganisationsPage
}
