import React, { ReactNode, ReactElement } from 'react'

/**
 * Check if a React child element is a React component
 * @param {ReactNode} elem
 * @return {boolean}
 */
const isReactElement = (elem: ReactNode): elem is ReactElement => (
  (<ReactElement>elem)?.type !== undefined
)

/**
 * Get the props of a React child element, if any
 * @param {ReactNode} elem
 * @return {string[]}
 */
const getPropTypes = (elem: ReactNode): string[] => {
  if (!isReactElement(elem) || typeof elem.type !== 'object') {
    return []
  }
  const type = elem.type as { propTypes: Record<string, any> }
  return Object.keys(type.propTypes)
}

/**
 * Clone a React element and apply props that it accepts
 * @param {ReactNode} elem - The original element to clone
 * @param {Record} props   - The props to add to the cloned element. Any props that element
 *                           is not expecting will be ignored
 * @return {ReactNode}     - The cloned element
 */
const cloneWithProps = (elem: ReactNode, props: Record<string, any>): ReactNode => {
  const propTypes = getPropTypes(elem)
  if (!propTypes.length) {
    return elem
  }

  const childProps: Record<string, any> = Object.fromEntries(
    Object.entries(props).filter(([key]) => (
      // Form elements with the accepter prop can't advertise which props the accepter will be passed
      propTypes.includes(key) || propTypes.includes('accepter')
    ))
  )
  return React.cloneElement(elem as any, childProps)
}

export {
  cloneWithProps
}
