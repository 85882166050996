import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Panel,
  Button,
  ButtonToolbar,
  Loader
} from 'rsuite'

import {
  usePageTitle, useUser, useApi, useModal
} from '../../app/hooks'
import { getUserVehicles, removeVehicle } from '../../services/graphql/queries'
import {
  Card, CardGrid, ConfirmModal, Modal
} from '../../components'
import { states } from './options'
import { Vehicle } from '../../types/vehicle'

import './VehicleList.css'
import { deleteFromPaginatedWithId } from '../../helpers'

/**
 * Vehicle list component
 * @return {ReactElement}
 */
function VehicleList (): ReactElement {
  usePageTitle('Vehicles')
  const vehicleQuery = useApi(getUserVehicles)
  const deleteQuery = useApi(removeVehicle)

  const modal = useModal()
  const [vehicleForRemoval, setVehicleForRemoval] = useState<Vehicle>()

  const user = useUser()
  const vehicleData = vehicleQuery.getResponse()

  useEffect(() => {
    vehicleQuery.sendRequest({ userId: user.id })
    return () => {
      vehicleQuery.cleanup()
      deleteQuery.cleanup()
    }
  }, [])

  /**
   * Remove a vehicle from the user's account
   */
  const remove = () => {
    if (vehicleForRemoval) {
      deleteQuery.sendRequest({ vehicleId: vehicleForRemoval.id }, (returnedVehicle: any) => {
        vehicleQuery.setData((data) => deleteFromPaginatedWithId(data, vehicleForRemoval.id))
        return returnedVehicle
      })
    }
  }

  /**
   * Show the vehicle removal prompt modal
   * @param {Vehicle} vehicle - The vehicle to be removed
   */
  const confirmRemoval = (vehicle: Vehicle) => {
    setVehicleForRemoval(vehicle)
    modal.show()
  }

  if (vehicleData.loading) {
    return <Loader center content="Loading..." />
  }

  return (
    <>
      <Panel header={<h2>Vehicle management</h2>} className="vehicle-list">
        <p className="intro">
          There’s no limit to the number of vehicles you can
          have on your account. The number of parking bays available to you can be managed in
          {' '}
          <Link to="/subscriptions">Subscriptions</Link>
          .
        </p>
        <CardGrid>
          {vehicleData.data && vehicleData.data.items.length ? (
            <>
              {vehicleData.data.items.map((vehicle: Vehicle) => (
                <Card key={vehicle.id} loading={deleteQuery.getParams()?.vehicleId === vehicle.id}>
                  <div className="vehicle-card-header">
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    {vehicle.rego.split('').map((c, i) => <span key={i + c}>{c}</span>)}
                  </div>
                  <Panel>
                    <div className="vehicle-card-descrip">
                      <div className="vehicle-detail-header">{vehicle.make}</div>
                      <div className="vehicle-detail">{vehicle.model}</div>
                      <div className="vehicle-detail">
                        {states.find(({ value }) => vehicle.state === value)?.label}
                      </div>
                      <div className="vehicle-detail">{vehicle.colour}</div>
                    </div>
                  </Panel>
                  <ButtonToolbar>
                    <Button to={vehicle.id} as={Link} appearance="primary">Edit</Button>
                    <Button onClick={() => confirmRemoval(vehicle)}>Delete</Button>
                  </ButtonToolbar>
                </Card>
              ))}
              <Card appearance="centred">
                <ButtonToolbar>
                  <Button to="new" as={Link} appearance="primary">Add vehicle</Button>
                </ButtonToolbar>
              </Card>
            </>
          ) : (
            <Card appearance="centred" span={2}>
              <Panel>
                <p>Add a vehicle to start using Noover</p>
                <ButtonToolbar>
                  <Button to="new" as={Link} appearance="primary">Add vehicle</Button>
                </ButtonToolbar>
              </Panel>
            </Card>
          )}
        </CardGrid>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`Your ${vehicleForRemoval?.make} ${vehicleForRemoval?.model} will be removed from
            your account.`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  VehicleList
}
