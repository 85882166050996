import React, { ReactElement } from 'react'
import { Content } from 'rsuite'

import { BaseLayout } from './BaseLayout'

type Props = {
  children: React.ReactNode,
  header?: boolean,
}

/**
 * Full width layout
 * @param {Props} props
 * @return {ReactElement}
 */
function FullWidthLayout (props: Props): ReactElement {
  const { children, header } = props
  return (
    <BaseLayout header={header}>
      <Content>
        {children}
      </Content>
    </BaseLayout>
  )
}

export {
  FullWidthLayout
}
