import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { LocationOccupancyAttendant } from '../../features/locations/LocationOccupancyAttendant'

/**
 * UserSearchPage route
 * @return {ReactElement}
 */
function OccupancyPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ATTENDANT, UserRole.OPERATOR, UserRole.ADMIN]}>
      <Routes>
        <Route path="/" element={<LocationOccupancyAttendant />} />
      </Routes>
    </RequireRole>
  )
}

export {
  OccupancyPage
}
