import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireLogin } from '../features/access/RequireLogin'
import { RequireRole } from '../features/access/RequireRole'
import { SidebarLayout } from '../layouts/SidebarLayout'
import { NotFound } from '../features/error-pages/NotFound'
import { UserRole } from '../types/enums'
import { AttendantMenu } from '../features/navigation/AttendantMenu'
import { UserSearchPage } from './attendant/users'
import { OccupancyPage } from './attendant/occupancy'

function AttendantRouter (): ReactElement {
  return (
    <RequireLogin>
      <RequireRole roles={[UserRole.ATTENDANT]}>
        <SidebarLayout menu={<AttendantMenu />}>
          <Routes>
            <Route path="/users/*" element={<UserSearchPage />} />
            <Route path="/occupancy/*" element={<OccupancyPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SidebarLayout>
      </RequireRole>
    </RequireLogin>
  )
}

export {
  AttendantRouter
}
