import { createQuery } from '../helpers'
import type {
  CreateTariffParams,
  TariffConfig,
  RemoveTariffParams,
  PublishTariffParams,
  UnpublishTariffParams,
  UpdateTariffParams,
  GetLocationTariffParams,
  Tariff,
  TestTariffResult,
  TestTariffParams
} from '../../../types/tariff'

import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  name
  group
  organisation
  location
  cameraGroup
  days
  date
  hours
  entryHours
  exitHours
  fixedFees
  fixedGrace
  tariffAssignmentType
  tariffRateType
  tariffDateType
  incrementalRate
  invoiceType
  status
`

const getLocationTariffs: Query<PaginatedResponse<TariffConfig>, GetLocationTariffParams > = createQuery(`
  query getLocationTariffs($locationId: ID!, $limit: Int, $next: String) {
    getLocationTariffs (location: $locationId, limit: $limit, next: $next ) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const getTariffConfig: Query<Tariff, { tariffId: string }> = createQuery(`
  query getTariffConfig($tariffId: ID!) {
    getTariffConfig (id: $tariffId) { ${returnProps} }
  }
`)

const createTariff: Query<TariffConfig, CreateTariffParams> = createQuery(`
  mutation createTariff(
    $location: ID!,
    $name: String!,
    $group: String,
    $organisation: String,
    $cameraGroup: String,
    $days: [String],
    $date: String,
    $hours: [String],
    $entryHours: [String],
    $exitHours: [String],
    $fixedFees: String,
    $fixedGrace: String,
    $tariffAssignmentType: String,
    $tariffRateType: String,
    $tariffDateType: String,
    $incrementalRate: String,
    $invoiceType: String,
    $status: TariffStatus
  ) {
    createTariff(
      location: $location,
      name: $name,
      group: $group,
      organisation: $organisation,
      cameraGroup: $cameraGroup,
      days: $days,
      date: $date,
      hours: $hours,
      entryHours: $entryHours,
      exitHours: $exitHours,
      fixedFees: $fixedFees,
      fixedGrace: $fixedGrace,
      tariffAssignmentType: $tariffAssignmentType,
      tariffRateType: $tariffRateType,
      tariffDateType: $tariffDateType,
      incrementalRate: $incrementalRate,
      invoiceType: $invoiceType,
      status: $status
    ) { ${returnProps} }
  }
`)

const updateTariff: Query<Tariff, UpdateTariffParams> = createQuery(`
  mutation editTariff(
    $tariffId: ID!,
    $location: ID!,
    $name: String!,
    $group: String,
    $organisation: String,
    $cameraGroup: String,
    $days: [String],
    $date: String,
    $hours: [String],
    $entryHours: [String],
    $exitHours: [String],
    $fixedFees: String,
    $fixedGrace: String,
    $tariffAssignmentType: String,
    $tariffRateType: String,
    $tariffDateType: String,
    $incrementalRate: String,
    $invoiceType: String,
    $status: TariffStatus
  ) {
    editTariff(
      id: $tariffId,
      location: $location,
      name: $name,
      group: $group,
      organisation: $organisation,
      cameraGroup: $cameraGroup,
      days: $days,
      date: $date,
      hours: $hours,
      entryHours: $entryHours,
      exitHours: $exitHours,
      fixedFees: $fixedFees,
      fixedGrace: $fixedGrace,
      tariffAssignmentType: $tariffAssignmentType,
      tariffRateType: $tariffRateType,
      tariffDateType: $tariffDateType,
      incrementalRate: $incrementalRate,
      invoiceType: $invoiceType,
      status: $status
    ) { ${returnProps} }
  }
`)

const removeTariff: Query<void, RemoveTariffParams> = createQuery(`
  mutation removeTariff($tariffId: ID!) {
    removeTariff(id: $tariffId)
  }
`)

const publishTariff: Query<void, PublishTariffParams> = createQuery(`
  mutation publishTariff($tariffId: ID!) {
    publishTariff(id: $tariffId)
  }
`)

const unpublishTariff: Query<void, UnpublishTariffParams> = createQuery(`
  mutation unpublishTariff($tariffId: ID!) {
    unpublishTariff(id: $tariffId)
  }
`)

const testTariffReturnProps = `
  id
  location
  name
  tariffRateType
  tariffRate
  time
  charge
  invoiceType
  status
`

const getTestTariff: Query<TestTariffResult, TestTariffParams> = createQuery(`
  query getTestTariff(
    $tariffId: ID!,
    $locationId: ID!,
    $entryDateTime: String!,
    $exitDateTime: String!,
    $group: ID,
    $organisation: ID,
    $cameraGroup: String
  ) {
    getTestTariff(
      tariffId: $tariffId,
      locationId: $locationId,
      entryDateTime: $entryDateTime,
      exitDateTime: $exitDateTime,
      group: $group,
      organisation: $organisation,
      cameraGroup: $cameraGroup,
    ) { ${testTariffReturnProps} }
  }
`)

export {
  getLocationTariffs,
  createTariff,
  updateTariff,
  publishTariff,
  unpublishTariff,
  getTariffConfig,
  removeTariff,
  getTestTariff
}
