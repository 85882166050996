import type {
  ApiRequest,
  ApiCaller,
  ApiResponse,
  Query
} from '../../types/api'

/**
 * Extract the query name from a GraphQL query string
 * @param {string} query
 * @return {string}
 */
const getQueryName = (query: string): string => {
  const match = query.match(/^\s*(?:query|mutation) (\w+)(?:\(| \{)/)
  if (!match || match.length <= 1) {
    throw new Error('Unable to find query name')
  }
  return match[1]
}

/**
 * Create an API query function
 * @param {string} query - The GraphQL query string
 * @return {Function} - An API query function that accepts parameters and returns a request function
 */
const createQuery = <Type, Params>(query: string): Query<Type, Params> => (
  (params?: Params): ApiRequest<Type> => (
    (caller: ApiCaller): ApiResponse<Type> => caller(query, params || {})
  )
)

export {
  getQueryName,
  createQuery
}
