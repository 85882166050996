import React, { useEffect, useState } from 'react'
import {
  Button, ButtonToolbar, Loader, Panel
} from 'rsuite'
import { useNavigate, useParams } from 'react-router-dom'

import { DefinitionList, PanelHeader } from '../../components'
import { ReviewSubscriptionAction } from '../../types/enums'
import { useApi } from '../../app/hooks'
import {
  getSubscriptionById,
  approveSubscriptionModification,
  rejectSubscriptionModification
} from '../../services/graphql/queries'

/**
 *  Feature for admins to review subscriptions
 */
function AdminModifiedReviewSubscription ():React.ReactElement {
  const navigate = useNavigate()

  const [approveLoad, setApproveLoad] = useState(false)
  const [rejectLoad, setRejectLoad] = useState(false)

  /**
   * When the user clicks the cancel button
   */
  const cancelCb = () => {
    navigate(-1)
  }

  const { id } = useParams()

  const subApi = useApi(
    getSubscriptionById,
    {
      displayErrorAlerts: false,
      cleanUpOnDismount: true,
      initialFetch: true,
      initialFetchParams: { subscriptionId: id || 'unknown ID' },
    }
  )
  const subApiRes = subApi.getResponse()

  const approveApi = useApi(
    approveSubscriptionModification,
    {
      displayErrorAlerts: false,
      cleanUpOnDismount: true,
    }
  )

  const rejectApi = useApi(
    rejectSubscriptionModification,
    {
      displayErrorAlerts: false,
      cleanUpOnDismount: true,
    }
  )

  const approveApiRes = approveApi.getResponse()
  const rejectApiRes = rejectApi.getResponse()

  const currentSubscription = subApiRes.data

  const title = currentSubscription?.plan?.name || 'Unknown subscription'

  useEffect(() => {
    if (approveApiRes.data !== null || rejectApiRes.data !== null) {
      navigate(-1)
    }
  }, [approveApiRes.data, rejectApiRes.data])

  /**
   * Review cb when user clicks either reject or accept
   * @param {ReviewSubscriptionAction} action - The desired action to take with the review
   */
  const reviewCb = (action:ReviewSubscriptionAction) => {
    if (currentSubscription) {
      if (approveLoad || rejectLoad) {
        return
      }

      if (action === ReviewSubscriptionAction.APPROVE) {
        approveApi.sendRequest({ id: currentSubscription.id })
      }
      if (action === ReviewSubscriptionAction.REJECT) {
        rejectApi.sendRequest({ id: currentSubscription.id })
      }

      // reviewApi.sendRequest({ subscriptionId: currentSubscription.id, action })

      setApproveLoad(action === ReviewSubscriptionAction.APPROVE)
      setRejectLoad(action === ReviewSubscriptionAction.REJECT)
    }
  }

  useEffect(() => {
    if (approveApiRes.error || rejectApiRes.error) {
      setApproveLoad(false)
      setRejectLoad(false)
    }
  }, [approveApiRes.error, rejectApiRes.error])

  const location = currentSubscription?.location
  const numRequested = currentSubscription?.vehicles
  let modificationRequest
  if (currentSubscription) {
    modificationRequest = JSON.parse(currentSubscription?.modificationRequest)
  }

  const data = {
    User: currentSubscription?.user.givenName ?? 'Unknown',
    Location: location?.name ?? 'Unknown',
    Address: location?.address ?? 'Unknown',
    'Current bays': numRequested ?? 'Unknown',
    'Requested bays': ((modificationRequest?.vehicles || 0) - (numRequested || 0)) ?? 'Unknown',
    'Total bays': modificationRequest?.vehicles ?? 'Unknown',
  }

  return (
    <div>
      {
        subApiRes.loading ? <Loader center size="md" />
          : (
            <Panel
              header={<PanelHeader editing header={title} onCancel={cancelCb} />}
            >
              <DefinitionList data={data} />
              <ButtonToolbar>
                <Button
                  color="green"
                  appearance="ghost"
                  disabled={!currentSubscription || rejectLoad}
                  onClick={() => reviewCb(ReviewSubscriptionAction.APPROVE)}
                  loading={approveLoad}
                >
                  Approve
                </Button>
                <Button
                  loading={rejectLoad}
                  color="red"
                  appearance="ghost"
                  disabled={!currentSubscription || approveLoad}
                  onClick={() => reviewCb(ReviewSubscriptionAction.REJECT)}
                >
                  Reject
                </Button>
              </ButtonToolbar>
            </Panel>
          )
      }
    </div>
  )
}

export {
  AdminModifiedReviewSubscription
}
