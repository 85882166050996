/* eslint-disable indent */
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { run } from '../graphql/client'
import { ApiResponse, Query } from '../../types/api'

export const runQuery = <Type, Params = void> (
  query: Query<Type, Params>,
  params: Params
) => query(params)(run)

/**
 * Generate a suitable React query fn from a Query
 * Use the query key to pass in parameters
 * @param query
 */
export const makeFn = <Type, Params = void> (
  query: Query<Type, Params>
) => async ({ queryKey }: { queryKey: [string, Params]}) => {
  const q = query(queryKey[1])(run)
  q.catch((r) => {
    toast.error('Something went wrong! Please try again or contact support')
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(r)
  })
  return q
}

/**
 * Display a toast while running query
 * Use the query key to pass parameters
 * @param name resource name to display to user
 */
export const makeToastFn = <Type, Params = void> (
  query: Query<Type, Params>,
  name: string = 'data'
) => async ({ queryKey }: { queryKey: [string, Params]}): ApiResponse<Type> => {
  const q = query(queryKey[1])(run)
  q.catch((r) => {
    const msg = `Error while fetching ${name}! Please try again.\nIf error persists contact support`
    toast.error(msg)
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(r)
  })
  return q
}
