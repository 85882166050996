import React from 'react'
import './CtaButton.css'

type CtaButtonProps = {
  loading?: boolean,
  inverted?: boolean,
  label?: string,
  variant: 'lg' | 'md'
  icon?: React.ReactElement
  onClick?: ()=>void
}

/**
 *  A call to action button
 */
function CtaButton (props: CtaButtonProps):React.ReactElement {
  const {
    loading, inverted, label, variant, icon, onClick = () => {},
  } = props

  const iconClass = icon ? '-icon' : ''
  const variantClass = variant === 'lg' ? '-large' : '-med'
  const invertClass = inverted ? '-invert' : ''

  const css = `cta-button ${iconClass} ${variantClass} ${invertClass}`

  return (
    <button type="button" onClick={onClick} className={css}>
      { icon
      && (
        <div className="icon">
          {icon}
        </div>
      )}
      <div className="label">
        { loading ? 'Loading...' : label }
      </div>
    </button>
  )
}

export {
  type CtaButtonProps,
  CtaButton
}
