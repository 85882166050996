import React, { ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Panel } from 'rsuite'

import { ResetPasswordForm } from '../../forms/ResetPasswordForm'
import { SetPasswordForm } from '../../forms/SetPasswordForm'
import {
  useSelector, useFormState, useDispatch, usePageTitle
} from '../../app/hooks'
import { userActions, userSelectors } from '../../store/user/slice'

/**
 * Password reset panel component
 * @return {ReactElement}
 */
function ResetPassword (): ReactElement {
  usePageTitle('Reset password')
  const dispatch = useDispatch()
  const status = useSelector(userSelectors.getStatus)
  const user = useSelector(userSelectors.getUser)
  const [formData, setField] = useFormState({
    email: '',
    code: '',
    password: '',
  })

  // Clear user on component unmount
  useEffect(() => () => {
    dispatch(userActions.clearUser())
  }, [])

  if (user && user.ready) return <Navigate to="/login" />

  /**
   * Request a password reset
   */
  const resetPassword = () => {
    dispatch(userActions.resetPassword({ username: formData.email }))
  }

  /**
   * Set a new password
   */
  const setPassword = () => {
    dispatch(userActions.setPassword({
      username: formData.email,
      code: formData.code,
      password: formData.password,
    }))
  }

  return (
    <div className="login-page">
      <Panel className="form" bordered header="Reset your password">
        {!(user && user.passwordReset) ? (
          <>
            <p>Enter your email address to reset your password</p>
            <ResetPasswordForm
              formValue={formData}
              onChange={setField}
              onSubmit={resetPassword}
              loading={status.loading}
              error={status.error}
            />
          </>
        ) : (
          <>
            <p>Enter your confirmation code and set your new password</p>
            <SetPasswordForm
              formValue={formData}
              onChange={setField}
              onSubmit={setPassword}
              loading={status.loading}
              error={status.error}
            />
          </>
        )}
      </Panel>
    </div>
  )
}

export {
  ResetPassword
}
