import React, { ReactElement, ReactNode } from 'react'
import { Table, Tag, TagGroup } from 'rsuite'
import { Link } from 'react-router-dom'

import { ActionMenu, PaginationControls, TableData } from '../../components'
import './VoucherTable.css'
import { Voucher } from '../../types/voucher'
import { VoucherType } from '../../types/enums'

type QrTableProps = {
  vouchers: Voucher[]
  loading?: boolean
  // onEdit?: (voucher: Voucher) => void
  onRevoke?: (voucher: Voucher) => void
  onSendCode?: (voucher: Voucher) => void
  nextPage?: () => void
  prevPage?: () => void
}

function VoucherTable ({
  vouchers,
  loading,
  onRevoke,
  onSendCode,
  nextPage,
  prevPage,
}: QrTableProps):React.ReactElement {
  const renderActions = (voucher: Voucher): ReactElement => {
    const actions : any[] = []
    // must not be redeemed or revoked to edit the voucher
    if (voucher.flags === undefined || voucher.flags?.length === 0) {
      // if (onEdit) {
      //  actions.push({
      //    label: 'Edit voucher',
      //    action: () => onEdit(voucher),
      //  })
      // }
      if (onSendCode) {
        actions.push({
          label: 'Send voucher',
          action: () => onSendCode(voucher),
        })
      }
      if (onRevoke) {
        actions.push({
          label: 'Revoke voucher',
          action: () => onRevoke(voucher),
        })
      }
    }
    return <ActionMenu actions={actions} />
  }

  const renderFlags = (voucher: Voucher): ReactElement => (
    <TagGroup>{voucher.flags?.map((f: string) => (<Tag>{f}</Tag>))}</TagGroup>
  )

  const renderStartDate = (voucher: Voucher): ReactNode => (
    voucher.type === VoucherType.SINGLE ? null : voucher.startDate
  )

  const renderLocation = ({ locationName }: Voucher): ReactNode => (
    locationName
  )

  const renderName = ({ id, name }: Voucher): ReactNode => (
    <Link to={`/admin/vouchers/${id}`}>{name}</Link>
  )

  return (
    <div className="qr-table">
      <Table
        wordWrap="break-word"
        data={vouchers}
        loading={loading}
        autoHeight
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <TableData<Voucher>
            dataKey="name"
            content={renderName}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Car park</Table.HeaderCell>
          <TableData<Voucher>
            dataKey="locationName"
            content={renderLocation}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <TableData dataKey="type" format="tag" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Start date</Table.HeaderCell>
          <TableData dataKey="startDate" format="date" content={renderStartDate} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Expiry date</Table.HeaderCell>
          <TableData dataKey="expiryDate" format="date" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Flags</Table.HeaderCell>
          <TableData dataKey="flags" content={renderFlags} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <TableData dataKey="actions" content={renderActions} />
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls nextPage={nextPage} prevPage={prevPage} />
      </div>
    </div>
  )
}

export {
  VoucherTable
}
