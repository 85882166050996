import { createQuery } from '../helpers'
import type {
  CreateLocationParams,
  GetAdminLocationParams,
  GetLocationParams,
  LocationDTO,
  UpdateLocationParams,
  LocationVehiclesDTO
} from '../../../types/location'
import type { PaginatedResponse, Query, RequestPaginationOptions } from '../../../types/api'
import { VehicleAccess } from '../../../types/enums'

/**
 * @var string
 * The properties to get back in any location response
 */
const returnProps = `
  id
  name
  description
  address
  timezone
  status
  type
  controllers
  organisation {
    id
    name
  }
  geolocation {
    lat
    lon
  }
  featureDetails {
    key
    enabled
    name
  }
  pricing {
    id
    name
    organisation
    type
    ... on Plan {
      price
    }
  }
  cameraGroups {
    name
    gates
    id
  }
  ... on Carpark {
    capacity {
      total
      available
      verified
    }
  }
`

const getLocation: Query<LocationDTO, GetLocationParams> = createQuery(`
  query getLocation($locationId: ID!) {
    getLocation(id: $locationId) { ${returnProps} }
  }
`)

const getAdminLocations: Query<PaginatedResponse<LocationDTO>, GetAdminLocationParams> = createQuery(`
  query getAdminLocations($limit: Int, $next: String) {
    getAdminLocations(limit: $limit, next: $next) {
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const getAdminLocationsForLookup: Query<PaginatedResponse<LocationDTO>,
GetAdminLocationParams> = createQuery(`
  query getAdminLocations($limit: Int, $next: String) {
    getAdminLocations(limit: $limit, next: $next) {
      items {
        id
        name
      }
      next
      limit
    }
  }
`)

const getLocations: Query<PaginatedResponse<LocationDTO>> = createQuery(`
  query getLocations {
    getLocations { 
      items {
        ${returnProps} 
      }
      limit
      next
    }
  }
`)

const createLocation: Query<LocationDTO, CreateLocationParams> = createQuery(`
  mutation createLocation(
    $name: String!,
    $organisation: String!,
    $description: String,
    $address: String!,
    $timezone: String!,
    $type: LocationType!,
    $controllers: [String]!,
    $geolocation: GeoLocationInput!,
    $totalCapacity: Int, 
  ) {
    createLocation(
      name: $name,
      description: $description,
      organisation: $organisation,
      address: $address,
      timezone: $timezone,
      type: $type,
      controllers: $controllers,
      geolocation: $geolocation,
      totalCapacity: $totalCapacity, 
    ) { ${returnProps} }
  }
`)

// TODO: Re-add returnProps selection once TP-156 is resolved
const updateLocation: Query<boolean, UpdateLocationParams> = createQuery(`
  mutation editLocation(
    $locationId: ID!,
    $name: String,
    $address: String,
    $description: String,
    $geolocation: GeoLocationInput,
    $timezone: String,
    $status: LocationStatus,
    $controllers: [String],
    $features: [LocationFeature],
    $totalCapacity: Int,
    $availableCapacity: Int,
  ) {
    editLocation(
      id: $locationId,
      name: $name,
      address: $address,
      description: $description,
      geolocation: $geolocation,
      timezone: $timezone,
      status: $status,
      controllers: $controllers,
      features: $features,
      totalCapacity: $totalCapacity,
      availableCapacity: $availableCapacity,
    )
  }
`)

const getLocationVehicles: Query<PaginatedResponse<LocationVehiclesDTO>, RequestPaginationOptions & {
  location: string,
  rego?: string,
  access?: VehicleAccess[],
}> = createQuery(`
  query getLocationVehicles(
    $location: ID!, 
    $rego: String, 
    $access: [VehicleAccess], 
    $limit: Int, 
    $next: String
  ) {
    getLocationVehicles(location: $location, rego: $rego, access: $access, limit: $limit, next: $next) { 
      items {
        id
        rego
        entrytime
        access
        status
      }
      limit
      next
    }
  }
`)

export {
  getLocation,
  getLocationVehicles,
  getAdminLocations,
  getAdminLocationsForLookup,
  createLocation,
  updateLocation,
  getLocations
}
