import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaUser,
  FaSearch,
  FaCar
} from 'react-icons/fa'

/**
 * Menu items for the operator area
 * @return {ReactElement}
 */
function AttendantMenu (): ReactElement {
  const path = useLocation().pathname
  return (
    <>
      <Nav.Item
        icon={(
          <Icon as={FaSearch} />
        )}
        to="/attendant/users"
        active={path.startsWith('/attendant/users')}
        as={Link}
      >
        Vehicle search
      </Nav.Item>
      <Nav.Item
        icon={(
          <Icon as={FaCar} />
        )}
        to="/attendant/occupancy"
        active={path.startsWith('/attendant/occupancy')}
        as={Link}
      >
        Occupancy
      </Nav.Item>
      <hr />
      <Nav.Item
        to="/"
        icon={<Icon as={FaUser} />}
        as={Link}
      >
        Driver account
      </Nav.Item>
    </>
  )
}

export {
  AttendantMenu
}
