import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { Loader } from 'rsuite'

import { useSelector } from '../../app/hooks'
import { userSelectors } from '../../store/user/slice'

/**
 * Redirect to the login page if not authenticated
 * @return {ReactElement}
 */
function RequireLogin (props: { children: JSX.Element|Array<JSX.Element> }): ReactElement {
  const { children } = props
  const { loading } = useSelector(userSelectors.getStatus)
  const user = useSelector(userSelectors.getUser)

  if ((!user || !user.authenticated) && !loading) return <Navigate to="/login/signup" />

  if (!user && loading) return <Loader />

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export {
  RequireLogin
}
