import React, { ReactElement } from 'react'

import { usePageTitle } from '../../app/hooks'
import { ErrorPage } from './ErrorPage'

/**
 * Not found page
 * @return {ReactElement}
 */
function NotFound (): ReactElement {
  usePageTitle('Not found')
  return (
    <ErrorPage
      type="info"
      title="404 not found"
      message="The page you're looking for doesn't appear to exist"
    />
  )
}

export {
  NotFound
}
