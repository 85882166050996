import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'
import { FlexboxGrid } from 'rsuite'

import { useSelector } from '../app/hooks'
import { userSelectors } from '../store/user/slice'
import { FullWidthLayout } from '../layouts/FullWidthLayout'
import { Login } from '../features/login/Login'
import { Signup } from '../features/login/Signup'
import { Confirm } from '../features/login/Confirm'
import { ResetPassword } from '../features/login/ResetPassword'
import { NotFound } from '../features/error-pages/NotFound'
import { LoginRedirect } from '../features/login/LoginRedirect'

/**
 * Login route
 * @return {ReactElement}
 */
function LoginPage (): ReactElement {
  const user = useSelector(userSelectors.getUser)

  if (user && user.authenticated) return <LoginRedirect />

  return (
    <FullWidthLayout header={false}>
      <FlexboxGrid justify="center" align="middle" style={{ minHeight: '90vh' }}>
        <FlexboxGrid.Item>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </FullWidthLayout>
  )
}

export {
  LoginPage
}
