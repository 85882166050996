import React from 'react'
import { Loader } from 'rsuite'
import './CenteredLoader.css'

/** A centered loader which consumes full width and height of parent */
function CenteredLoader ():React.ReactElement {
  return (
    <div className="centered-loader">
      <Loader />
    </div>
  )
}

export {
  CenteredLoader
}
