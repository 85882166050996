import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, Button, ButtonToolbar } from 'rsuite'

type Props = {
  onClose: () => void,
}

/**
 * Modal component displayed when user tries to
 * subscribe without any payment methods
 * @param {Props} props
 * @param {Function} props.onClose - Cb called when modal is closed
 */
function MissingPaymentMethodModal (props: Props) {
  const navigate = useNavigate()
  const { onClose } = props

  return (
    <>
      <Modal.Header>
        <Modal.Title>No payment method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You do not have any payment methods yet associated
        with your account. Please add one first before continuing.
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button
            onClick={() => navigate('/account')}
            appearance="primary"
          >
            Add payment method
          </Button>
          <Button
            onClick={onClose}
            appearance="subtle"
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  MissingPaymentMethodModal
}
