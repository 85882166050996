import { Query } from '../../../types/api'
import { DashboardDataDTO } from '../../../types/dashboard'
import { createQuery } from '../helpers'

const returnProps = `
  location {
    lastSync
    status
    location {
      id
      name
      capacity {
        available
        total
        verified
      }
    }
    occupancy {
      allowed
      casual
      day
      hour
      subscription
    }
  }
`
export const getDashboardData: Query<DashboardDataDTO, {location: String | null }> = createQuery(`
  query getDashboardData {
    getDashboardData {
      ${returnProps}
    }
  }
`)
