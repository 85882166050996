/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import {
  Modal, Button, Form, Message, Panel
} from 'rsuite'
import { Input } from '@rsuite/formik'

import { Formik, FormikErrors } from 'formik'

import FieldWithFormik from '../../forms/formik-fields/FieldWithFormik'
import { SelectPicker } from '../../forms/formik-fields/SelectPicker'
import { useApi, useUser } from '../../app/hooks'
import { getLocationTariffs, getAttendantLocationId } from '../../services/graphql/queries'
import { createVisitOverride } from '../../services/graphql/queries/visit-overrides'

interface FormValues {
  rego: string;
  comment: string;
  tariff: string;
}

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {}
  // validate all fields are filled
  if (!values.rego) {
    errors.rego = 'Registration number is required'
  }

  return errors
}

export default function TariffOverrideModal (
  { open, rego, handleClose }: { open: boolean, rego: string, handleClose: () => void }
) {
  const user = useUser()
  const createOverrideQuery = useApi(createVisitOverride)
  const attendantLocationQuery = useApi(getAttendantLocationId)
  const attendantData = attendantLocationQuery.getResponse()
  const tariffQuery = useApi(getLocationTariffs)
  const tariffData = tariffQuery.getResponse()
  useEffect(() => {
    if (user.id) {
      attendantLocationQuery.sendRequest({ id: user.id })
    }
    return () => {
      attendantLocationQuery.cleanup()
    }
  }, [])
  useEffect(() => {
    if (attendantData.data) {
      tariffQuery.sendRequest({ locationId: attendantData.data })
    }
    return () => {
      tariffQuery.cleanup()
    }
  }, [attendantData.data])

  const tariffs = (
    tariffData.data && tariffData.data?.items.map((t: any) => ({ label: t.name, value: t.id }))
  ) || []
  tariffs.sort((a, b) => a.label.localeCompare(b.label))
  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={{
        rego,
        comment: '',
        tariff: '',
      }}
      onSubmit={async (values) => {
        if (tariffData.data) {
          const tariff = tariffData.data.items.find((t: { id: string }) => t.id === values.tariff)
          if (tariff && tariff.location) {
            createOverrideQuery.sendRequest({
              ...values,
              startTime: new Date().toISOString(),
              location: tariff.location,
            })
            handleClose()
          }
        }
      }}
    >
      {
        ({ errors, touched, handleSubmit }) => (
          <Form className="noover" id="tariff-override-modal-form" autoComplete="off">
            <Modal size="full" open={open} onClose={handleClose}>
              <Modal.Header>
                <Modal.Title>Tariff Override</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FieldWithFormik
                  required
                  errors={errors}
                  touched={touched}
                  name="rego"
                  label="Vehicle registration:"
                  component={Input}
                  disabled
                  style={{ textTransform: 'uppercase', marginBottom: 10 }}
                />
                <FieldWithFormik
                  errors={errors}
                  touched={touched}
                  name="tariff"
                  label="Tariff to apply for the vehicle's visit:"
                  data={tariffs}
                  component={SelectPicker}
                  style={{ marginBottom: 10 }}
                />
                <FieldWithFormik
                  errors={errors}
                  touched={touched}
                  name="comment"
                  label="The reason for overriding the tariff:"
                  component={Input}
                  style={{ marginBottom: 20 }}
                />
                <Message showIcon type="warning">
                  This is a temporary tariff override for this visit only.
                </Message>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} appearance="subtle">
                  Cancel
                </Button>
                <Button onClick={(_e) => handleSubmit()} type="submit" appearance="primary">
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )
      }
    </Formik>
  )
}
