/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react'

import {
  Formik, Form, Field, FormikErrors
} from 'formik'
import { usePaymentInputs } from 'react-payment-inputs'
import {
  Panel,
  Form as RForm
} from 'rsuite'

import images from '../components/icons/credit-cards'

import type { FormProps } from '../types/form'
import FieldWithFormik from './formik-fields/FieldWithFormik'

import { Input } from './formik-fields/Input'

import './PaymentDetailsForm.css'
import { CreditCard } from '../components/icons/credit-cards/CreditCard'

interface FormValues {
  cardNumber: string;
  expiryDate: string;
  cvc: string;
  cardHolderName: string;
}

/**
 * Payment details form component
 * @return {ReactElement}
 */
function PaymentDetailsForm (props: FormProps): ReactElement {
  const {
    onSubmit, formId,
  } = props

  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs()

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {}

    if (meta.erroredInputs.cardNumber) {
      errors.cardNumber = meta.erroredInputs.cardNumber
    }

    if (meta.erroredInputs.expiryDate
      && (meta.touchedInputs.expiryDate
      || !meta.erroredInputs.cardNumber)) {
      errors.expiryDate = meta.erroredInputs.expiryDate
    }
    if (meta.erroredInputs.cvc
      && (meta.touchedInputs.cvc
      || !meta.erroredInputs.cardNumber)) {
      errors.cvc = meta.erroredInputs.cvc
    }
    if (values?.cardHolderName.length === 0) {
      errors.cardHolderName = 'Enter a card holder name'
    }
    return errors
  }

  return (
    <Formik
      initialValues={{
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvc: '',
      }}
      onSubmit={async (values, actions) => {
        const errs = validate(values)
        if (Object.keys(errs).length === 0) {
          setTimeout(() => {
            onSubmit(values)
            actions.setSubmitting(false)
          }, 300)
        }
      }}
      validate={validate}
    >
      {
        (form) => (
          <Form
            id={formId}
            autoComplete="off"
          >
            <div className="payment-header">
              <div>
                <CreditCard width="2em" height="2em">
                  {images.visa}
                </CreditCard>
              </div>
              <div>
                <CreditCard width="2em" height="2em">
                  {images.mastercard}
                </CreditCard>
              </div>
              <div>
                <CreditCard width="2em" height="2em">
                  {images.amex}
                </CreditCard>
              </div>
            </div>

            <Panel
              header={(
                <div>
                  Payment details
                  <RForm.HelpText tooltip>
                    Transaction are encrypted and secure
                  </RForm.HelpText>
                </div>
              )}
              className="payments-panel"
              bordered
              style={{ maxWidth: 600 }}
            >
              <div className="payment-card-holder">
                <FieldWithFormik
                  errors={form.errors}
                  touched={form.touched}
                  name="cardHolderName"
                  label="Card holder name"
                  component={Input}
                  style={{ width: 280 }}
                />
              </div>
              <div className="payment-card-number">
                <RForm.ControlLabel htmlFor="cardNumber" className="rs-form-control-label">
                  Card number
                </RForm.ControlLabel>
                <div style={{ position: 'relative' }}>
                  <svg
                    {...getCardImageProps({ images })}
                    width="2.2em"
                    height="2.2em"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      left: '8px',
                    }}
                  />
                  <Field name="cardNumber">
                    {({ field }) => (
                      <input
                        {...getCardNumberProps({
                          onBlur: field.onBlur,
                          onChange: field.onChange,
                        })}
                        className="rs-input"
                        style={{ paddingLeft: '48px', width: 280 }}
                      />
                    )}
                  </Field>
                  <p style={{
                    color: '#c9444d',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    marginBottom: '1.5rem',
                  }}
                  >
                    {form.errors.cardNumber}
                  </p>
                </div>
              </div>
              <div className="payment-card-group">
                <div className="payment-card-expiry">
                  <RForm.ControlLabel className="rs-form-control-label">
                    Expiry date
                    <RForm.HelpText tooltip>
                      Ensure expiry date is in the future
                    </RForm.HelpText>
                  </RForm.ControlLabel>
                  <Field name="expiryDate">
                    {({ field }) => (
                      <input
                        {...getExpiryDateProps({
                          onBlur: field.onBlur,
                          onChange: field.onChange,
                        })}
                        style={{ width: 100 }}
                        className="rs-input"
                      />
                    )}
                  </Field>
                  <p style={{
                    color: '#c9444d',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    marginBottom: '1.5rem',
                  }}
                  >
                    {form.errors.expiryDate}
                  </p>
                </div>
                <div className="payment-card-code">
                  <RForm.ControlLabel className="rs-form-control-label">
                    Security Code
                    <RForm.HelpText tooltip>
                      The security code or CVV refers
                      to the extra 3 or 4 digits on the back or
                      front of your card
                    </RForm.HelpText>
                  </RForm.ControlLabel>
                  <Field name="cvc">
                    {({ field }) => (
                      <input
                        {...getCVCProps({
                          onBlur: field.onBlur,
                          onChange: field.onChange,
                        })}
                        style={{ width: 100 }}
                        className="rs-input"
                      />
                    )}
                  </Field>
                  <p style={{
                    color: '#c9444d',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    marginBottom: '1.5rem',
                  }}
                  >
                    {form.errors.cvc}
                  </p>
                </div>
              </div>
            </Panel>
          </Form>
        )
      }
    </Formik>
  )
}

export {
  PaymentDetailsForm
}
