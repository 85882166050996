import { createQuery } from '../helpers'
import type {
  VehicleDTO,
  GetVehicleParams,
  GetUserVehiclesParams,
  CreateVehicleParams,
  UpdateVehicleParams,
  RemoveVehicleParams,
  GetVehicleByRegoParams,
  VehicleSearchDTO
} from '../../../types/vehicle'
import type { PaginatedResponse, Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any vehicle response
 */
const returnProps = `
  id
  rego
  make
  model
  state
  colour
`

const getVehicle: Query<VehicleDTO, GetVehicleParams> = createQuery(`
  query getVehicle($vehicleId: ID!) {
    getVehicle(id: $vehicleId) { ${returnProps} }
  }
`)

const getVehicleByRego: Query<VehicleSearchDTO, GetVehicleByRegoParams> = createQuery(`
  query getVehicleByRego($rego: String!) {
    getVehicleByRego(rego: $rego) {
      id,
      rego,
      types,
      organisation {
        name
        locationName
        spaces
      }
      make,
      model,
      state,
      colour,
      user,
      flags,
      email,
      familyName,
      givenName,
      phoneNumber,
      allowList,
      allowListLocation,
      allowListType,
      subscriptions {
        plan
        location
        startDate
        status
        vehicles
        endDate
      }
      overrides {
        rego
        location
        tariff
        startTime
        endTime
        comment
      }
      parkingHistory {
        rego
        location
        entrytime
        exittime
      }
    }
  }
`)

const getUserVehicles: Query<PaginatedResponse<VehicleDTO>, GetUserVehiclesParams> = createQuery(`
  query getUserVehicles($userId: ID!, $limit: Int, $next: String) {
    getUserVehicles(user: $userId, next: $next, limit: $limit) {
      items {
        ${returnProps} 
      }
      limit
      next
    }
  }
`)

const createVehicle: Query<VehicleDTO, CreateVehicleParams> = createQuery(`
  mutation addVehicle(
    $userId: ID!,
    $rego: String!,
    $make: String!,
    $model: String!,
    $state: String!,
    $colour: String!,
  ) {
    addVehicle(
      user: $userId,
      rego: $rego,
      make: $make,
      model: $model,
      state: $state,
      colour: $colour,
    ) { ${returnProps} }
  }
`)

const updateVehicle: Query<VehicleDTO, UpdateVehicleParams> = createQuery(`
  mutation editVehicle(
    $vehicleId: ID!,
    $rego: String,
    $state: String
  ) {
    editVehicle(
      id: $vehicleId,
      rego: $rego,
      state: $state
    ) { ${returnProps} }
  }
`)

const removeVehicle: Query<VehicleDTO, RemoveVehicleParams > = createQuery(`
  mutation deregisterVehicle($vehicleId: ID!) {
    deregisterVehicle(id: $vehicleId) { ${returnProps} }
  }
`)

export {
  getVehicle,
  getVehicleByRego,
  getUserVehicles,
  createVehicle,
  updateVehicle,
  removeVehicle
}
