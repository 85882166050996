import React from 'react'
import { Button, ButtonToolbar, Modal } from 'rsuite'

type Props = {
  onConfirm: ()=>void,
  onClose: ()=>void,
  loading: boolean
}

/**
 * Modal for admins to confirm their subscription cancellation
 * @return {React.ReactElement}
 */
export function AdminCancelSubscriptionModal (props:Props):React.ReactElement {
  const { onConfirm, onClose, loading } = props
  return (
    <>
      <Modal.Header>
        <Modal.Title>Are you sure you want to cancel this subscription?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You will be cancelling this subscription on the user&apos;s behalf.</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={onConfirm} appearance="primary" loading={loading}>
            Cancel subscription
          </Button>
          <Button onClick={onClose} appearance="subtle">Don&apos;t cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}
