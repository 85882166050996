import { nanoid } from 'nanoid'

import { ApiErrorCode } from '../../types/enums'
import type { GraphQLError, ApiError } from '../../types/api'

/**
 * Convert an Amplify error object into a user error
 * @param {Object} error
 * @return {UserError}
 */
const createError = (errors: Array<GraphQLError>): ApiError => ({
  code: ApiErrorCode.GENERIC_GRAPHQL_ERROR,
  message: errors.map(({ message }: GraphQLError) => message).join(', '),
  requestId: nanoid(),
})

export {
  createError
}
