import React from 'react'

import { InvoiceItemDTO } from '../types/payment'

type InvoiceTableProps = {
  items?: InvoiceItemDTO[]
}

/**
 * Component for displaying invoice items
 * @param {InvoiceTableProps} props
 * @return {React.ReactElement}
 */
export function InvoiceTable (props: InvoiceTableProps) : React.ReactElement {
  const { items } = props
  return (
    <div className="invoice-table">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit price</th>
            <th>GST</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          { items?.map((item) => (
            <tr>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>
                $
                {item.unitPrice}
              </td>
              <td>
                $
                {item.gst}
              </td>
              <td>
                $
                {item.total}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
