import React, {
  ReactElement, ReactNode, useState, useEffect
} from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Content, Sidebar } from 'rsuite'

import { BaseLayout } from './BaseLayout'
import { SideMenu } from '../features/navigation/SideMenu'
import { SideMenuToggle } from '../features/navigation/SideMenuToggle'

import './SidebarLayout.css'

type Props = {
  menu: ReactNode,
  children: ReactNode,
  header?: boolean,
}

/**
 * Sidebar layout
 * @param {Props} props
 * @return {ReactElement}
 */
function SidebarLayout (props: Props): ReactElement {
  const { menu, children, header } = props
  const [showMenu, setMenu] = useState(false)
  const location = useLocation()

  /**
   * Toggle the visibility of the sidebar menu
   */
  const toggleMenu = () => {
    setMenu(!showMenu)
  }

  /**
   * Hide the sidebar menu
   */
  const hideMenu = () => {
    setMenu(false)
  }

  useEffect(() => hideMenu, [location])

  return (
    <BaseLayout header={header}>
      <Container>
        <SideMenuToggle mode="open" onClick={toggleMenu} />
        <Sidebar className={showMenu ? '-expanded' : ''}>
          <SideMenu onClose={hideMenu}>
            {menu}
          </SideMenu>
        </Sidebar>
        <Content onClick={hideMenu}>
          {children}
        </Content>
      </Container>
    </BaseLayout>
  )
}

export {
  SidebarLayout
}
