/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import {
  Formik, Form, FormikErrors
} from 'formik'

import { useParams } from 'react-router-dom'

import { Message } from 'rsuite'

import { clamp } from '../../helpers'
import { FormSteps } from '../../components/FormSteps'
import { TariffSummaryPage } from './TariffSummaryPage'
import { TariffNamePage } from './TariffNamePage'
import { TariffDatePage } from './TariffDatePage'
import { TariffRatesPage } from './TariffRatesPage'
import type { FormProps } from '../../types/form'
import { useApi } from '../../app/hooks'
import { getOrganisations, getLocationGroups } from '../../services/graphql/queries'
import { TariffStatus } from '../../types/enums'

const START_OF_DAY = new Date()
const END_OF_DAY = new Date()
START_OF_DAY.setHours(0, 0, 0, 0)
END_OF_DAY.setHours(23, 59, 59, 0)

const FIELDS_BY_STEP: { [index: number]: string[] } = {
  0: ['name', 'invoiceType', 'group', 'organisation', 'cameraGroup'],
  1: ['days', 'date', 'hours'],
  2: ['incrementalRate', 'fixedGrace', 'fixedFee'],
}

type Props = FormProps & { isDraft: boolean }

interface FormValues {
  name: string;
  invoiceType: string | null;
  group: string | null;
  organisation: string | null;
  cameraGroup: string | null;
  days: string[];
  date: Date | null;
  hours: Date[] | null;
  entryHours: Date[] | null;
  exitHours: Date[] | null;
  fixedFees: string;
  fixedGrace: string;
  incrementalRate: { step: number, period: number, unit: string, rate: number}[];
  tariffAssignmentType: string;
  tariffRateType: string;
  tariffDateType: string;
  status: TariffStatus
}

const parseDateValue = (val: string): Date => new Date(Date.parse(val))

const parseHoursValue = (hours: string[]): Date[] | null => {
  if (!hours || hours?.length === 0) {
    return null
  }
  return [parseDateValue(hours[0]), parseDateValue(hours[1])]
}

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {}

  if (!values.name) {
    errors.name = 'All tariff schemes must have a name.'
  }

  if (!values.invoiceType) {
    errors.invoiceType = 'All tariff schemes must have an invoice type.'
  }

  if (values.tariffDateType === 'days' && (!values.days || values.days.length === 0)) {
    errors.days = 'At least one day must be selected'
  }

  if (values.tariffDateType === 'date' && !values.date) {
    errors.date = 'A date must be selected.'
  }

  if (values.tariffAssignmentType === 'group' && !values.group) {
    errors.group = 'A group must be selected.'
  }

  if (values.tariffAssignmentType === 'organisation' && !values.organisation) {
    errors.organisation = 'An organisation must be selected.'
  }

  if (!values.hours || values.hours.length !== 2) {
    errors.hours = 'Start and end time are required.'
  }

  if (values.tariffRateType === 'fixed') {
    if (!values.fixedFees) {
      errors.fixedFees = 'Fixed fee is required'
    } else if (Number.isNaN(Number(values.fixedFees))) {
      errors.fixedFees = 'Fixed fee must be a number.'
    }

    if (!values.fixedGrace) {
      errors.fixedGrace = 'Fixed grace is required'
    } else if (Number.isNaN(Number(values.fixedGrace))) {
      errors.fixedGrace = 'Fixed grace must be a number.'
    }
  }

  if (values.tariffRateType === 'incremental' && values.incrementalRate.length === 0) {
    errors.incrementalRate = 'At least one rate must be specified.'
  }

  return errors
}

function TariffForm (props: Props) {
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
    isDraft,
  } = props
  const { locationId } = useParams()

  const organisationApi = useApi(getOrganisations)
  const organisationData = organisationApi.getResponse()
  const organisations = organisationData.success
    ? organisationData.data?.items.map(
      (org) => ({ name: org.name, id: org.id, groups: JSON.parse(org.groups) })
    ) ?? [] : []

  const groupApi = useApi(getLocationGroups)
  const groupData = groupApi.getResponse()
  const groups = groupData.success
    ? groupData.data?.items.map((grp: any) => ({ label: grp.name, value: grp.id })) ?? [] : []

  React.useEffect(() => {
    organisationApi.sendRequest()
    return organisationApi.cleanup
  }, [])

  React.useEffect(() => {
    if (locationId) {
      groupApi.sendRequest({ locationId })
    }
    return groupApi.cleanup
  }, [])

  const [step, setStep] = useState(0)
  const handleStepForward = () => {
    if (step < 3) {
      setStep(clamp(step + 1, 0, 3))
    }
  }
  const handleStepBack = () => {
    if (step > 0) {
      setStep(clamp(step - 1, 0, 3))
    }
  }

  const getStepErrors = (errs: any) => {
    let hasErrors = false
    Object.keys(errs).forEach((e) => {
      if (FIELDS_BY_STEP[step].includes(e)) hasErrors = true
    })

    return hasErrors
  }

  const draftWarning = isDraft
    // eslint-disable-next-line max-len
    ? 'This tariff scheme is currently in draft mode, and will not be used for rate calculations until published.'
    : 'You are editing a published tariff scheme, which is actively being used for rate calculations.'

  return (
    <Formik
      validate={validate}
      initialValues={{
        name: formValue?.name,
        invoiceType: formValue?.invoiceType || null,
        group: formValue?.group,
        organisation: formValue?.organisation,
        cameraGroup: formValue?.cameraGroup,
        days: formValue?.days || [],
        date: parseDateValue(formValue?.date),
        hours: parseHoursValue(formValue?.hours || [START_OF_DAY, END_OF_DAY]),
        entryHours: parseHoursValue(formValue?.entryHours),
        exitHours: parseHoursValue(formValue?.exitHours),
        fixedFees: formValue?.fixedFees,
        fixedGrace: formValue?.fixedGrace,
        incrementalRate: JSON.parse(formValue?.incrementalRate || '[]') || [],
        tariffAssignmentType: formValue?.tariffAssignmentType || 'all',
        tariffRateType: formValue?.tariffRateType || 'fixed',
        tariffDateType: formValue?.tariffDateType || 'days',
        status: formValue?.status || TariffStatus.DRAFT,
      }}
      onSubmit={async (values, actions) => {
        const errs = validate(values)
        if (Object.keys(errs).length === 0) {
          setTimeout(() => {
            onSubmit(values)
            actions.setSubmitting(false)
          }, 1000)
        }
      }}
    >
      {
        (form) => (
          <>
            <Message showIcon type="warning">
              {draftWarning}
            </Message>
            <FormSteps
              stepForward={async () => {
                const a = await form.validateForm()
                if (getStepErrors(a)) {
                  const outputObj: {[key: string]: boolean} = {}
                  FIELDS_BY_STEP[step].forEach((item) => {
                    outputObj[item] = true
                  })
                  form.setTouched(outputObj)
                  return
                }

                handleStepForward()
              }}
              stepBack={handleStepBack}
              current={step}
              steps={[{ title: 'Users' }, { title: 'Schemes' }, { title: 'Rates' }, { title: 'Summary' }]}
            />
            <Form className="rs-form-group noover" id={formId} autoComplete="off">
              {step === 0
                ? <TariffNamePage form={form} groups={groups} organisations={organisations} /> : null}
              {step === 1 ? <TariffDatePage form={form} /> : null}
              {step === 2 ? <TariffRatesPage form={form} /> : null}
              {step === 3
                ? (
                  <TariffSummaryPage
                    values={form.values}
                    groups={groups}
                    organisations={organisations}
                  />
                ) : null}
            </Form>
          </>
        )
      }
    </Formik>
  )
}

export { TariffForm }
