import React from 'react'

import { AdminDashboardInfo } from '../../types/dashboard'
import './AdminDashboard.css'
import { AdminDashboardCasual } from './AdminDashboardCasual'

/** Prop type for {@link CustomerDashboard} */
type AdminDashboardProps = AdminDashboardInfo

/**
 * Customer dashboard which displays the profile status of the customer
 * and the customer's subscriptions & vehicles
 */
function AdminDashboard (props: AdminDashboardProps):React.ReactElement {
  const {
    showSubscriptionHeader,
    data,
    addLocationCb,
  } = props

  return (
    <AdminDashboardCasual
      showSubscriptionHeader={showSubscriptionHeader}
      data={data}
      addLocationCb={addLocationCb}
    />
  )
}

export {
  AdminDashboard
}
