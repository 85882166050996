import React, { ReactElement } from 'react'
import { Navbar, Nav, Dropdown } from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaUserCircle } from 'react-icons/fa'

import type { StatusedAuthUser } from '../../types/user'
import type { InteractionCallback } from '../../types/form'

import './HeaderBar.css'

type Props = {
  user: StatusedAuthUser|null,
  onLogout: InteractionCallback,
}

/**
 * Full width layout
 * @param {Props} props
 * @return {ReactElement}
 */
function HeaderBar (props: Props): ReactElement {
  const { user, onLogout } = props
  const userName = user ? user.givenName : 'User'
  return (
    <Navbar className="header-bar">
      {user && user.authenticated && (
        <Nav pullRight>
          <Dropdown title={<span className="label">{userName}</span>} icon={<Icon as={FaUserCircle} />}>
            <Dropdown.Item onClick={onLogout}>Log out</Dropdown.Item>
          </Dropdown>
        </Nav>
      )}
    </Navbar>
  )
}

export {
  HeaderBar
}
