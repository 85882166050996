/* eslint  no-template-curly-in-string: 0 */
import React from 'react'
import {
  Panel, Table
} from 'rsuite'
import dayjs from 'dayjs'

const { Column, HeaderCell, Cell } = Table

export function TariffSummaryPage (
  { values, groups, organisations }: { values: any, groups: any, organisations: any }
) {
  const getSummaryData = (data: any) => {
    const {
      name,
      cameraGroup,
      days,
      dates,
      group,
      organisation,
      tariffDateType,
      hours,
      entryHours,
      exitHours,
      tariffAssignmentType,
      invoiceType,
    } = data

    let invoice = 'At gate'
    if (invoiceType === 'pre-paid') {
      invoice = 'Pre paid'
    } else if (invoiceType === 'post-paid') {
      invoice = 'Post paid'
    }

    let assigned = 'All users'
    if (tariffAssignmentType === 'all') {
      assigned = 'All users'
    } else if (tariffAssignmentType === 'member') {
      assigned = 'All members'
    } else if (group !== null) {
      assigned = groups.find((g: any) => g.value === group).label
    } else if (organisation !== null) {
      const [orgId, groupId] = organisation.split(':')
      const org = organisations.find((o: any) => o.id === orgId)
      const orgGroup = org.groups.find((g: any) => g.id === groupId)
      assigned = `${orgGroup.name} (${org.name})`
    }

    let datesKey: string | null = null
    let datesValue: string | null = null
    if (tariffDateType === 'days') {
      datesKey = 'Days'
      datesValue = days.join(', ')
    } else if ((tariffDateType === 'date')) {
      datesKey = 'Date'
      datesValue = dayjs(dates).format('DD/MM/YYYY')
    }

    const ret = [
      { key: 'Name', value: name },
      { key: 'Invoice Type', value: invoice },
      { key: 'Assigned', value: assigned },
    ]
    if (cameraGroup != null) {
      ret.push({ key: 'Camera Group', value: cameraGroup })
    }
    if ((datesKey != null) && (datesValue != null)) {
      ret.push({ key: datesKey, value: datesValue })
    }
    if (hours.length > 1) {
      const from = dayjs(hours[0]).format('hh:mm A')
      const to = dayjs(hours[1]).format('hh:mm A')
      ret.push({ key: 'Hours', value: `${from} - ${to}` })
    }

    if (entryHours && entryHours.length > 1) {
      const from = dayjs(entryHours[0]).format('hh:mm A')
      const to = dayjs(entryHours[1]).format('hh:mm A')
      ret.push({ key: 'Entry Hours', value: `${from} - ${to}` })
    }

    if (exitHours && exitHours.length > 1) {
      const from = dayjs(exitHours[0]).format('hh:mm A')
      const to = dayjs(exitHours[1]).format('hh:mm A')
      ret.push({ key: 'Exit Hours', value: `${from} - ${to}` })
    }

    return (ret)
  }

  const getFixedRateData = (data: any) => {
    const { fixedGrace, fixedFees } = data

    const ret = [
      { key: 'Grace Period', value: fixedGrace },
      { key: 'Fixed Fees', value: fixedFees },
    ]

    return (ret)
  }

  const getDetails = () => (
    <Table
      wordWrap="break-word"
      autoHeight
      data={getSummaryData(values)}
    >
      <Column width={150}>
        <HeaderCell>Property</HeaderCell>
        <Cell dataKey="key" />
      </Column>
      <Column flexGrow={1}>
        <HeaderCell>Value</HeaderCell>
        <Cell dataKey="value" />
      </Column>
    </Table>
  )

  const getFixedRates = () => (
    <Panel header="Fixed Rates">
      <Table
        wordWrap="break-word"
        autoHeight
        data={getFixedRateData(values)}
      >
        <Column width={150}>
          <HeaderCell>Property</HeaderCell>
          <Cell dataKey="key" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Value</HeaderCell>
          <Cell dataKey="value" />
        </Column>
      </Table>
    </Panel>
  )

  const getIncrementalRates = () => (
    <Panel header="Incremental Rates">
      <Table
        autoHeight
        data={values.incrementalRate}
      >
        <Column>
          <HeaderCell>Step</HeaderCell>
          <Cell dataKey="step" />
        </Column>
        <Column>
          <HeaderCell>Period</HeaderCell>
          <Cell dataKey="period" />
        </Column>
        <Column>
          <HeaderCell>Unit</HeaderCell>
          <Cell dataKey="unit" />
        </Column>
        <Column>
          <HeaderCell>Rate</HeaderCell>
          <Cell dataKey="rate" />
        </Column>
      </Table>
    </Panel>
  )

  return (
    <div>
      <Panel header="Tariff Summary">
        {getDetails()}
      </Panel>
      {(values.tariffRateType === 'fixed') ? (
        getFixedRates()
      ) : null}
      {(values.tariffRateType === 'incremental') ? (
        getIncrementalRates()
      ) : null}
    </div>
  )
}
