import React from 'react'
import { IconButton, Steps } from 'rsuite'
import { PageNext, PagePrevious } from '@rsuite/icons'
import './FormSteps.css'

type StepItem = {
  title: string
}

type FormStepsProps = {
  steps: StepItem[]
  current: number
  stepForward: any
  stepBack: any
}

function FormSteps ({
  steps, current, stepForward, stepBack,
}: FormStepsProps) {
  return (
    <div className="noover-steps">
      <IconButton
        disabled={current === 0}
        onClick={stepBack}
        icon={<PagePrevious style={{ height: 30 }} />}
        placement="left"
        size="xs"
      >
        Back
      </IconButton>
      <Steps style={{ flexGrow: 1, paddingLeft: 10 }} current={current}>
        {steps.map((step) => <Steps.Item key={step.title} />)}
      </Steps>
      <IconButton
        disabled={current === (steps.length - 1)}
        onClick={stepForward}
        icon={<PageNext style={{ height: 30 }} />}
        placement="right"
        size="xs"
      >
        Next
      </IconButton>
    </div>
  )
}

export { FormSteps }
