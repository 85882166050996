import { nanoid } from 'nanoid'

import type { UserError } from '../../types/user'

/**
 * Convert an Amplify error object into a user error
 * @param {Object} error
 * @return {UserError}
 */
const createError = (error: any): UserError => ({
  code: error.code,
  message: error.message,
  requestId: nanoid(),
})

export {
  createError
}
