import React, { ReactElement } from 'react'
import { Form, InputPicker } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { states } from '../features/vehicles/options'
import type { FormProps } from '../types/form'

/**
 * Vehicle form component
 * @return {ReactElement}
 */
function VehicleForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId, mode,
  } = props

  const validator = new Validator({
    make: {
      model: schemas.required('Enter the make of your vehicle'),
    },
    model: {
      model: schemas.required('Enter the model of your vehicle'),
    },
    state: {
      model: schemas.required('Enter the state the vehicle is registered'),
    },
    rego: {
      model: schemas.required('Enter the registration number'),
    },
    colour: {
      model: schemas.required('Enter the colour of the vehicle'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="rego">
        <Form.ControlLabel>Registration number</Form.ControlLabel>
        <Form.Control name="rego" type="text" />
      </Form.Group>
      <Form.Group controlId="state">
        <Form.ControlLabel>Registration state</Form.ControlLabel>
        <Form.Control name="state" accepter={InputPicker} data={states} />
      </Form.Group>
      <Form.Group controlId="make">
        <Form.ControlLabel>Make</Form.ControlLabel>
        <Form.Control name="make" type="text" disabled={mode === 'edit'} />
      </Form.Group>
      <Form.Group controlId="model">
        <Form.ControlLabel>Model</Form.ControlLabel>
        <Form.Control name="model" type="text" disabled={mode === 'edit'} />
      </Form.Group>
      <Form.Group controlId="model">
        <Form.ControlLabel>Colour</Form.ControlLabel>
        <Form.Control name="colour" type="text" disabled={mode === 'edit'} />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  VehicleForm
}
