import { Query } from '../../../types/api'
import { ReportingDataDTO } from '../../../types/reporting'
import { createQuery } from '../helpers'

const returnProps = `
  subscriptionsTotal
  subscriptionBreakdown {
    location
    plan
    subscriptionCount
  }
  subscriptionsAtCarpark
  customersTotal
  customersWithSubscriptionTotal
`

export const getReportingData: Query<ReportingDataDTO> = createQuery(`
  query getReportingData {
    getReportingData {
      ${returnProps}
    }
  }
`)

export const getSubscribersReport: Query<any, {
  location: String | null, startDate: String | null, endDate: String | null
}> = createQuery(`
  query getSubscribersReport($location: ID, $startDate: String, $endDate: String) {
    getSubscribersReport(location: $location, startDate: $startDate, endDate: $endDate) {
      userFullName
      userEmail
      userPhone
      planName 
      locationName 
      vehicles
      status
      requestDate
      startDate
      endDate
    }
  }
`)

export const getMembersReport: Query<any, {
  status: String | null, payment: String | null, login: String | null
}> = createQuery(`
  query getMembersReport($status: String, $payment: String, $login: String) {
    getMembersReport(status: $status, payment: $payment, login: $login) {
      name
      email
      phone
      subscriptions
      status
      vehicles
      payment
      login
    }
  }
`)

export const getPricingReport: Query<any, {
  plan: String | null, location: String | null
}> = createQuery(`
  query getPricingReport($plan: String, $location: String) {
    getPricingReport(plan: $plan, location: $location) {
      plan
      price
      location
      activeCount
      activeVehicles
      pendingCount
      pendingVehicle
    }
  }
`)

export const getHourlyOccupancyReport: Query<any, {
  date: String | null, location: String | null
}> = createQuery(`
  query getHourlyOccupancyReport($date: String, $location: String) {
    getHourlyOccupancyReport(date: $date, location: $location) {
      hour
      day
      carpark
      subscription
      casual
      allowed
    }
  }
`)

export const getVehicleAccessReport: Query<any, {
  location: String | null, rego: String | null, entry: String | null, exit: String | null
}> = createQuery(`
  query getVehicleAccessReport($location: ID, $rego: String, $entry: String, $exit: String) {
    getVehicleAccessReport(location: $location, rego: $rego, entry: $entry, exit: $exit) {
      rego
      carpark
      access
      entrytime
      exittime
      name
      email
      status
      state
      make
      model
    }
  }
`)

export const getAccessListReport: Query<any, {
  location: String | null, accessType: String | null
}> = createQuery(`
  query getAccessListReport($location: ID, $accessType: String) {
    getAccessListReport(location: $location, accessType: $accessType) {
      name
      carpark
      plate
      description
      type
    }
  }
`)

export const getDeclinedPaymentsReport: Query<any, {
  from: String | null, to: String | null
}> = createQuery(`
  query getDeclinedPaymentsReport($from: String, $to: String) {
    getDeclinedPaymentsReport(from: $from, to: $to) {
      name
      email
      phone
      amount
      location
      type
      status
      processed
      created
    }
  }
`)

export const getPaymentsReport: Query<any, {
  location: String | null, type: String | null, from: String | null, to: String | null
}> = createQuery(`
  query getPaymentsReport($location: ID, $type: String, $from: String, $to: String) {
    getPaymentsReport(location: $location, type: $type, from: $from, to: $to) {
      name
      email
      phone
      amount
      surcharge
      outstanding
      currency
      location
      type
      status
      processed
      created
    }
  }
`)

export const getAverageTimePerSpaceReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getAverageTimePerSpaceReport($location: ID, $start: String, $end: String) {
    getAverageTimePerSpaceReport(location: $location, start: $start, end: $end) {
      month
      carpark
      visits
      average
    }
  }
`)

export const getTotalMonthlySalesReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getTotalMonthlySalesReport($location: ID, $start: String, $end: String) {
    getTotalMonthlySalesReport(location: $location, start: $start, end: $end) {
      carpark
      month
      total
    }
  }
`)

export const getTotalWeeklySalesReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getTotalWeeklySalesReport($location: ID, $start: String, $end: String) {
    getTotalWeeklySalesReport(location: $location, start: $start, end: $end) {
      carpark
      week
      total
    }
  }
`)

export const getTotalDailySalesReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getTotalDailySalesReport($location: ID, $start: String, $end: String) {
    getTotalDailySalesReport(location: $location, start: $start, end: $end) {
      carpark
      day
      total
    }
  }
`)

export const getTotalDailyVisitRevenue: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getTotalDailyVisitRevenue($location: ID, $start: String, $end: String) {
    getTotalDailyVisitRevenue(location: $location, start: $start, end: $end) {
      carpark
      date
      visits
      total
      fee
    }
  }
`)

export const getAveragePaymentPerSpaceReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getAveragePaymentPerSpaceReport($location: ID, $start: String, $end: String) {
    getAveragePaymentPerSpaceReport(location: $location, start: $start, end: $end) {
      month
      carpark
      bays
      payments
      average
    }
  }
`)

export const getPostpaidVehicleVisitsReport: Query<any, {
  location: String | null, rego: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getPostpaidVehicleVisitsReport($location: ID, $rego: String, $start: String, $end: String) {
    getPostpaidVehicleVisitsReport(location: $location, rego: $rego, start: $start, end: $end) {
      rego
      carpark
      entrytime
      exittime
      duration
      tariff
      name
      email
    }
  }
`)

export const getVehicleReadExceptionsReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getVehicleReadExceptionsReport($location: ID, $start: String, $end: String) {
    getVehicleReadExceptionsReport(location: $location, start: $start, end: $end) {
      time
      rego
      carpark
      confidence
      reason
    }
  }
`)

export const getUsersByTariffReport: Query<any, {
  location: String | null, start: String | null, end: String | null
}> = createQuery(`
  query getUsersByTariffReport($location: ID, $start: String, $end: String) {
    getUsersByTariffReport(location: $location, start: $start, end: $end) {
      date
      carpark
      tariff
      total
    }
  }
`)
