import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { createSubscription, getSubscriptions } from '../../services/graphql/queries/subscription'
import { ApiResourceHookConfig } from '../../types/api'
import { CustomerSubscriptionHook, Subscription } from '../../types/subscription'
import { useApi } from './api'
import { useUser } from './user'

/**
 * Api resource hook for customer subscriptions
 * @param {ApiResourceHookConfig | undefined} config - The options for this hook
 * @return {CustomerSubscriptionHook}
 */
function useCustomerSubscription (config?: ApiResourceHookConfig): CustomerSubscriptionHook {
  const createSub = useApi(createSubscription)
  const subs = useApi(getSubscriptions)

  const createRes = createSub.getResponse()
  const subsRes = subs.getResponse()

  const user = useUser()

  const [subscriptions, setSubscriptions] = useState<Map<string, Subscription>>(new Map())

  /**
   * Adds a subscription for the current user
   * @param {string} locationId - The id of the location the subscription is for
   * @param {number} numVehicles - The num of vehicles the subscription is for
   */
  function addSubscription (locationId: string, numVehicles: number) {
    createSub.sendRequest({ locationId, numVehicles, user: user.id })
  }

  useEffect(() => {
    subs.sendRequest({ userId: user.id })
    return () => {
      createSub.cleanup()
      subs.cleanup()
    }
  }, [])

  useEffect(() => {
    if (subsRes.data !== null) {
      const map = new Map<string, Subscription>()
      subsRes.data.items.forEach((e) => map.set(e.id, e))
      setSubscriptions(map)
    }
  }, [subsRes.data])

  useEffect(() => {
    if (config?.displayErrorAlerts && subsRes.error !== null) {
      toast.error('Error', {
        description: subsRes.error.message,
      })
    }
  }, [subsRes.error])

  return {
    subscriptions,
    subscriptionsError: subsRes.error,
    subscriptionsLoading: subsRes.loading,
    addSubscription,
    addSubscriptionLoading: createRes.loading,
    addSubscriptionResponse: createRes.data,
    addSubscriptionError: createRes.error,
  }
}

export {
  useCustomerSubscription
}
