/* eslint-disable camelcase */
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'

import { config } from '../config'
import { getQueryName } from './helpers'
import type { ApiResponse, GraphQLResult } from '../../types/api'

Amplify.configure(config)

const client = generateClient()

/**
 * Run a GraphQL API query with the authenticated amplify client
 */
const run = async <Type>(query: string, variables: Record<string, any>): ApiResponse<Type> => {
  const queryName = getQueryName(query)
  const res = await client.graphql({ query, variables }) as unknown as GraphQLResult<Type>
  return res.data ? res.data[queryName] as Type : null as Type
}

export {
  run
}
