import React, { ReactElement } from 'react'

import { Panel } from 'rsuite'

import { usePageTitle, useUser } from '../../app/hooks'
import { DefinitionList, PanelHeader } from '../../components'
import { Organisation } from '../../types/organisation'
import { OrganisationGroups } from './OrganisationGroups'
import { UserRole } from '../../types/enums'

type Props = {
  organisation : Organisation
  onEdit : () => void
}

/**
 * Component to display information about a single organisation
 * @param {Props} props
 * @param {Organisation} props.organisation - Organisation object
 * @param {()=>void} props.onEdit
 * @returns {ReactElement}
 */
function OrganisationDefinition (props: Props): ReactElement {
  const { organisation, onEdit } = props
  const user = useUser()
  const isAdmin = user.hasRole(UserRole.ADMIN, UserRole.OPERATOR)
  const usedSpaces = (JSON.parse(organisation.groups) || [])
    .reduce((prev: any, curr: { vehicles: string | any[]}) => prev + curr.vehicles.length, 0)

  const organisationData = {
    Name: organisation.name,
    Description: organisation.description !== '' ? organisation.description : '-',
    Location: organisation.locationName ?? '-',
    'Total Spaces Allocated': organisation.spaces,
    'Total Users': usedSpaces,
  }

  usePageTitle('Organisation details')
  return (
    <div>
      <Panel
        header={(
          <PanelHeader
            header="Organisation details"
            onEdit={onEdit}
            editDisabled={!isAdmin}
          />
        )}
      >
        <DefinitionList data={organisationData} />
      </Panel>
      <OrganisationGroups isAdmin={isAdmin} data={organisation} />
    </div>
  )
}

export { OrganisationDefinition }
