import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  Panel, Table
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { useApi, useModal } from '../../app/hooks'
import { getLocationGroups, getLocation, removeGroup } from '../../services/graphql/queries'
import {
  ConfirmModal, TableData, ActionMenu, Modal, PanelHeader
} from '../../components'
import { deleteFromPaginatedWithId } from '../../helpers'
import type { RowData } from '../../types/table'
import type { GroupConfig } from '../../types/group'

const { Column, HeaderCell, Cell } = Table

/**
 * Group list component
 * @return {ReactElement}
 */
function LocationGroups (): ReactElement {
  const navigate = useNavigate()

  const { locationId } = useParams()
  const groupQuery = useApi(getLocationGroups)
  const locationQuery = useApi(getLocation)
  const deleteQuery = useApi(removeGroup)

  const groupData = groupQuery.getResponse()
  const locationData = locationQuery.getResponse()
  const modal = useModal()
  const [groupForRemoval, setGroupForRemoval] = useState<GroupConfig>()

  useEffect(() => {
    if (locationId) {
      groupQuery.sendRequest({ locationId })
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      groupQuery.cleanup()
      locationQuery.cleanup()
      deleteQuery.cleanup()
    }
  }, [])

  /**
   * Show the group removal confirmation modal
   * @param {RowData} group - The group to remove
   */
  const confirmRemoval = (group: RowData) => {
    setGroupForRemoval(group as GroupConfig)
    modal.show()
  }
  /**
   * Remove the group from the location and reload the group list
   */
  const remove = () => {
    if (groupForRemoval) {
      deleteQuery.sendRequest({
        groupId: groupForRemoval.id,
      }, () => {
        groupQuery.setData((oldData: any) => deleteFromPaginatedWithId(oldData, groupForRemoval.id))
      })
    }
  }

  /**
   * Dropdown menu for a row in the group list table
   * @param {RowData} group - The group object of the row
   * @return {ReactElement}
   */
  const renderActions = (group: RowData): ReactElement => {
    const actions = [
      { label: 'Delete', action: () => confirmRemoval(group) },
    ]
    return (
      <ActionMenu actions={actions} />
    )
  }

  const groups = (locationData.data && groupData.data?.items) || [{ name: 'one' }]

  return (
    <>
      <Panel
        className="location-panel"
        header={(
          <PanelHeader
            header="Car Park Groups"
            onEdit={() => navigate('groups/new')}
            icon={<Icon as={FaPlus} />}
          />
        )}
      >
        <Table
          autoHeight
          data={groups}
          loading={groupData.loading}
        >
          <Column flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name">
              {(rowData) => (
                <Link to={`groups/${rowData.id}`}>
                  {rowData.name}
                </Link>
              )}
            </Cell>
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>Users</HeaderCell>
            <Cell dataKey="users">
              {(rowData) => (
                <div>{rowData.users?.length ?? 0}</div>
              )}
            </Cell>
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>Actions</HeaderCell>
            <TableData dataKey="action" content={renderActions} />
          </Column>
        </Table>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`The group "${groupForRemoval?.name}" will be removed from this location.`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  LocationGroups
}
