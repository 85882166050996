/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  DatePicker, Table
} from 'rsuite'

import dayjs from 'dayjs'
import moment from 'moment-timezone'

import { getDeclinedPaymentsReport } from '../../services/graphql/queries'
import { useApi } from '../../app/hooks'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'

const { Column, HeaderCell, Cell } = Table

const defaultColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'processed',
    label: 'Transaction date',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
]

function DeclinedPaymentsReport () {
  const [sortColumn, setSortColumn] = React.useState('name')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [startdate, setStartdate] = React.useState<Date | null>(null)
  const [enddate, setEnddate] = React.useState<Date | null>(null)

  const dataQuery = useApi(getDeclinedPaymentsReport)
  const res = dataQuery.getResponse()

  React.useEffect(() => {
    dataQuery.sendRequest({
      from: startdate ? moment.parseZone(startdate).startOf('day').utc().format() : null,
      to: enddate ? moment.parseZone(enddate).endOf('day').utc().format() : null,
    })
    return () => {
      dataQuery.cleanup()
    }
  }, [startdate, enddate])

  const data = res.data ? res.data.map((v: any) => ({
    ...v,
    created: dayjs(v.entrytime).format('YYYY-MM-DD'),
  })) : []

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return data.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return data
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }
  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `DeclinedPaymentsReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div>
      <div style={{
        display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
      }}
      >
        <div style={{ width: 200 }}>
          From transaction date
          <DatePicker
            onChange={(date: Date | null) => setStartdate(date)}
            value={startdate}
          />
        </div>
        <div style={{ width: 200 }}>
          To transaction date
          <DatePicker onChange={setEnddate} value={enddate} />
        </div>
        <DownloadTableToCsv
          style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
          url={URL.createObjectURL(dataExport)}
          fileName={dataExport.name}
          text="Download to file"
        />
      </div>

      <Table
        loading={res.loading || loading}
        hover={false}
        showHeader
        autoHeight
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        defaultSortType="asc"
        onSortColumn={handleSortColumn}
        bordered
        headerHeight={40}
        rowHeight={46}
      >
        {defaultColumns.map((column) => {
          const { key, label, ...rest } = column
          return (
            <Column {...rest} key={key} flexGrow={1} fullText sortable>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key} />
            </Column>
          )
        })}
      </Table>
    </div>
  )
}

export {
  DeclinedPaymentsReport
}
