import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// eslint-disable-next-line import/no-unresolved
import { Toaster } from 'sonner'

import { store } from './app/store'
import { Router } from './router'
import * as serviceWorker from './serviceWorker'

import 'rsuite/dist/rsuite.min.css'
import './index.css'
import './theme.css'
import './font.css'
import './print.css'

const gtmId = process.env.REACT_APP_GTM_ID
if (gtmId && gtmId.match(/GTM-[A-Z0-9]{6}/)) {
  TagManager.initialize({ gtmId })
} else {
  // eslint-disable-next-line no-console
  console.warn('Ignoring missing or invalid GTM ID')
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus might breaks some UIs
      // so we only selectively want to enable this
      refetchOnWindowFocus: false,
      // Structural sharing between responses makes it impossible to rely on
      // "first seen" timestamps on objects to determine if they're fresh.
      // Disable this optimization so that we can rely on "first seen" timestamps.
      structuralSharing: false,
      // We don't want to retry queries by default, because in most cases we
      // want to fail early and show a response to the user. There are
      // exceptions, and those can be made on a per-query basis. For others, we
      // should give users controls to retry.
      retry: false,
    },
  },
})

export function App () {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Toaster toastOptions={{ className: 'toast', duration: 5000 }} />
        <Router />
      </QueryClientProvider>
    </Provider>
  )
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
