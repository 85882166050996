import React from 'react'
import { Link } from 'react-router-dom'
import './CustomerDashboardSection.css'

type SectionType = 'profile' | 'subscriptions' | 'vehicles'

/**
 * Prop types for {@link CustomerDashboardSection}
 */
type CustomerDashboardSectionProps = {
  /** Specifies the section of the dashboard */
  sectionType: SectionType
  /** The href of the 'manage' link displayed in the header */
  manageRoute: string
  /** Section contents */
  children?: React.ReactNode
  title: string,
  description: string,
}

const cssVariant: Record<SectionType, string> = {
  profile: '-profile',
  subscriptions: '-subscription',
  vehicles: '-vehicle',
}

/**
 * A layout helper for sections of the customer dashboard
 */
function CustomerDashboardSection (props: CustomerDashboardSectionProps): React.ReactElement {
  const {
    title, description, sectionType, children, manageRoute,
  } = props
  return (
    <div className="dashboard-section">
      <div className="dashboard-section-header">
        <div>
          <div className="title">
            {title}
          </div>
          <div className="description">
            {description}
          </div>
        </div>
        {sectionType !== 'profile' && <Link to={manageRoute} className="manage"> Manage </Link>}
      </div>
      <div className={`dashboard-section-content ${cssVariant[sectionType]}`}>
        {children}
      </div>
    </div>
  )
}

export {
  CustomerDashboardSection,
  type CustomerDashboardSectionProps
}
