import React, { ReactElement, useEffect } from 'react'
import { Panel } from 'rsuite'

import { useNavigate } from 'react-router-dom'

import { PanelHeader } from '../../components'
import { OrganisationForm } from '../../forms/OrganisationForm'
import { createOrganisation } from '../../services/graphql/queries'
import {
  useApi, usePageTitle, useFormState, useUser
} from '../../app/hooks'

type NewOrganisationDetailsFormData = {
  name: string,
  description: string,
  spaces: number,
}

/**
 * Component to create an organisation
 * @param {() => void} props.onFinish - Cb when editing is finished
 * @returns {ReactElement}
 */
function OrganisationCreate (): ReactElement {
  const navigate = useNavigate()
  const user = useUser()
  const onFinish = () => { navigate(`/${user.getRoleForRoute()}/organisations`) }
  const createOrganisationApi = useApi(createOrganisation)

  const { error, loading, data } = createOrganisationApi.getResponse()

  usePageTitle('New organisation')

  useEffect(() => {
    if (data !== null) {
      onFinish()
    }
  }, [data])

  const [formData, setField] = useFormState<NewOrganisationDetailsFormData>({
    name: '',
    description: '',
    spaces: 0,
  })
  const onCreateOrganisation = () => {
    const requestParams = {
      name: formData.name,
      description: formData.description,
      spaces: formData.spaces,
    }
    createOrganisationApi.sendRequest(requestParams)
  }

  const formId = 'organisation-create-form'
  return (
    <Panel
      header={(
        <PanelHeader
          editing
          formId={formId}
          onCancel={onFinish}
          header="Edit organisation"
          loading={loading}
        />
      )}
    >
      <OrganisationForm
        formId={formId}
        formValue={formData}
        onChange={setField}
        onSubmit={onCreateOrganisation}
        error={error}
        mode="new"
      />
    </Panel>
  )
}

export { OrganisationCreate }
