import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { UserErrorCode as Errors } from '../types/enums'
import type { FormProps } from '../types/form'

/**
 * Set new password form component
 * @return {ReactElement}
 */
function SetPasswordForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    loading,
    error,
  } = props

  const validator = new Validator({
    code: {
      model: schemas.confirmCode('Please enter your confirmation code'),
      errors: [Errors.BAD_CODE],
    },
    password: {
      model: schemas.password('Please set your new account password'),
    },
  })

  return (
    <ValidatedForm
      fluid
      validator={validator}
      formValue={formValue}
      onSubmit={onSubmit}
      onChange={onChange}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="email">
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="email" type="email" disabled />
      </Form.Group>
      <Form.Group controlId="code">
        <Form.ControlLabel>Confirmation code</Form.ControlLabel>
        <Form.Control name="code" type="text" autoComplete="off" autoFocus />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.ControlLabel>Password</Form.ControlLabel>
        <Form.Control name="password" type="password" />
      </Form.Group>
      <Form.Group>
        <Button appearance="primary" loading={loading} type="submit">Change password</Button>
        <Button appearance="link" as={Link} to="/login">Cancel</Button>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  SetPasswordForm
}
