import React from 'react'

import { CustomerDashboardInfo } from '../../types/dashboard'
import './CustomerDashboard.css'
import { CustomerDashboardMember } from './CustomerDashboardMember'
import { CustomerDashboardCasual } from './CustomerDashboardCasual'

/** Prop type for {@link CustomerDashboard} */
type CustomerDashboardProps = CustomerDashboardInfo

/**
 * Customer dashboard which displays the profile status of the customer
 * and the customer's subscriptions & vehicles
 */
function CustomerDashboard (props: CustomerDashboardProps):React.ReactElement {
  const {
    casualDashboard,
    showSubscriptionHeader,
    vehicles,
    subscriptions,
    vehiclesActive,
    subscriptionsActive,
    paymentMethodExists,
    addPaymentMethodCb,
    addSubscriptionCb,
    addVehicleCb,
    vehicleIcon,
    subscriptionIcon,
    paymentIcon,
  } = props

  return (
    casualDashboard
      ? (
        <CustomerDashboardCasual
          showSubscriptionHeader={showSubscriptionHeader}
          casualDashboard={casualDashboard}
          addSubscriptionCb={addSubscriptionCb}
          addPaymentMethodCb={addPaymentMethodCb}
          addVehicleCb={addVehicleCb}
          vehiclesActive={vehiclesActive}
          vehicleIcon={vehicleIcon}
          subscriptionsActive={subscriptionsActive}
          subscriptionIcon={subscriptionIcon}
          paymentIcon={paymentIcon}
          paymentMethodExists={paymentMethodExists}
          vehicles={vehicles}
          subscriptions={subscriptions}
        />
      )
      : (
        <CustomerDashboardMember
          showSubscriptionHeader={showSubscriptionHeader}
          casualDashboard={casualDashboard}
          addSubscriptionCb={addSubscriptionCb}
          addPaymentMethodCb={addPaymentMethodCb}
          addVehicleCb={addVehicleCb}
          vehiclesActive={vehiclesActive}
          vehicleIcon={vehicleIcon}
          subscriptionsActive={subscriptionsActive}
          subscriptionIcon={subscriptionIcon}
          paymentIcon={paymentIcon}
          paymentMethodExists={paymentMethodExists}
          vehicles={vehicles}
          subscriptions={subscriptions}
        />
      )
  )
}

export {
  CustomerDashboard
}
