import { createSlice } from '@reduxjs/toolkit'

import * as reducers from './reducers'
import * as selectors from './selectors'
import * as thunks from './thunks'
import { StoreStatus } from '../../types/enums'
import type { AuthUser, UserError } from '../../types/user'

export type UserState = {
  user: AuthUser|null,
  status: StoreStatus,
  error: UserError|null,
}

const initialState: UserState = {
  user: null,
  status: StoreStatus.LOADING,
  error: null,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers,
})

const userActions = { ...slice.actions, ...thunks }
const userReducer = slice.reducer
const userSelectors = selectors

export {
  userActions,
  userReducer,
  userSelectors
}
