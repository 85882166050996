import React, {
  ChangeEvent, ReactElement, SyntheticEvent, useEffect, useMemo, useState
} from 'react'
import {
  Button,
  Form, IconButton, Input, InputPicker
} from 'rsuite'

import { FormControlAccepterProps } from 'rsuite/esm/FormControl'

import { FaTimes } from 'react-icons/fa'

import { Icon } from '@rsuite/icons'

import { nanoid } from 'nanoid'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'
import * as options from '../features/access-lists/options'
import './AccessListForm.css'

type AccessListItemFormProps = FormControlAccepterProps & {
  formErrors?: Record<string, any>,
}

/**
 * A single repeatable form row for describing an access list item
 * @param {AccessListItemFormProps} props
 * @return {ReactElement}
 */
function RegoItemForm (props: AccessListItemFormProps): ReactElement {
  const { onChange, value = [], formErrors } = props
  const [items, setItems] = useState<{
    value:string,
    description:string
  }[]>([])

  useEffect(() => {
    setItems(value)
  }, [value])

  /**
   * Update the form state for a rego field input change
   * @param {number} index
   * @param {string} rego
   * @param {ChangeEvent} event
   */
  const handleRegoChange = (
    index: number,
    rego: string,
    event: ChangeEvent
  ) => {
    const newItem = items[index] || {
      value: '',
      description: '',
    }
    newItem.value = rego
    items[index] = newItem
    const newItems = [...items]
    setItems(newItems)
    onChange?.(newItems, event)
  }

  /**
   * Update the form state for a description field input change
   * @param {number} index
   * @param {string} description
   * @param {ChangeEvent} event
   */
  const handleDescriptionChange = (
    index: number,
    description: string,
    event: ChangeEvent
  ) => {
    const newItem = items[index] || {
      value: '',
      description: '',
    }
    newItem.description = description
    items[index] = newItem
    const newItems = [...items]
    setItems(newItems)
    onChange?.(newItems, event)
  }

  /**
   * Handle the removal of an access list item
   * @param {number} index
   * @param {SyntheticEvent} event
   */
  const onRemove = (index: number, event: SyntheticEvent) => {
    items.splice(index, 1)
    const newItems = [...items]
    setItems(newItems)
    onChange?.(newItems, event)
  }

  /**
   * Handle the addition of a new access list item
   * @param {SyntheticEvent} event
   */
  const onAdd = (event: SyntheticEvent) => {
    const newItems = [...items, { value: '', description: '' }]
    setItems(newItems)
    onChange?.(newItems, event)
  }

  /**
   * Show a form error
   * @param {string} message
   * @return {ReactElement}
   */
  const errorAlert = (message:string): ReactElement => (
    <div className="rs-form-control-message-wrapper rs-form-error-message-wrapper ">
      <span className="rs-form-error-message rs-form-error-message-show">
        <span className="rs-form-error-message-arrow" />
        <span className="rs-form-error-message-inner">{message}</span>
      </span>
    </div>
  )

  const ids = useMemo(() => items.map(() => nanoid()), [items.length])

  return (
    <div className="rego-list">
      {
        items.map((e, i) => {
          const valueErr = formErrors?.items?.array?.[i]?.object?.value?.errorMessage
          const descriptionErr = formErrors?.items?.array?.[i]?.object?.description?.errorMessage
          return (
            <div className="group rs-form-group" key={ids[i]}>
              <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="rego" className="rs-form-control-label">
                  Registration number
                </label>
                <Input
                  name="rego"
                  id="rego"
                  onChange={(
                    rego,
                    event
                  ) => handleRegoChange(i, rego, event)}
                  value={e.value}
                />
                {valueErr ? errorAlert(valueErr) : null}
              </div>
              <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="description" className="rs-form-control-label">
                  Description
                </label>
                <Input
                  name="description"
                  id="description"
                  value={e.description}
                  onChange={(
                    descrip,
                    event
                  ) => handleDescriptionChange(i, descrip, event)}
                />
                {descriptionErr ? errorAlert(descriptionErr) : null}
              </div>
              <IconButton
                appearance="subtle"
                circle
                icon={<Icon as={FaTimes} />}
                onClick={(event) => onRemove(i, event)}
              />
            </div>
          )
        })
      }
      <Button onClick={onAdd}>Add item</Button>
    </div>
  )
}

/**
 * AccessList form component
 * @param {Props} props
 * @return {ReactElement}
 */
function AccessListForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId,
  } = props

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the access list'),
    },
    type: {
      model: schemas.required('Please select a type for this access list'),
    },
    items: {
      model: schemas.accesslistItems('Vehicle registration number'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      noCheckOnChange
      error={error}
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="type">
        <Form.ControlLabel>Type</Form.ControlLabel>
        <Form.Control name="type" accepter={InputPicker} data={options.accessListTypes} />
      </Form.Group>
      <Form.Control name="items" accepter={RegoItemForm} />
    </ValidatedForm>
  )
}

export {
  AccessListForm
}
