import React, { ReactElement } from 'react'

import { Loader } from 'rsuite'

type Props = {
  data: Record<string, any>,
  loading?: boolean,
}

/**
 * A list of key value pairs
 * @param {Props} props
 * @return {ReactElement}
 */
function DefinitionList (props: Props): ReactElement {
  const { data, loading } = props
  return loading ? <Loader /> : (
    <dl>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <dt>{key}</dt>
          <dd>{value}</dd>
        </div>
      ))}
    </dl>
  )
}

export {
  DefinitionList
}
