import { UserDetails } from '../../types/user'

const NAME_MAP: Record<string, string> = {
  givenName: 'given_name',
  familyName: 'family_name',
  email: 'email',
  phone: 'phone_number',
}

/**
 * Convert user details property names to those required by Amplify
 * @param {Object} details
 * @return {Object}
 */
const mapAttributeNames = (details: Partial<UserDetails>): Record<string, any> => {
  const result: Record<string, any> = {}
  Object.entries(details).forEach(([key, value]) => {
    const mappedKey = NAME_MAP[key]
    if (mappedKey) {
      result[mappedKey] = value
    }
  })
  return result
}

export {
  mapAttributeNames
}
