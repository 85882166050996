import { useSyncExternalStore } from 'react'

import { PaginatedResponse } from './types/api'

/**
 * Utility function to delete an item from a paginated query result.
 * @param {PaginatedResponse<T>} data - Existing query result
 * @param {(item:T)=>K} selector      - Retrieves a unique identifier from an item, if the supplied
 *                                      match arg === this identifier, the item will be deleted
 * @param {K} match                   - The unique identfier of the item to be deleted
 */
export function deleteFromPaginated<T, K> (
  data: PaginatedResponse<T> | null,
  // eslint-disable-next-line no-unused-vars
  selector: (item:T)=>K,
  match: K
) : PaginatedResponse<T> | null {
  if (!data) {
    return null
  }
  const newData = {
    ...data,
    items: data.items.filter((e) => selector(e) !== match),
  }
  return newData
}

/**
 * Utility function to delete an item with an id from a paginated
 * query result.
 *
 * @param {PaginatedResponse<T>} data - Existing query result
 * @param {string} id                 - The id of the item to delete
 */
export function deleteFromPaginatedWithId<T extends {id:string}> (
  data: PaginatedResponse<T>|null,
  id: string
) {
  return deleteFromPaginated(data, (e) => e.id, id)
}

/**
 * Utility function to replace an item in a paginated query result
 * @param {PaginatedResponse<T>} data - Existing query result
 * @param {T} item                    - The new item to replace into the the query, identified by ID
 * @return {PaginatedResponse<T>|null}
 */
export function replaceInPaginatedWithId<T extends {id: string}> (
  data: PaginatedResponse<T>|null,
  item: T
) {
  if (!data) {
    return null
  }
  return {
    ...data,
    items: data.items.map((e) => (e.id === item.id ? item : e)),
  }
}

/**
 * Utility function to split an array of items into separate pages
 *
 * @param {T[]} data              - The array of data to split
 * @param {number} itemsPerpage   - The maximum number of items per page
 */
export function paginateItems<T> (data:T[], itemsPerpage: number) {
  const pages: T[][] = []
  const dataMut = [...data]
  const numIters = Math.ceil(data.length / itemsPerpage)

  for (let i = 0; i < numIters; i += 1) {
    pages.push(dataMut.splice(0, itemsPerpage))
  }
  return {
    numPages: pages.length,
    pages,
  }
}

// Utility functions to create a hook to return the viewport subscribed to window resize
function subscribeToWindowResize (callback: () => void) {
  window.addEventListener('resize', callback)
  return () => window.removeEventListener('resize', callback)
}

function getWindowSnapshot () { return window }

export function useWindowDimensions () {
  return useSyncExternalStore(subscribeToWindowResize, getWindowSnapshot)
}

// Utility to clamp a number in an inclusive range
export function clamp (num: number, min: number, max: number) { return Math.min(Math.max(num, min), max) }
