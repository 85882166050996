import { useMemo, useState } from 'react'
import {
  AppConfigDataClient,
  BadRequestException,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand
} from '@aws-sdk/client-appconfigdata'

let existingToken: string
const client = new AppConfigDataClient({
  region: process.env.REACT_APP_FEATURE_FLAG_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_FEATURE_FLAG_KEY || '',
    secretAccessKey: process.env.REACT_APP_FEATURE_FLAG_ACCESS || '',
  },
})

const getToken = async (): Promise<string> => {
  const getSession = new StartConfigurationSessionCommand({
    ApplicationIdentifier: process.env.REACT_APP_FEATURE_FLAG_APP_IDENTIFIER,
    ConfigurationProfileIdentifier: process.env.REACT_APP_FEATURE_FLAG_PROFILE_IDENTIFIER,
    EnvironmentIdentifier: process.env.REACT_APP_FEATURE_FLAG_ENVIRONMENT_IDENTIFIER,
  })
  const sessionToken = await client.send(getSession)
  return sessionToken.InitialConfigurationToken || ''
}

const featureFlag = async (flag: string): Promise<boolean> => {
  if (!existingToken) {
    existingToken = await getToken()
  }
  try {
    const command = new GetLatestConfigurationCommand({
      ConfigurationToken: existingToken,
    })
    const response = await client.send(command)
    let flags: any = {}
    if (response.Configuration) {
      const str = new TextDecoder().decode(response.Configuration)
      const allFlag = JSON.parse(str)
      flags = { ...allFlag }
    }
    return Boolean(flags[flag]?.enabled)
  } catch (err) {
    if (err instanceof BadRequestException) {
      existingToken = await getToken()
      return featureFlag(flag)
    }
    throw err
  }
}

/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey = '') => {
  const [enabled, setEnabled] = useState(false)

  useMemo(async () => {
    if (!flagKey || !flagKey.toString().trim().length) {
      // eslint-disable-next-line no-console
      console.error('A feature flag key must be supplied.')
    } else {
      try {
        const flag = await featureFlag(flagKey)
        setEnabled(flag)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }, [flagKey])
  return { enabled }
}

export { useFeatureFlag }
