/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-return-assign */
import React, { ReactElement, useState } from 'react'
import {
  Input, Panel, Table, Button, Loader, TagGroup
} from 'rsuite'

import moment from 'moment-timezone'
import './CustomerSearch.css'

import { useApi } from '../../app/hooks'
import { getVehicleByRego } from '../../services/graphql/queries'
import TariffOverrideModal from './TariffOverrideModal'
import { DefinitionList } from '../../components'
import { AccessTag } from '../../components/AccessTag'

const { Column, HeaderCell, Cell } = Table

/**
 * Component to create Gate
 * @returns {ReactElement}
 */
function CustomerSearch () : ReactElement {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const [value, setValue] = useState<string>('')

  const vehicle = useApi(getVehicleByRego, {
    displayErrorAlerts: false,
    cleanUpOnDismount: true,
  })

  const vehicleRes = vehicle.getResponse()
  const vehicleData = vehicleRes.data

  const performSearch = async () => {
    if (value?.length === 0) return
    vehicle.reset()
    await vehicle.sendRequest({ rego: value })
  }

  const handleClose = () => {
    performSearch()
    setOpen(false)
  }

  const memberData = vehicleData ? {
    Name: `${vehicleData?.givenName} ${vehicleData?.familyName}`,
    Email: vehicleData?.email,
    Phone: vehicleData?.phoneNumber,
  } : {}

  const orgData = vehicleData ? {
    Name: vehicleData?.organisation?.name,
    Carpark: vehicleData?.organisation?.locationName,
    Spaces: vehicleData?.organisation?.spaces,
  } : {}

  const allowListData = vehicleData ? {
    Name: vehicleData?.allowList,
    Carpark: vehicleData?.allowListLocation,
    Type: vehicleData?.allowListType,
  } : {}

  const vehicleTypeOptions = (types: string[]) : ReactElement => {
    if (types?.length === 0) {
      return <div />
    }
    const tags : ReactElement[] = []
    types.forEach((t) => {
      tags.push(<AccessTag access={t} />)
    })
    return <TagGroup className="vehicle-search">{tags}</TagGroup>
  }

  return (
    <>
      <Panel
        header={(
          <h2>Vehicle search</h2>
        )}
      >
        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Vehicle registration..."
            value={value}
            onChange={(v) => setValue(v)}
            style={{ marginBottom: 8, textTransform: 'uppercase' }}
          />
          <Button
            disabled={value.length === 0}
            appearance="primary"
            onClick={performSearch}
          >
            Search
          </Button>
          {
            (vehicleRes.success && vehicleData)
              ? (
                <Button
                  disabled={value?.length === 0}
                  style={{ marginLeft: 8, textTransform: 'uppercase' }}
                  onClick={handleOpen}
                >
                  Apply tariff override
                </Button>
              ) : null
          }
        </div>
        <div>
          {(vehicleRes.loading) && (
            <Loader>
              {' '}
              Loading...
            </Loader>
          )}
          {(vehicleRes.success && !vehicleData) && (<p>No match found.</p>)}
        </div>
      </Panel>
      {
        (vehicleRes.success && vehicleData) && (
          <>
            {
              !vehicleData.types ? <div />
                : (
                  <Panel
                    header={(<h3>Vehicle type</h3>)}
                    className="result-panel vehicle-search-details"
                  >
                    {vehicleTypeOptions(vehicleRes.data?.types || []) }
                  </Panel>
                )
            }
            {
              !vehicleData.rego ? <div />
                : (
                  <Panel header={(<h3>Vehicle details</h3>)} className="result-panel">
                    <Table
                      autoHeight
                      data={[vehicleData]}
                    >
                      <Column>
                        <HeaderCell>Registration</HeaderCell>
                        <Cell dataKey="rego" />
                      </Column>
                      <Column>
                        <HeaderCell>State</HeaderCell>
                        <Cell dataKey="state" />
                      </Column>
                      <Column>
                        <HeaderCell>Make</HeaderCell>
                        <Cell dataKey="make" />
                      </Column>
                      <Column>
                        <HeaderCell>Model</HeaderCell>
                        <Cell dataKey="model" />
                      </Column>
                      <Column>
                        <HeaderCell>Colour</HeaderCell>
                        <Cell dataKey="colour" />
                      </Column>
                      <Column fullText width={300}>
                        <HeaderCell>Flags</HeaderCell>
                        <Cell dataKey="flags">
                          {(rowData) => (
                            <div>{rowData.flags?.join(', ')}</div>
                          )}
                        </Cell>
                      </Column>
                    </Table>
                  </Panel>
                )
            }
            {
              !vehicleData.allowList ? <div />
                : (
                  <Panel
                    header={(<h3>Access list details</h3>)}
                    className="result-panel accesslist vehicle-search-details"
                  >
                    <DefinitionList loading={vehicleRes.loading} data={allowListData} />
                  </Panel>
                )
            }
            {
              !vehicleData.organisation ? <div />
                : (
                  <Panel header={(<h3>Organisation details</h3>)} className="result-panel">
                    <DefinitionList loading={vehicleRes.loading} data={orgData} />
                  </Panel>
                )
            }
            {
              !vehicleData.subscriptions ? <div />
                : (
                  <Panel header={(<h3>Member details</h3>)} className="result-panel">
                    <DefinitionList loading={vehicleRes.loading} data={memberData} />
                  </Panel>
                )
            }
            {
              !vehicleData.subscriptions ? <div />
                : (
                  <Panel header={(<h3>Subscription history</h3>)} className="result-panel">
                    <Table
                      data={vehicleData.subscriptions}
                      autoHeight
                    >
                      <Column fullText flexGrow={0.2}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="plan" />
                      </Column>
                      <Column fullText flexGrow={0.2}>
                        <HeaderCell>Car park</HeaderCell>
                        <Cell dataKey="location" />
                      </Column>
                      <Column flexGrow={0.25}>
                        <HeaderCell>Start date</HeaderCell>
                        <Cell dataKey="startDate">
                          {(rowData) => (
                            <div>{moment.parseZone(rowData.startDate).format('DD/MM/YYYY HH:mm')}</div>
                          )}
                        </Cell>
                      </Column>
                      <Column flexGrow={0.25}>
                        <HeaderCell>End date</HeaderCell>
                        <Cell dataKey="endDate">
                          {(rowData) => (
                            <div>
                              {
                                rowData.endDate
                                  ? moment.parseZone(rowData.endDate).format('DD/MM/YYYY HH:mm')
                                  : ''
                              }
                            </div>
                          )}
                        </Cell>
                      </Column>
                      <Column flexGrow={0.25}>
                        <HeaderCell>Status</HeaderCell>
                        <Cell dataKey="status" />
                      </Column>
                      <Column flexGrow={0.15}>
                        <HeaderCell>Vehicles</HeaderCell>
                        <Cell dataKey="vehicles" />
                      </Column>
                    </Table>
                  </Panel>
                )
            }
            {
              !vehicleData.overrides ? <div />
                : (
                  <Panel header={(<h3>Tariff overrides</h3>)} className="result-panel">
                    <Table
                      data={vehicleData.overrides}
                      autoHeight
                    >
                      <Column>
                        <HeaderCell>Car park</HeaderCell>
                        <Cell dataKey="location" />
                      </Column>
                      <Column fullText width={200}>
                        <HeaderCell>Tariff applied</HeaderCell>
                        <Cell dataKey="tariff" />
                      </Column>
                      <Column width={150}>
                        <HeaderCell>Start time</HeaderCell>
                        <Cell dataKey="startTime">
                          {(rowData) => (
                            <div>{moment.parseZone(rowData.startTime).format('DD/MM/YYYY HH:mm')}</div>
                          )}
                        </Cell>
                      </Column>
                      <Column width={150}>
                        <HeaderCell>End time</HeaderCell>
                        <Cell dataKey="endTime">
                          {(rowData) => (
                            <div>
                              {
                                rowData.endTime
                                  ? moment.parseZone(rowData.endTime).format('DD/MM/YYYY HH:mm')
                                  : ''
                              }
                            </div>
                          )}
                        </Cell>
                      </Column>
                      <Column fullText width={300}>
                        <HeaderCell>Comment</HeaderCell>
                        <Cell dataKey="comment" />
                      </Column>
                    </Table>
                  </Panel>
                )
            }
            <Panel header={(<h3>Recent parking history </h3>)} className="result-panel">
              <Table
                data={vehicleData.parkingHistory}
                autoHeight
              >
                <Column>
                  <HeaderCell>Car park</HeaderCell>
                  <Cell dataKey="location" />
                </Column>
                <Column width={150}>
                  <HeaderCell>Entry time</HeaderCell>
                  <Cell dataKey="startTime">
                    {(rowData) => (
                      <div>
                        {
                          rowData.entrytime === '2000-01-01T00:00:00.000+10:00'
                            ? '' : moment.parseZone(rowData.entrytime).format('DD/MM/YYYY HH:mm')
                        }
                      </div>
                    )}
                  </Cell>
                </Column>
                <Column width={150}>
                  <HeaderCell>Exit time</HeaderCell>
                  <Cell dataKey="endTime">
                    {(rowData) => (
                      <div>
                        {
                          rowData.exittime === '2000-01-01T00:00:00.000+10:00'
                            ? '' : moment.parseZone(rowData.exittime).format('DD/MM/YYYY HH:mm')
                        }
                      </div>
                    )}
                  </Cell>
                </Column>
              </Table>
            </Panel>
          </>
        )
      }
      <TariffOverrideModal open={open} handleClose={handleClose} rego={value} />
    </>
  )
}

export {
  CustomerSearch
}
