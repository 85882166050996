import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiActions } from './slice'
import * as graphqlService from '../../services/graphql/client'
import { createError } from './errors'
import type {
  ApiCaller,
  ApiResponse,
  ThunkArgs
} from '../../types/api'

/**
 * Provide a caller function for use in an API query
 * @param {string} query - The query string passed by the API query
 * @param {Object} params - Any query params passed by the API query
 * @return {Object} - The API response data and metadata
 */
const caller: ApiCaller = <Type>(query: string, params: Record<string, any>): ApiResponse<Type> => (
  graphqlService.run(query, params)
)

const request = createAsyncThunk(
  'api/request',
  async ({ id, query, transformer }:ThunkArgs, { dispatch }) => {
    dispatch(apiActions.setLoading(id))
    const promise = query(caller)
    try {
      const data = (await promise)
      const transformed = transformer ? transformer(data) : data
      dispatch(apiActions.recieveResponse({ data: transformed, id }))
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error calling GraphQL API', error)
      }
      dispatch(apiActions.setFailed({ id, error: createError(error.errors) }))
    }
  }
)

export {
  request
}
