import React from 'react'
import {
  Modal, Button, ButtonToolbar, Table
} from 'rsuite'

import { TableData } from '../../components'
import type { Payment } from '../../types/payment'

import './PaymentModal.css'

type Props = {
  payments: Payment[],
  onConfirm: () => void,
  onClose: () => void,
  loading: boolean,
}

/**
 * A modal to present details of a payment to be made
 * @param {Props} props
 */
function PaymentModal (props: Props) {
  const {
    payments, onConfirm, onClose, loading,
  } = props
  const total = payments.reduce((acc, payment) => Number(payment.outstanding) + acc, 0)

  return (
    <>
      <Modal.Header>
        <Modal.Title>Confirm payment</Modal.Title>
      </Modal.Header>
      <Modal.Body className="payment-modal">
        <p>
          {`If you continue, we will attempt to take the following
          ${payments.length === 1 ? 'payment' : `${payments.length} payments`}
          from your nominated payment method:`}
        </p>
        <div className="payment-list">
          <Table data={payments}>
            <Table.Column width={100} fixed>
              <Table.HeaderCell>Payment due</Table.HeaderCell>
              <TableData dataKey="created" format="date" />
            </Table.Column>
            <Table.Column width={200} align="right" verticalAlign="middle" fixed>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <TableData dataKey="outstanding" format="money" />
            </Table.Column>
          </Table>
          <p className="total">
            Total
            <span>
              {`$${total.toFixed(2)}`}
            </span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button
            onClick={onConfirm}
            appearance="primary"
            loading={loading}
          >
            Pay now
          </Button>
          <Button
            onClick={onClose}
            appearance="subtle"
            disabled={loading}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  PaymentModal
}
