import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'rsuite'
import { skipToken, useQuery } from '@tanstack/react-query'

import { getLocation } from '../../services/graphql/queries'
import { LocationDefinition } from './LocationDefinition'
import { LocationEdit } from './LocationEdit'
import { makeToastFn } from '../../services/query/helpers'
import { GetLocationParams } from '../../types/location'

/**
 * View/edit location
 * @return {ReactElement}
 */
function LocationDetails (): ReactElement {
  const [editing, setEditing] = useState(false)
  const { locationId } = useParams()

  const { data } = useQuery({
    queryKey: ['getLocation', ({ locationId } as GetLocationParams)],
    queryFn: locationId ? makeToastFn(getLocation, 'location') : skipToken,
  })

  if (!data) return <Loader center />

  return (
    <>
      { !editing && <LocationDefinition location={data} onEdit={() => setEditing(true)} /> }
      { editing && <LocationEdit location={data} onFinish={() => setEditing(false)} />}
    </>
  )
}

export {
  LocationDetails
}
