import { PaginatedResponse, Query } from '../../../types/api'
import { GetUserVisitsParams, VisitDTO } from '../../../types/visits'
import { createQuery } from '../helpers'

const returnProps = `
  items {
    id
    user
    entry {
      id
      time
    }
    exit {
      id
      time
    }
    location {
      id
      name
    }
    vehicle {
      id
      rego
    }
    duration
    access
    payment {
      id
      amount
      surcharge
    }
  }
  next
  limit
`

export const getUserVisits: Query<
PaginatedResponse<VisitDTO>,
GetUserVisitsParams
> = createQuery(`
  query getUserVisits(
    $userId: ID!,
    $fromDate: String,
    $toDate: String,
    $next: String,
    $limit: Int
  ) {
    getUserVisits(
      user: $userId,
      fromDate: $fromDate,
      toDate: $toDate,
      next: $next,
      limit: $limit
    ) {
      ${returnProps}
    }
  }
`)
