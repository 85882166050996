import { useState } from 'react'

import type { ChangeCallback } from '../../types/form'

/**
 * A hook for managing the values of multiple form fields in a single state property
 * @param {Object|null} defaults - The default values for the form fields
 * @return {Array} - An array containg the current state value and functions to
 *                   update individual field values and the entire state value
 */
const useFormState = <Type>(defaults: Type): [Type, ChangeCallback, Function] => {
  const [formData, setData]: [Type, Function] = useState(defaults)
  // Allow an initial null value to be overridden
  if (!formData && defaults) {
    setData(defaults)
  }

  /**
   * Set the form data. This is required to match the typing of the form change callback
   * @param {Object} value - The new form values
   */
  const setFormData: ChangeCallback = (value) => setData(value)

  /**
   * Reset the form to its initial values
   */
  const resetForm = (newDefaults: Type) => setData(newDefaults || defaults)

  return [formData, setFormData, resetForm]
}

export {
  useFormState
}
