import { getDashboardData } from '../../services/graphql/queries'
import { StatusedApiResponse } from '../../types/api'
import { DashboardDataDTO } from '../../types/dashboard'
import { useApi } from './api'

/**
 *  Hook to retreive customer data for the dashboard
 */
function useAdminDashboardData (): StatusedApiResponse<DashboardDataDTO> {
  const dataApi = useApi(getDashboardData, {
    displayErrorAlerts: false,
    initialFetch: true,
    initialFetchParams: { location: null },
    cleanUpOnDismount: true,
  })
  const dataRes = dataApi.getResponse()
  return dataRes
}

export {
  useAdminDashboardData
}
