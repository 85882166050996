import { createQuery } from '../helpers'
import type {
  CreateGroupParams,
  GroupConfig,
  RemoveGroupParams,
  UpdateGroupParams,
  GetLocationGroupParams,
  Group
} from '../../../types/group'

import type { PaginatedResponse, Query } from '../../../types/api'

const returnProps = `
  id
  location
  name
  description
  users
`

const getLocationGroups: Query<PaginatedResponse<GroupConfig>, GetLocationGroupParams > = createQuery(`
  query getLocationGroups($locationId: ID!, $limit: Int, $next: String) {
    getLocationGroups (location: $locationId, limit: $limit, next: $next) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const getGroupConfig: Query<Group, { groupId: string }> = createQuery(`
  query getGroupConfig($groupId: ID!) {
    getGroupConfig (id: $groupId) { ${returnProps} }
  }
`)

const createGroup: Query<GroupConfig, CreateGroupParams> = createQuery(`
  mutation createGroup(
    $location: ID!,
    $name: String!,
    $description: String,
    $users: [String],
  ) {
    createGroup(
      location: $location,
      name: $name,
      description: $description,
      users: $users,
    ) { ${returnProps} }
  }
`)

const updateGroup: Query<Group, UpdateGroupParams> = createQuery(`
  mutation editGroup(
    $groupId: ID!,
    $location: ID!,
    $name: String!,
    $description: String,
    $users: [String],
  ) {
    editGroup(
      id: $groupId,
      location: $location,
      name: $name,
      description: $description,
      users: $users,
    ) { ${returnProps} }
  }
`)

const removeGroup: Query<void, RemoveGroupParams> = createQuery(`
  mutation removeGroup($groupId: ID!) {
    removeGroup(id: $groupId)
  }
`)

export {
  getLocationGroups,
  createGroup,
  updateGroup,
  getGroupConfig,
  removeGroup
}
