import React from 'react'

import './DashboardVehicleItem.css'

/** Prop types for {@link DashboardVehicleItem} */
type DashboardVehicleItemProps = {
  vehicleRego: string,
  vehicleMake: string,
  vehicleModel: string,
  /** Whether to display the component's loading state */
  loading?: boolean;
}

/**
 * Component to display a vehicle on the dashboard
 */
function DashboardVehicleItem (props:DashboardVehicleItemProps): React.ReactElement {
  const {
    vehicleMake, vehicleRego, vehicleModel, loading,
  } = props
  return (
    <div className="dashboard-vehicle-item">
      <div className="plate">
        {loading ? 'Loading...' : vehicleRego}
      </div>
      <div className="make">
        {loading ? 'Loading...' : `${vehicleMake}, ${vehicleModel}`}
      </div>
    </div>
  )
}

export {
  DashboardVehicleItem,
  type DashboardVehicleItemProps
}
