import React, { ReactElement, useEffect, useMemo } from 'react'

import { Form, InputPicker } from 'rsuite'

import { useParams } from 'react-router-dom'

import type { FormProps } from '../types/form'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import * as options from '../features/locations/LocationOptions'

import { ValidatedForm } from '../components'
import { useApi } from '../app/hooks'
import { getLocation } from '../services/graphql/queries'
import { ScreenType } from '../types/enums'

function LocationTouchscreenForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
  } = props

  const { locationId } = useParams()
  const locationQuery = useApi(getLocation)
  const locationData = locationQuery.getResponse()

  useEffect(() => {
    if (locationId) {
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      locationQuery.cleanup()
    }
  }, [])

  const validator = new Validator({
    name: {
      model: schemas.required('Enter a name for the touchscreen'),
    },
    ip: {
      model: schemas.required('Enter an IP address for the touch screen'),
    },
  })

  const cameraGroups = useMemo(
    () => {
      if (!locationData.data || !locationData.data.cameraGroups) {
        return []
      }
      return locationData.data.cameraGroups.map(({ name, id }) => ({ label: name, value: id }))
    },
    [locationData]
  )

  const showCameraGroup = formValue?.screenType && formValue?.screenType !== ScreenType.KIOSK

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="screenType">
        <Form.ControlLabel>Type</Form.ControlLabel>
        <Form.Control name="screenType" accepter={InputPicker} data={options.screentypes} />
      </Form.Group>
      <Form.Group controlId="ip">
        <Form.ControlLabel>Screen address</Form.ControlLabel>
        <Form.Control name="ip" type="text" />
      </Form.Group>
      { showCameraGroup
        && (
          <Form.Group controlId="gate">
            <Form.ControlLabel>Gate</Form.ControlLabel>
            <Form.Control name="gate" accepter={InputPicker} data={cameraGroups} />
          </Form.Group>
        )}
    </ValidatedForm>
  )
}

export {
  LocationTouchscreenForm
}
