import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, ButtonToolbar } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { UserErrorCode as Errors } from '../types/enums'
import type { FormProps } from '../types/form'

/**
 * Reset password form component
 * @return {ReactEelement}
 */
function ResetPasswordForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    loading,
    error,
  } = props

  const validator = new Validator({
    email: {
      model: schemas.email('Please enter your email address'),
      errors: [Errors.BAD_USERNAME],
    },
  })

  return (
    <ValidatedForm
      fluid
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="email">
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="email" type="email" />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" loading={loading} type="submit">Reset password</Button>
          <Button appearance="subtle" as={Link} to="/login">Cancel</Button>
        </ButtonToolbar>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  ResetPasswordForm
}
