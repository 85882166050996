import React, { ReactElement, useEffect } from 'react'
import { Table } from 'rsuite'

import moment from 'moment-timezone'
import humanizeDuration from 'humanize-duration'

import { PaginationControls, TableData } from '../../components'

import { RowData } from '../../types/table'
import './LocationOccupancyTable.css'
import { LocationVehicles } from '../../types/location'
import { AccessTag } from '../../components/AccessTag'

type Props = {
  vehicles: LocationVehicles[]
  // eslint-disable-next-line no-unused-vars
  loading?: boolean
  nextPage?: () => void
  prevPage?: () => void
}

/**
 * Table listing vehicle regos for a location
 * @param {Props} props
 * @param {LocationVehicles[]} props.vehicles                - The vehicle to list
 * @param {boolean} props.loading                             - Whether table data is loading
 * @param {()=>{}} props.nextPage                             - Callback when next button is clicked
 * @param {()=>{}} props.prevPage                             - Callback when prev button is clicked
 * @return {React.ReactElement}
 */
function LocationOccupancyTable (props:Props):React.ReactElement {
  const {
    nextPage, prevPage, loading, vehicles,
  } = props
  const [tableData, setTableData] = React.useState(vehicles)

  useEffect(() => {
    setTableData(vehicles)
  }, [loading])

  const renderDuration = (entrytime: string) => {
    const entry = moment.parseZone(entrytime)
    const current = moment()
    const ms = moment.duration(current.diff(entry)).asMilliseconds()
    let duration = humanizeDuration(
      ms,
      {
        units: ['d', 'h', 'm', 's'],
        conjunction: ' and ',
        serialComma: false,
        maxDecimalPoints: 0,
      }
    )
    if (ms < 0) {
      duration = '-'
    }
    return duration
  }

  const renderAccessType = (data: RowData): ReactElement => <AccessTag access={data.access} />

  return (
    <div className="location-occupancy-table">
      <Table
        wordWrap="break-word"
        data={tableData}
        loading={loading}
        autoHeight
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Rego</Table.HeaderCell>
          <Table.Cell dataKey="rego" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Access Type</Table.HeaderCell>
          <TableData dataKey="access" content={renderAccessType} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Entry</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <div>
                {
                  rowData.entrytime === '2000-01-01T00:00:00.000+10:00'
                    ? '' : new Date(rowData.entrytime).toLocaleString()
                }
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Duration</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              renderDuration(rowData.entrytime)
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls nextPage={nextPage} prevPage={prevPage} />
      </div>
    </div>
  )
}

export {
  LocationOccupancyTable
}
