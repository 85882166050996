import { Schema } from 'rsuite'

import { mod89, mod10, monthAfter } from './checks'

const required = (message: string) => Schema.Types.StringType()
  .isRequired(message)

const email = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .isEmail('Please enter a valid email address')

const password = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .containsUppercaseLetter('Your password must contain upper case letters')
  .containsLowercaseLetter('Your password must contain lower case letters')
  .containsNumber('Your password must contain numbers')
  .minLength(8, 'Your password must be at least eight characters in length')

const phoneNumber = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .pattern(/[+0]\d+/, 'Please enter a valid phone number')

const confirmCode = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .pattern(/^\d{6}$/, 'The code must have exactly six numbers')

const abn = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .pattern(/^\d{11}$/, 'An ABN must have exactly 11 digits')
  .addRule(mod89, 'This is not a valid ABN')

const cardNumber = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .pattern(/^\d{4} ?\d{4} ?\d{4} ?\d{4}$/, 'A card number must have exactly 16 digits')
  .addRule(mod10, 'This is not a valid card number')

const cardVerification = (message: string) => Schema.Types.StringType()
  .isRequired(message)
  .pattern(/^\d{3}$/, 'The verification code must have exactly three digits')

const expiryDate = (message: string) => Schema.Types.DateType()
  .isRequired(message)
  .addRule((date) => monthAfter(date, new Date()), 'The expiry date must be in the future')

const checkedBox = (message: string) => Schema.Types.NumberType().isOneOf([1], message)
  .isRequired(message)

const positiveInt = (message: string) => Schema.Types.NumberType()
  .isRequired(message)
  .isInteger('Must be a whole number')
  .min(1, message)

const accesslistItems = (valueLabel: string) => Schema.Types.ArrayType().of(
  Schema.Types.ObjectType().shape({
    value: required(`${valueLabel} is required`),
    description: required('A description is required'),
  })
)

export {
  accesslistItems,
  required,
  email,
  password,
  phoneNumber,
  confirmCode,
  abn,
  cardNumber,
  cardVerification,
  positiveInt,
  expiryDate,
  checkedBox
}
