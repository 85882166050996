import React, { ReactElement } from 'react'
import { Form, SelectPicker } from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'
import { usePaginatedApi } from '../app/hooks'
import { getAdminLocations } from '../services/graphql/queries'
import './OrganisationForm.css'
import { LocationDTO } from '../types/location'

/**
 * Organisation form component
 * @param {Props} props
 * @return {ReactElement}
 */
function OrganisationForm (props: FormProps): ReactElement {
  const {
    formValue, onChange, onSubmit, error, formId,
  } = props

  const api = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 20,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  /**
   * Format organisation data for select picker
   */
  const formatData = (res: any) => res?.map(
    (data: LocationDTO) => ({ label: data.name, value: data.id })
  ) ?? []
  const locations = api.currentPage ? formatData(api.currentPage) : []

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the organisation'),
    },
  })

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Form.Control name="description" type="text" />
        <Form.ControlLabel>Car park</Form.ControlLabel>
        <Form.Control
          data={locations}
          name="location"
          accepter={SelectPicker}
        />
        <Form.ControlLabel>Space allocated</Form.ControlLabel>
        <Form.Control name="spaces" type="number" />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  OrganisationForm
}
