import React, { ReactElement } from 'react'
import {
  Form, Button, ButtonToolbar
} from 'rsuite'

import { ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { UserErrorCode as Errors } from '../types/enums'
import type { FormProps } from '../types/form'
import { FormControlCheckbox } from '../components/FormControlCheckbox'

const AGREEMENT_DOCUMENT_LINK = 'https://noover.com/portal-terms'

/**
 * Sign up form component
 * @return {ReactElement}
 */
function SignupForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    loading,
    error,
  } = props

  const validator = new Validator({
    givenName: {
      model: schemas.required('Please provide your given name'),
    },
    familyName: {
      model: schemas.required('Please provide your family name'),
    },
    email: {
      model: schemas.email('Please provide your email address'),
      errors: [Errors.USERNAME_EXISTS],
    },
    phone: {
      model: schemas.phoneNumber('Please provide your phone number'),
    },
    password: {
      model: schemas.password('Please set your account password'),
    },
    agreementCheckbox: {
      model: schemas.checkedBox('You must accept the terms and conditions to create an account'),
    },

  })

  return (
    <ValidatedForm
      fluid
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="givenName">
        <Form.ControlLabel>First name</Form.ControlLabel>
        <Form.Control name="givenName" type="text" />
      </Form.Group>
      <Form.Group controlId="familyName">
        <Form.ControlLabel>Last name</Form.ControlLabel>
        <Form.Control name="familyName" type="text" />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control name="email" type="email" />
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.ControlLabel>Phone</Form.ControlLabel>
        <Form.Control name="phone" type="phone" />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.ControlLabel>Password</Form.ControlLabel>
        <Form.Control name="password" type="password" />
      </Form.Group>
      <Form.Group controlId="agreementCheckbox">
        <FormControlCheckbox
          checkboxProps={{ name: 'agreementCheckbox' }}
          formControlProps={{ name: 'agreementCheckbox' }}
        >
          I accept the following
          {' '}
          <a rel="noopener noreferrer" target="_blank" href={AGREEMENT_DOCUMENT_LINK}>terms and conditions</a>
          .
        </FormControlCheckbox>
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            loading={loading}
            disabled={formValue.agreementCheckbox !== 1}
            type="submit"
          >
            Create account
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  SignupForm
}
