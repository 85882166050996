import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { NotFound } from '../../features/error-pages/NotFound'

import { OutstandingPaymentList } from '../../features/payments/OutstandingPaymentList'
import { PaymentHistoryList } from '../../features/payments/PaymentHistoryList'

/**
 * PaymentsPage route
 * @return {ReactElement}
 */
function PaymentsPage (): ReactElement {
  return (
    <Routes>
      <Route path="/outstanding" element={<OutstandingPaymentList />} />
      <Route path="/history" element={<PaymentHistoryList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export {
  PaymentsPage
}
