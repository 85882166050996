import React, { useEffect } from 'react'
import { Loader, Panel } from 'rsuite'

import { useParams, useNavigate, Navigate } from 'react-router-dom'

import { getGroupConfig } from '../../services/graphql/queries'
import { createGroup, updateGroup } from '../../services/graphql/queries/groups'
import { CreateGroupParams } from '../../types/group'
import { NotFound } from '../error-pages/NotFound'
import { PanelHeader } from '../../components'
import { LocationGroupForm } from '../../forms/LocationGroupForm'
import {
  useApi, useApiGroup, useFormState, usePageTitle, useUser
} from '../../app/hooks'

function LocationGroupDetails () {
  const navigate = useNavigate()
  const user = useUser()
  const { locationId, groupId } = useParams()

  const api = useApiGroup({
    fetch: useApi(getGroupConfig),
    create: useApi(createGroup),
    update: useApi(updateGroup),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = groupId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = groupId ? fetchResponse.data : { name: '' }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (groupId) {
      api.requests.fetch.sendRequest({ groupId })
    }
    return api.cleanup
  }, [])

  const formId = 'group-details-form'
  const pageTitle = groupId ? 'Edit group' : 'New group'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/${user.getRoleForRoute()}/locations/${locationId}`} />
  }

  /**
   * Create or update a gate using the API
   */
  const save = (data: any) => {
    const group = { ...data, location: locationId } as CreateGroupParams
    if (groupId) {
      api.requests.update.sendRequest({ groupId, ...group })
    } else {
      api.requests.create.sendRequest(group)
    }
  }

  /**
   * Return to the location list screen
   */
  const cancel = () => {
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
  }

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {api.requests.fetch.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <LocationGroupForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={groupId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export { LocationGroupDetails }
