import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaBuilding,
  FaUser,
  FaCar,
  FaMagic,
  FaChartPie,
  FaSearch
} from 'react-icons/fa'

/**
 * Menu items for the operator area
 * @return {ReactElement}
 */
function OperatorMenu (): ReactElement {
  const path = useLocation().pathname
  return (
    <>
      <Nav.Item
        to="/operator/locations"
        icon={<Icon as={FaCar} />}
        active={path.startsWith('/operator/locations')}
        as={Link}
      >
        Car parks
      </Nav.Item>
      <Nav.Item
        icon={(
          <Icon as={FaMagic} />
        )}
        to="/operator/subscriptions"
        active={path.startsWith('/operator/subscriptions')}
        as={Link}
      >
        Subscriptions
      </Nav.Item>
      <Nav.Item
        to="/operator/organisations"
        icon={<Icon as={FaBuilding} />}
        active={path.startsWith('/operator/organisations')}
        as={Link}
      >
        Organisations
      </Nav.Item>
      <Nav.Item
        to="/operator/users"
        icon={<Icon as={FaUser} />}
        active={path.startsWith('/operator/users')}
        as={Link}
      >
        Users
      </Nav.Item>
      <Nav.Item
        to="/operator/vehicles"
        icon={<Icon as={FaSearch} />}
        active={path.startsWith('/operator/vehicles')}
        as={Link}
      >
        Vehicle search
      </Nav.Item>
      <Nav.Item
        icon={(
          <Icon as={FaChartPie} />
        )}
        to="/operator/reporting"
        active={path.startsWith('/operator/reporting')}
        as={Link}
      >
        Reporting
      </Nav.Item>
      <hr />
      <Nav.Item
        to="/"
        icon={<Icon as={FaUser} />}
        as={Link}
      >
        Driver account
      </Nav.Item>
    </>
  )
}

export {
  OperatorMenu
}
