import React, { ReactElement } from 'react'
import { Panel } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle
} from 'react-icons/fa'

import './ErrorPage.css'

type Props = {
  type: 'error'|'warning'|'info',
  title: string,
  message: string,
}

const icons = {
  error: FaExclamationCircle,
  warning: FaExclamationTriangle,
  info: FaInfoCircle,
}

/**
 * A full-page error
 * @param {Props} props
 * @return {ReactElement}
 */
function ErrorPage (props: Props): ReactElement {
  const { type, title, message } = props
  const sizeClass = title.length > 8 ? '-small' : ''
  return (
    <Panel className={`error-page -${type} ${sizeClass}`}>
      <div>
        <Icon as={icons[type]} />
        <h1>{title}</h1>
      </div>
      <p>{message}</p>
    </Panel>
  )
}

export {
  ErrorPage
}
