import React from 'react'

import { CtaButton } from './CtaButton'
import './DashboardNewItem.css'

type DashboardNewItemProps = {
  description: string,
  addItemCb: ()=>void,
  ctaIcon: React.ReactElement
  ctaLabel: string,
}

/**
 *  Component which allows users to add an item on the dashboard
 */
function DashboardNewItem (props: DashboardNewItemProps): React.ReactElement {
  const {
    description, ctaLabel, addItemCb, ctaIcon,
  } = props
  return (
    <div className="dashboard-new-item">
      <div className="description">
        {description}
      </div>
      <CtaButton variant="md" inverted label={ctaLabel} icon={ctaIcon} onClick={addItemCb} />
    </div>
  )
}

export {
  type DashboardNewItemProps,
  DashboardNewItem
}
