import React, {
  ReactElement, ReactNode, useState, useEffect
} from 'react'
import { Form } from 'rsuite'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { cloneWithProps } from '../elements'
import type { Validator } from '../forms/validation/validator'
import type { FormError, SubmitCallback, ChangeCallback } from '../types/form'
import { ApiError } from '../types/api'
import { UserError } from '../types/user'

type Props = {
  validator: Validator,
  onSubmit: SubmitCallback,
  onChange: ChangeCallback,
  children: ReactNode,
  noCheckOnChange?: boolean
  error: UserError | ApiError | null,
} & Record<string, any>

/**
 * A form that manages error messages
 *
 * External errors (eg from a failed API call) will be merged into client-side validation
 * errors generated by the form `model` schema. The external errors are displayed until the
 * field they apply to is modified, after which they are suppressed until the form is next
 * submitted.
 * External errors that aren't used on the form will be displayed in an Alert.
 *
 * @param {Props} props
 * @param {Validator} props.validator - A validator object to control the validation
 * @param {ChangeCallback} props.onChange - A callback to handle the form change event
 * @param {SubmitCallback} props.onSubmit - A callback to handle the form submit event
 * @param {ReactNode} props.children - Any form child elements
 * @return {ReactElement}
 */

function ValidatedForm (props: Props): ReactElement {
  const {
    validator, onChange, noCheckOnChange, onSubmit, children, error: externalError, ...others
  } = props

  const [error, setError] = useState({})
  const [changed, setChanged] = useState(new Set() as Set<string>)
  const formError = validator.parseError(externalError)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const alert = (message: String) => {
    toast.error('Error', {
      description: message,
    })
  }

  useEffect(() => {
    validator.emitUnusedErrors(alert)
  }, [externalError])

  /**
   * Update the error state on field change
   * @param {string} value
   * @param {FormEvent} event
   */
  const change: ChangeCallback = (value, event) => {
    if (!event) {
      return
    }
    const element = event.currentTarget as HTMLInputElement
    setChanged(changed.add(element.name))
    onChange(value, event)
  }

  /**
   * Submit the form if valid
   * @param {boolean} checkStatus
   * @param {FormEvent} event
   */
  const submit: SubmitCallback = (checkStatus, event) => {
    if (checkStatus) {
      setChanged(new Set())
      onSubmit(checkStatus, event)
    }
  }

  // Combine validation errors with external errors for unchanged fields
  const allErrors: FormError = { ...error }
  Object.entries(formError).forEach(([key, value]) => {
    if (!changed.has(key)) allErrors[key] = value
  })

  const childrenWithErr = React.Children.map(
    children,
    (child) => cloneWithProps(child, { formErrors: allErrors })
  )

  return (
    <Form
      model={validator.model}
      onCheck={setError}
      onSubmit={submit}
      onChange={change}
      checkTrigger={noCheckOnChange ? 'none' : 'change'}
      formError={allErrors}
      autoComplete="off"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      {childrenWithErr}
    </Form>
  )
}

export {
  ValidatedForm
}
