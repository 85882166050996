import React from 'react'
import { Button, ButtonToolbar, Modal } from 'rsuite'

type Props = {
  onConfirm: ()=>void,
  onClose: ()=>void,
  loading: boolean
}

export function RevokeVoucherModal (props:Props):React.ReactElement {
  const { onConfirm, onClose, loading } = props
  return (
    <>
      <Modal.Header>
        <Modal.Title>Are you sure you want to revoke this voucher?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The voucher recipient will not be notified.</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={onConfirm} appearance="primary" loading={loading || false}>
            Revoke
          </Button>
          <Button onClick={onClose} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}
