import * as graphqlService from '../graphql/client'
import { getPaymentTokenisationUrl } from '../graphql/queries'
import type {
  CardDetails,
  CardTokenisationResult, WindcaveCardTokenisationResult
} from '../../types/payment-method'

/**
 * Tokenise a user's card details using the Windcave payment gateway
 * @param {string} userId
 * @param {CardDetails} card
 * @return A promise that resolves to a session ID, which is sent
 *                             back to the API for retrieval and storage of the card token
 */
const tokeniseCardDetails = async (userId: string, card: CardDetails): Promise<CardTokenisationResult> => {
  const response = await getPaymentTokenisationUrl({ userId })(graphqlService.run)

  if (!response) {
    throw new Error('Couldn\'t fetch the tokenisation URL')
  }

  const req = {
    method: 'post',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({
      card: {
        cardHolderName: card.cardHolderName,
        cardNumber: card.cardNumber,
        dateExpiryMonth: (card.expiry.getMonth() + 1).toString().padStart(2, '0'),
        dateExpiryYear: card.expiry.getFullYear().toString().slice(-2),
        cvc2: card.verificationCode,
      },
    }),
  }
  const data: WindcaveCardTokenisationResult = await fetch(response.url, req).then((res) => res.json())
  if (data.links?.find((e) => e.rel === 'done')) {
    return {
      type: 'CardTokenisationResultWithout3DS',
      sessionId: data.id,
    }
  }
  const threeDSLink = data.links?.find((e) => e.rel === '3DSecure')
  if (!threeDSLink) {
    // This needs to be handled when there is no 3ds redirect but no indication that 3ds is not required
    return {
      type: 'CardTokenisationResultWithout3DS',
      sessionId: data.id,
    }
  }
  return {
    type: 'CardTokenisationResultWith3DS',
    sessionId: data.id,
    threeDSRedirect: threeDSLink.href,
    verificationId: response.verificationId,
  }
}

export {
  tokeniseCardDetails
}
