/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  DatePicker, SelectPicker, Table
} from 'rsuite'

import dayjs from 'dayjs'

import { getAdminLocations, getAveragePaymentPerSpaceReport } from '../../services/graphql/queries'
import { useApi, usePaginatedApi } from '../../app/hooks'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'

const { Column, HeaderCell, Cell } = Table

const defaultColumns = [
  {
    key: 'month',
    label: 'Month',
  },
  {
    key: 'carpark',
    label: 'Car park',
  },
  {
    key: 'bays',
    label: 'Total bays',
  },
  {
    key: 'payments',
    label: 'Total payments',
  },
  {
    key: 'average',
    label: 'Average revenue per bay',
  },
]

function AveragePaymentPerSpaceReport () {
  const [sortColumn, setSortColumn] = React.useState('rego')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [carpark, setCarpark] = React.useState<String | null>(null)
  const [startdate, setStartdate] = React.useState<Date | null>(dayjs().subtract(1, 'month').toDate())
  const [enddate, setEnddate] = React.useState<Date | null>(new Date())

  const locationsApi = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 1000,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const dataQuery = useApi(getAveragePaymentPerSpaceReport)
  const res = dataQuery.getResponse()

  React.useEffect(() => {
    dataQuery.sendRequest({
      location: carpark,
      start: startdate ? startdate.toISOString() : null,
      end: enddate ? enddate.toISOString() : null,
    })
    return () => {
      dataQuery.cleanup()
    }
  }, [carpark, startdate, enddate])

  const data = res.data ? res.data.map((v: any) => ({
    ...v,
  })) : []

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return data.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return data
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }
  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `AveragePaymentPerSpaceReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div>
      <div style={{
        display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
      }}
      >
        <div style={{ width: 200 }}>
          Car park
          <SelectPicker
            onChange={setCarpark}
            value={carpark}
            data={(locationsApi.currentPage || []).map((l) => ({ label: l.name, value: l.id }))}
            loading={locationsApi.loading}
          />
        </div>
        <div style={{ width: 200 }}>
          Start month
          <DatePicker
            onChange={(date: Date | null) => setStartdate(date)}
            value={startdate}
            format="yyyy-MM"
          />
        </div>
        <div style={{ width: 200 }}>
          End month
          <DatePicker onChange={setEnddate} value={enddate} format="yyyy-MM" />
        </div>
        <DownloadTableToCsv
          style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
          url={URL.createObjectURL(dataExport)}
          fileName={dataExport.name}
          text="Download to file"
        />
      </div>

      {(carpark && startdate && enddate) ? (
        <Table
          loading={res.loading || loading}
          hover={false}
          showHeader
          autoHeight
          data={getData()}
          sortColumn={sortColumn}
          sortType={sortType}
          defaultSortType="asc"
          onSortColumn={handleSortColumn}
          bordered
          headerHeight={40}
          rowHeight={46}
        >
          {defaultColumns.map((column) => {
            const { key, label, ...rest } = column
            return (
              <Column {...rest} key={key} flexGrow={1} fullText sortable>
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={key} />
              </Column>
            )
          })}
        </Table>
      ) : <p style={{ margin: 20 }}>No car park or dates selected</p>}
    </div>
  )
}

export {
  AveragePaymentPerSpaceReport
}
