import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { AdminEditSubscription } from '../../features/subscriptions/AdminEditSubscription'
import { AdminReviewSubscription } from '../../features/subscriptions/AdminReviewSubscription'
import { AdminPendingSubscriptionList } from '../../features/subscriptions/AdminPendingSubscriptionsList'
import { AdminOtherSubscriptionList } from '../../features/subscriptions/AdminOtherSubscriptionsList'
import { UserRole } from '../../types/enums'
import { AdminModifiedSubscriptionList } from '../../features/subscriptions/AdminModifiedSubscriptionsList'
import { AdminModifiedReviewSubscription } from '../../features/subscriptions/AdminModifiedReviewSubscription'

/**
 * SubscriptionsPage route
 * @return {ReactElement}
 */
function SubscriptionsPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN, UserRole.ORGANISATION, UserRole.OPERATOR]}>
      <Routes>
        <Route path="/pending" element={<AdminPendingSubscriptionList />} />
        <Route path="/modified" element={<AdminModifiedSubscriptionList />} />
        <Route path="/modified/:id" element={<AdminModifiedReviewSubscription />} />
        <Route path="/other" element={<AdminOtherSubscriptionList />} />
        <Route path="/review/:id" element={<AdminReviewSubscription />} />
        <Route path="/edit/:id" element={<AdminEditSubscription />} />
      </Routes>
    </RequireRole>
  )
}

export {
  SubscriptionsPage
}
