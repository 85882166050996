import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { userActions } from './slice'
import { apiActions } from '../api-data/slice'
import * as userService from '../../services/user/client'
import { LoginStatus } from '../../types/enums'
import { createError } from './errors'
import type { UserDetails, Credentials } from '../../types/user'
import * as graphqlService from '../../services/graphql/client'
import { updateUserLastLogin } from '../../services/graphql/queries'

const login = createAsyncThunk(
  'user/login',
  async (payload: Credentials, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      const user = await userService.authenticateUser(payload)
      dispatch(userActions.setUser(user))
      /* eslint-disable no-console */
      updateUserLastLogin({ id: user.id })(graphqlService.run).catch((r) => console.log(r))
    } catch (error: any) {
      if (error.code === userService.errors.USER_NOT_CONFIRMED) {
        // The username can be used for account verification in lieu of an ID
        const user = {
          id: payload.username,
          login: LoginStatus.UNCONFIRMED,
          roles: [],
          emailVerified: false,
        }
        dispatch(userActions.setUser(user))
        toast.info('Confirm account', {
          description: 'You must confirm your account before logging in',
        })
      } else {
        dispatch(userActions.setFailed(createError(error)))
      }
    }
  }
)

const logout = createAsyncThunk(
  'user/logout',
  async (payload: undefined, { dispatch }) => {
    await userService.clearUser()
    dispatch(userActions.clearUser())
    dispatch(apiActions.clearAll())
  }
)

const signup = createAsyncThunk(
  'user/signup',
  async (payload: { details: UserDetails, credentials: Credentials }, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      const user = await userService.createUser(payload.details, payload.credentials)
      dispatch(userActions.setUser(user))
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const confirm = createAsyncThunk(
  'user/confirm',
  async (payload: { username: string, code: string }, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.confirmUser(payload.username, payload.code)
      dispatch(userActions.updateUser({ login: LoginStatus.READY }))
      toast.success('Account confirmed', {
        description: 'Your account has been confirmed. You can now log in',
      })
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const resendCode = createAsyncThunk(
  'user/resend-confirmation-code',
  async (payload: { username: string }, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.resendConfirmationCode(payload.username)
      dispatch(userActions.setDone())
      toast.info('Confirmation code', {
        description: 'You should get a new confirmation code soon',
      })
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const hydrate = createAsyncThunk(
  'user/hydrate',
  async (payload: undefined, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      const user = await userService.getCurrentUser()
      dispatch(userActions.setUser(user))
    } catch (error) {
      dispatch(userActions.logout())
    }
  }
)

const resetPassword = createAsyncThunk(
  'user/reset-password',
  async (payload: { username: string }, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.resetPassword(payload.username)
      dispatch(userActions.setUser({
        id: payload.username,
        login: LoginStatus.PASSWORD_RESET,
        roles: [],
        emailVerified: false,
      }))
      toast.info('Confirmation code', {
        description: 'You should get a new confirmation code soon',
      })
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const setPassword = createAsyncThunk(
  'user/set-password',
  async (payload: Credentials & { code: string }, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.setPassword(payload.username, payload.password, payload.code)
      dispatch(userActions.updateUser({ login: LoginStatus.READY }))
      toast.success('Password updated', {
        description: 'Your password has been changed. You can now log in',
      })
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const update = createAsyncThunk(
  'user/update',
  async (payload: UserDetails, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.updateUser(payload)
      const user = await userService.getCurrentUser()
      dispatch(userActions.updateUser(user))
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

const verifyEmail = createAsyncThunk(
  'user/verifyEmail',
  async (payload: string, { dispatch }) => {
    dispatch(userActions.setLoading())
    try {
      await userService.submitEmailVerificationCode(payload)
      const user = await userService.getCurrentUser()
      dispatch(userActions.updateUser(user))
    } catch (error) {
      dispatch(userActions.setFailed(createError(error)))
    }
  }
)

export {
  login,
  logout,
  signup,
  confirm,
  verifyEmail,
  resendCode,
  hydrate,
  resetPassword,
  setPassword,
  update
}
