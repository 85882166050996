import amex from './amex'
import dinersclub from './dinersclub'
import discover from './discover'
import hipercard from './hipercard'
import jcb from './jcb'
import unionpay from './unionpay'
import mastercard from './mastercard'
import placeholder from './placeholder'
import visa from './visa'

export default {
  amex,
  dinersclub,
  discover,
  hipercard,
  jcb,
  unionpay,
  mastercard,
  placeholder,
  visa,
}
