import React, { ReactElement, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import {
  useApi, usePageTitle, useUser
} from '../../app/hooks'
import { CenteredLoader } from '../../components'
import { confirmCardVerification } from '../../services/graphql/queries'
import { CardVerificationResult } from '../../types/enums'

/**
 * Component which users will land on upon finising their 3DS verification
 * @return {ReactElement}
 */
export function ThreeDSResult (): ReactElement {
  usePageTitle('Threeds verification')
  const [urlParams] = useSearchParams()
  const user = useUser()
  const navigate = useNavigate()

  // Whether the 3DS verification was successful
  const success = urlParams.get('success')

  // The associated verification id in the add payment method flow
  const id = urlParams.get('id')

  const confirmVerification = useApi(confirmCardVerification, {
    displayErrorAlerts: false,
  })
  const confirmResponse = confirmVerification.getResponse()

  useEffect(() => {
    if (!success || !id) {
      toast.error('Payment Method Error', {
        description: 'Error saving payment details. Please try again.',
      })
      navigate('/account')
    } else {
      confirmVerification.sendRequest({
        userId: user.id || '',
        verificationId: id || '',
        verificationResult: success === 'true'
          ? CardVerificationResult.SUCCESS : CardVerificationResult.FAILED,
      })
    }
  }, [])

  useEffect(() => {
    if (confirmResponse.data) {
      if (success === 'true') {
        toast.success('Payment Method Added', {
          description: 'Your payment method has been added',
        })
      } else {
        toast.error('Payment Method Error', {
          description: '3D Secure verification failed. '
            + 'Please contact your bank to resolve verification issue.',
        })
      }
      navigate('/account')
    }
    if (confirmResponse.error) {
      toast.error('Payment Method Error', {
        description: 'Error saving payment details. Please try again.',
      })
      navigate('/account')
    }
  }, [confirmResponse])

  return <CenteredLoader />
}
