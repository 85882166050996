import React, { ReactElement } from 'react'
import {
  Modal, Button, ButtonToolbar, SelectPicker, Notification, useToaster
} from 'rsuite'

import { useApi, usePaginatedApi } from '../app/hooks'
import {
  addAttendantLocationId, getAdminLocations
} from '../services/graphql/queries'
import { LocationDTO } from '../types/location'

export type Props = {
  user: string | null,
  header?: string,
  onConfirm?: () => void,
  onClose: () => void,
}

/**
 * Contirmation modal component
 * @return {ReactElement}
 */
function AttendantAssignModal (props: Props): ReactElement {
  const [location, setLocation] = React.useState(null)
  const toaster = useToaster()

  const {
    user,
    header = 'Assign user as a carpark attendant',
    onConfirm = () => {},
    onClose = () => {},
  } = props

  const api = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 20,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })
  const addAttendantLocationQuery = useApi(addAttendantLocationId)

  /**
   * Close the modal
   */
  const close = () => {
    onClose()
  }

  const message = (
    <Notification type="error" header="Error" closable>
      Error: Could not assign carpark attendant. Please try again or contact support.
    </Notification>
  )

  /**
   * Run the confirm action and close the modal
   */
  const confirm = () => {
    if (!user) {
      toaster.push(message, { placement: 'bottomStart' })
    } else {
      addAttendantLocationQuery.sendRequest({
        id: user,
        attendantLocationId: location || '',
      })
    }
    onConfirm()
    onClose()
  }

  /**
   * Format organisation data for select picker
   */
  const formatData = (res: any) => res?.map(
    (data: LocationDTO) => ({ label: data.name, value: data.id })
  ) ?? []

  const locations = api.currentPage ? formatData(api.currentPage) : []

  return (
    <>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectPicker
          loading={api.loading}
          value={location}
          onChange={setLocation}
          data={locations}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={confirm} appearance="primary">Confirm</Button>
          <Button onClick={close} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  AttendantAssignModal
}
