import React, { useEffect } from 'react'
import { Panel } from 'rsuite'

import { useNavigate } from 'react-router-dom'

import {
  useApi, useFormState, usePageTitle, useUser
} from '../../app/hooks'

import { PanelHeader } from '../../components'
import { LocationForm } from '../../forms/LocationForm'
import { LocationStatus, LocationType } from '../../types/enums'
import { CreateLocationParams, LocationDetailsFormData } from '../../types/location'
import { createLocation } from '../../services/graphql/queries'

/**
 * Component to create location
 * @returns {React.ReactElement}
 */
function LocationCreate () : React.ReactElement {
  const navigate = useNavigate()
  const user = useUser()
  const onFinish = () => { navigate(`/${user.getRoleForRoute()}/locations`) }
  const createLocationApi = useApi(createLocation)

  const { error, loading, data } = createLocationApi.getResponse()

  usePageTitle('New car park')

  useEffect(() => {
    if (data !== null) {
      onFinish()
    }
  }, [data])

  const [formData, setField] = useFormState<LocationDetailsFormData>({
    name: '',
    description: '',
    // TODO: Replace this with the current organisation ID from app settings. See TP-95
    organisation: process.env.REACT_APP_ORGANISATION_ID ?? 'UNKNOWN ORGANISATION',
    address: '',
    timezone: '',
    type: LocationType.CARPARK,
    status: LocationStatus.DISABLED,
    features: [],
    controllers: [],
    lat: 0,
    lon: 0,
    totalCapacity: 0,
    availableCapacity: 0,
  })
  const onCreateLocation = () => {
    const requestParams: CreateLocationParams = {
      name: formData.name,
      description: formData.description,
      controllers: formData.controllers,
      organisation: formData.organisation,
      status: formData.status,
      type: formData.type,
      timezone: formData.timezone,
      address: formData.address,
      geolocation: {
        lat: formData.lat,
        lon: formData.lon,
      },
      totalCapacity: formData.totalCapacity,
    }
    createLocationApi.sendRequest(requestParams)
  }
  return (
    <Panel header={(
      <PanelHeader
        header="Create car park"
        editing
        formId="location-create-form"
        onCancel={onFinish}
        loading={loading}
      />
    )}
    >
      <LocationForm
        formId="location-create-form"
        formValue={formData}
        onChange={setField}
        error={error}
        onSubmit={onCreateLocation}
        mode="new"
      />
    </Panel>
  )
}

export { LocationCreate }
