import React, { ReactElement, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Panel, Loader } from 'rsuite'

import {
  usePageTitle, useUser, useFormState, useApi, useApiGroup
} from '../../app/hooks'
import { getVehicle, createVehicle, updateVehicle } from '../../services/graphql/queries'
import { PanelHeader } from '../../components'
import { VehicleForm } from '../../forms/VehicleForm'
import { VehicleDetails as VehicleDetailsType } from '../../types/vehicle'
import { NotFound } from '../error-pages/NotFound'

/**
 * Create/edit vehicle component
 * @return {ReactElement}
 */
function VehicleDetails (): ReactElement {
  const navigate = useNavigate()
  const { vehicleId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getVehicle),
    create: useApi(createVehicle),
    update: useApi(updateVehicle),
  })
  const user = useUser()

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = vehicleId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = vehicleId ? fetchResponse.data : {
    make: '',
    model: '',
    state: '',
    rego: '',
    colour: '',
  }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (vehicleId) {
      api.requests.fetch.sendRequest({ vehicleId })
    }
    return api.cleanup
  }, [])

  const pageTitle = vehicleId ? 'Edit vehicle' : 'New vehicle'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to="/vehicles" />
  }

  /**
   * Create or update a vehicle using the API
   */
  const save = () => {
    const vehicle = formData as VehicleDetailsType
    if (vehicleId) {
      api.requests.update.sendRequest({
        vehicleId,
        rego: vehicle.rego,
        state: vehicle.state,
        colour: vehicle.colour,
      })
    } else {
      api.requests.create.sendRequest({ userId: user.id, ...vehicle })
    }
  }

  /**
   * Return to the vehicles screen
   */
  const cancel = () => {
    navigate('/vehicles')
  }

  const formId = 'vehicle-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {api.requests.fetch.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <VehicleForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={vehicleId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  VehicleDetails
}
