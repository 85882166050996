import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { CustomerSearch } from '../../features/users/CustomerSearch'

/**
 * UserSearchPage route
 * @return {ReactElement}
 */
function UserSearchPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ATTENDANT, UserRole.OPERATOR, UserRole.ADMIN]}>
      <Routes>
        <Route path="/" element={<CustomerSearch />} />
      </Routes>
    </RequireRole>
  )
}

export {
  UserSearchPage
}
