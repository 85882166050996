/* eslint-disable max-len */
import React, { ReactElement } from 'react'

import './NooverLogo.css'

type Props = {
  inverted?: boolean,
}

/**
 * The Noover logo in SVG format
 * @return {ReactElement}
 */
function NooverLogo (props: Props): ReactElement {
  const { inverted = false } = props
  return (
    <div className={`noover-logo ${inverted ? '-inverted' : ''}`}>
      <svg role="img" width="157" height="51" version="1.1" viewBox="0 0 157 51" xmlns="http://www.w3.org/2000/svg">
        <path className="background" d="m41.16 0h-31.36c-5.4124 0-9.8 4.3876-9.8 9.8v31.36c0 5.4124 4.3876 9.8 9.8 9.8h31.36c5.4124 0 9.8-4.3876 9.8-9.8v-31.36c0-5.4124-4.3876-9.8-9.8-9.8z" />
        <path className="foreground" d="m33.81 12.055v19.245c0 1.5856-1.2309 2.9311-2.8145 2.9978-0.3879 0.0166-0.7752-0.0437-1.1396-0.1775s-0.6987-0.3384-0.9836-0.6021-0.5148-0.5812-0.6763-0.9341c-0.1616-0.353-0.2516-0.7345-0.265-1.1224l0.9682-11.617c0.0069-0.0813 0.0108-0.1626 0.0108-0.244-0.0012-1.1653-0.2331-2.3187-0.6823-3.394-0.4491-1.0752-1.1066-2.0509-1.9347-2.8708-0.828-0.8199-1.8101-1.4678-2.8897-1.9063-1.0796-0.4386-2.2353-0.6591-3.4005-0.6489-4.8559 0.0471-8.7328 4.0758-8.7328 8.9317v19.194c0 0.078 0.031 0.1528 0.0861 0.2079 0.0552 0.0551 0.1299 0.0861 0.2079 0.0861h5.292c0.078 0 0.1528-0.031 0.2079-0.0861s0.0861-0.1299 0.0861-0.2079v-19.246c0-1.5847 1.2299-2.9312 2.8136-2.9978 0.3878-0.0167 0.7751 0.0436 1.1395 0.1774s0.6987 0.3385 0.9837 0.6021c0.2849 0.2637 0.5147 0.5812 0.6763 0.9342 0.1615 0.353 0.2516 0.7344 0.265 1.1224l-0.9683 11.618c-0.0068 0.0804-0.0098 0.1617-0.0098 0.244 0.0011 1.1652 0.2329 2.3187 0.6819 3.3939 0.4491 1.0752 1.1065 2.0509 1.9345 2.8709 0.8279 0.8199 1.8099 1.4678 2.8895 1.9063 1.0795 0.4386 2.2352 0.6591 3.4004 0.6489 4.8559-0.047 8.7337-4.0758 8.7337-8.9317v-19.194c0-0.078-0.031-0.1527-0.0861-0.2079-0.0551-0.0551-0.1299-0.0861-0.2079-0.0861h-5.292c-0.078 0-0.1527 0.031-0.2079 0.0861-0.0551 0.0552-0.0861 0.1299-0.0861 0.2079z" />
        <g className="text">
          <path d="m61.396 16.682h4.214v1.9395c0.9192-1.568 2.9958-2.4167 4.9-2.4167 3.9817 0 6.5346 2.6215 6.5346 7.1824v10.892h-4.4208l0.0343-9.8372c0-2.45-1.2593-3.9475-3.3016-3.9475-2.2804 0-3.5397 1.8375-3.5397 4.3561v9.4286h-4.4208v-17.597z" />
          <path d="m78.281 25.463c0-5.0715 3.332-9.258 8.5436-9.258s8.5084 3.8808 8.5084 9.258c0 5.1058-3.3007 9.2924-8.5084 9.2924-5.2401 0-8.5436-3.8749-8.5436-9.2924zm12.627 0c0-3.1987-1.5994-5.1391-4.0837-5.1391s-4.116 1.9404-4.116 5.1391c0 3.2674 1.6003 5.2078 4.116 5.2078 2.4843 0 4.0837-1.9404 4.0837-5.2078z" />
          <path d="m96.403 25.463c0-5.0715 3.332-9.258 8.5422-9.258 5.211 0 8.51 3.8808 8.51 9.258 0 5.1058-3.302 9.2924-8.51 9.2924-5.2405 0-8.5422-3.8749-8.5422-9.2924zm12.627 0c0-3.1987-1.599-5.1391-4.085-5.1391-2.485 0-4.116 1.9404-4.116 5.1391 0 3.2674 1.6 5.2078 4.116 5.2078 2.486 0 4.085-1.9404 4.085-5.2078z" />
          <path d="m112.58 16.682h4.697l2.382 7.4539 1.532 5.6154h0.068l1.498-5.6154 2.076-7.4539h4.526l-5.82 17.597h-4.9l-6.059-17.597z" />
          <path d="m136.87 30.841c1.803 0 2.757-0.6468 3.234-2.0423h4.288c-0.51 3.5397-3.607 5.9564-7.454 5.9564-5.037 0-8.475-3.0978-8.475-9.2238 0-5.7183 3.472-9.3266 8.441-9.3266 4.868 0 8.036 3.4045 8.036 9.0542 0 0.6125-0.034 1.1231-0.068 1.6337h-11.951c0.239 2.6548 1.634 3.9484 3.949 3.9484zm-3.812-7.4539h7.423c-0.374-2.1109-1.599-3.1997-3.642-3.1997-1.808 0-3.305 0.8506-3.781 3.1997z" />
          <path d="m146.25 16.682h4.226v2.0756c1.021-1.8718 2.961-2.3138 4.594-2.3138h1.635l-0.034 4.2199h-1.906c-2.689 0-4.085 1.8385-4.085 4.5266v9.0886h-4.425l-5e-3 -17.597z" />
        </g>
      </svg>
    </div>
  )
}

export {
  NooverLogo
}
