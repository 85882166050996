import { PaginatedResponse, Query } from '../../../types/api'
import { GetUserVehicleVisitsParams, VehicleVisitDTO } from '../../../types/vehicle-visits'
import { createQuery } from '../helpers'

const returnProps = `
  items {
    id
    user
    entrytime
    exittime
    location
    rego
    vehicle
    access
    status
    payment {
      id
      amount
      surcharge
    }
  }
  next
  limit
`

export const getUserVehicleVisits: Query<
PaginatedResponse<VehicleVisitDTO>,
GetUserVehicleVisitsParams
> = createQuery(`
  query getUserVehicleVisits(
    $userId: ID!,
    $fromDate: String,
    $toDate: String,
    $location: String,
    $vehicle: String,
    $next: String,
    $limit: Int
  ) {
    getUserVehicleVisits(
      user: $userId,
      fromDate: $fromDate,
      toDate: $toDate,
      location: $location,
      vehicle: $vehicle,
      next: $next,
      limit: $limit
    ) {
      ${returnProps}
    }
  }
`)
