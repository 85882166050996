import React, { ReactElement } from 'react'
import { IconButton } from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FiX, FiMenu } from 'react-icons/fi'

import type { InteractionCallback } from '../../types/form'

import './SideMenuToggle.css'

type Props = {
  mode: 'open' | 'close',
  onClick: InteractionCallback,
}

/**
 * Sidebar menu open/close button
 * @param {Props} props
 * @return {ReactElement}
 */
function SideMenuToggle (props: Props): ReactElement {
  const { mode, onClick } = props
  const icon = mode === 'open' ? FiMenu : FiX

  return (
    <div className={`sidebar-toggle -${mode}`}>
      <IconButton
        className="sidebar-hide"
        appearance="subtle"
        circle
        icon={<Icon as={icon} />}
        onClick={onClick}
      />
    </div>
  )
}

export {
  SideMenuToggle
}
