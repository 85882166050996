import dayjs, { Dayjs } from 'dayjs'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button, ButtonGroup, Panel, SelectPicker, Table
} from 'rsuite'

import {
  useApi,
  usePageTitle, usePaginatedApi, useUser
} from '../../app/hooks'
import { PaginationControls, TableData } from '../../components'
import { getUserVehicleVisitsTableRow, visitDtoToVehicleVisit } from '../../types/visitHelpers'

import './CustomerVisitsList.css'
import { getUserVehicleVisits, getUserVehicles } from '../../services/graphql/queries'
import { RowData } from '../../types/table'
import { DownloadTableToCsv, tableToCSV } from '../../components/DownloadTableToCsv'
import { useCustomerLocations } from '../../app/hooks/customerLocation'

const defaultColumns = [
  { key: 'location', label: 'Car park' },
  { key: 'rego', label: 'Vehicle' },
  { key: 'entry', label: 'Entry' },
  { key: 'exit', label: 'Exit' },
  { key: 'duration', label: 'Duration' },
  { key: 'tag', label: 'Access type' },
  { key: 'fee', label: 'Fee' },
  { key: 'totalPrice', label: 'Total price' },
]

/**
 * Customer view of their visits in a list
 * @return {React.ReactElement}
 */
export function CustomerVisitsList () {
  const navigate = useNavigate()
  const { month } = useParams()

  const [sortColumn, setSortColumn] = React.useState('entry')
  const [sortType, setSortType] = React.useState()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [carpark, setCarpark] = React.useState<String | null>(null)
  const [vehicle, setVehicle] = React.useState<String | null>(null)

  let date = dayjs().startOf('month')

  const dateMatch = (useParams().month || '').match(/(?<year>\d{4})-(?<month>\d{2})/)
  if (dateMatch) {
    const yearPart = Number(dateMatch.groups?.year || null)
    const monthPart = Number(dateMatch.groups?.month || null)
    date = dayjs(new Date(yearPart, monthPart - 1, 1))
  }

  const { locations } = useCustomerLocations({
    displayErrorAlerts: false,
  })
  const locationList: { label: string, value: string }[] = []
  locations.data.forEach((l) => {
    locationList.push({ label: l.name, value: l.id })
  })

  /**
   * Gets the date related parameters required for the query from a Dayjs object
   * @param {Dayjs} monthStart - The dayjs object for the start of the desired month
   */
  const getQueryDateParams = (monthStart: Dayjs): { toDate: string, fromDate: string } => ({
    toDate: monthStart.endOf('month').format(),
    fromDate: monthStart.format(),
  })

  const user = useUser()
  const vehicleQuery = useApi(getUserVehicles)
  const visits = usePaginatedApi({
    query: getUserVehicleVisits,
    fetchParams: {
      userId: user.id,
      location: carpark ?? undefined,
      vehicle: vehicle ?? undefined,
      ...getQueryDateParams(date),
    },
  })
  const vehicleData = vehicleQuery.getResponse()

  useEffect(() => {
    visits.sendRequest({
      userId: user.id,
      location: carpark ?? undefined,
      vehicle: vehicle ?? undefined,
      ...getQueryDateParams(date),
    })
  }, [carpark, vehicle, month])
  useEffect(() => {
    vehicleQuery.sendRequest({ userId: user.id })
    return () => {
      vehicleQuery.cleanup()
    }
  }, [])

  const vehicleOptions = useMemo(
    () => {
      if (vehicleData.data && vehicleData.data.items) {
        return vehicleData.data.items.map((v: any) => ({ label: v.rego, value: v.rego }))
      }
      return []
    },
    [vehicleData?.data?.items]
  )

  const dateFormat = 'MMM YYYY'
  const monthString = date.format(dateFormat)

  const route = '/visits/'
  const routeDateFormat = 'YYYY-MM'

  /** Cb when user clicks the prev month btn */
  const prevMonth = () => {
    navigate(`${route}${date.subtract(1, 'month').format(routeDateFormat)}`)
  }

  /** Cb when user clicks the next month btn */
  const nextMonth = () => {
    navigate(`${route}${date.add(1, 'month').format(routeDateFormat)}`)
  }

  const tableData = useMemo(() => visits.currentPage?.map(visitDtoToVehicleVisit)
    .map(getUserVehicleVisitsTableRow) || [], [visits.currentPage])

  const isCurrentMonth = date.get('month') === dayjs().get('month')

  const renderParkingHistoryEntry = (parkingHistory: RowData): ReactNode => (
    parkingHistory.status === 'MISSING_ENTRY' ? '' : parkingHistory.entry
  )

  const renderParkingHistoryExit = (parkingHistory: RowData): ReactNode => (
    parkingHistory.status === 'MISSING_EXIT' ? '' : parkingHistory.exit
  )

  const renderParkingHistoryDuration = (parkingHistory: RowData): ReactNode => (
    (parkingHistory.status === 'MISSING_EXIT'
      || parkingHistory.status === 'MISSING_ENTRY') ? '' : parkingHistory.duration
  )

  const getData = () => {
    if (sortColumn) {
      const sortT = sortType || 'asc'
      return tableData.sort((a: any, b: any) => {
        const x = a[sortColumn]
        const y = b[sortColumn]

        if ((typeof x === 'string') && (typeof y === 'string')) {
          return (sortT === 'asc') ? x.localeCompare(y) : -1 * x.localeCompare(y)
        }

        if (sortT === 'asc') {
          return x - y
        }
        return y - x
      })
    }
    return tableData
  }

  const handleSortColumn = (sortCol: any, sortT: any) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortCol)
      setSortType(sortT)
    }, 500)
  }

  usePageTitle('Parking history')

  const ts = dayjs(new Date()).format('YYYYMMDD-HHmm')
  const dataExport = new File(
    tableToCSV(defaultColumns, getData()),
    `ParkingHistoryReport_${ts}.csv`,
    { type: 'text/plain' }
  )
  return (
    <div className="customer-visits-list">
      <Panel header={(
        <h2>
          Parking history
          {' - '}
          {monthString}
        </h2>
      )}
      >
        {/* Add filters */}
        <div style={{
          display: 'flex', gap: 12, paddingBottom: 12, position: 'relative',
        }}
        >
          <div style={{ width: 200 }}>
            Car park
            <SelectPicker
              onChange={setCarpark}
              value={carpark}
              data={locationList}
            />
          </div>
          <div style={{ width: 200 }}>
            Vehicle
            <SelectPicker
              onChange={setVehicle}
              value={vehicle}
              data={vehicleOptions}
            />
          </div>
          <div className="display-month">
            <ButtonGroup className="switch">
              <Button onClick={prevMonth}>Prev month</Button>
              <Button onClick={nextMonth} disabled={isCurrentMonth}>Next month</Button>
            </ButtonGroup>
          </div>
          <DownloadTableToCsv
            style={{ position: 'absolute', alignSelf: 'end', right: 0 }}
            url={URL.createObjectURL(dataExport)}
            fileName={dataExport.name}
            text="Download to file"
          />
        </div>
        <div className="table">
          <Table
            autoHeight
            wordWrap="break-word"
            data={getData()}
            loading={visits.loading || loading}
            onSortColumn={handleSortColumn}
            sortColumn={sortColumn}
            sortType={sortType}
            defaultSortType="asc"
          >
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Car park</Table.HeaderCell>
              <TableData dataKey="location" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Vehicle</Table.HeaderCell>
              <TableData dataKey="rego" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Entry</Table.HeaderCell>
              <TableData dataKey="entry" content={renderParkingHistoryEntry} />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Exit</Table.HeaderCell>
              <TableData dataKey="exit" content={renderParkingHistoryExit} />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <TableData dataKey="duration" content={renderParkingHistoryDuration} />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Access type</Table.HeaderCell>
              <TableData format="tag" dataKey="accessType" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Fee</Table.HeaderCell>
              <TableData dataKey="fee" />
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Total price</Table.HeaderCell>
              <TableData dataKey="totalPrice" />
            </Table.Column>
          </Table>
          <div className="pagination">
            <PaginationControls
              nextPage={visits.next || undefined}
              prevPage={visits.prev || undefined}
            />
          </div>
        </div>

      </Panel>
    </div>
  )
}
