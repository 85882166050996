import { RootState } from '../../app/store'
import { StoreStatus, LoginStatus } from '../../types/enums'
import type { StatusedAuthUser, UserStatus } from '../../types/user'

const getUser = (state: RootState): StatusedAuthUser|null => {
  if (!state.user.user) {
    return null
  }
  return {
    ready: state.user.user?.login === LoginStatus.READY,
    unconfirmed: state.user.user?.login === LoginStatus.UNCONFIRMED,
    authenticated: state.user.user?.login === LoginStatus.AUTHENTICATED,
    passwordReset: state.user.user?.login === LoginStatus.PASSWORD_RESET,
    ...state.user.user,
  }
}

const getAuthUser = (state: RootState): StatusedAuthUser => {
  if (!state.user.user || state.user.user.login !== LoginStatus.AUTHENTICATED) {
    throw new Error('The current user is not authenticated in this context')
  }
  return getUser(state) as StatusedAuthUser
}

const getStatus = (state: RootState): UserStatus => ({
  loading: state.user.status === StoreStatus.LOADING,
  error: state.user.error,
})

export {
  getUser,
  getAuthUser,
  getStatus
}
