import React, { ReactElement } from 'react'

import { useUser } from '../../app/hooks'
import { UserRole } from '../../types/enums'

type Props = {
  children: JSX.Element|Array<JSX.Element>,
  role?: UserRole,
  roles?: Array<UserRole>,
}

/**
 * Show the child elements only if the user has one of the required roles
 * @return {ReactElement|null}
 */
function ShowWithRole (props: Props): ReactElement|null {
  const { children, role, roles } = props
  const user = useUser()
  const roleList = role ? [role] : roles || []

  if (!user.hasRole(...roleList)) return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export {
  ShowWithRole
}
