import React, { ReactElement, useEffect } from 'react'
import {
  Modal, Button, ButtonToolbar, SelectPicker, Notification, useToaster
} from 'rsuite'

import { useApi } from '../app/hooks'
import { addUserOrganisationAdmin, getOrganisations } from '../services/graphql/queries'
import { OrganisationDTO } from '../types/organisation'

export type Props = {
  user: string | null,
  header?: string,
  onConfirm?: () => void,
  onClose: () => void,
}

/**
 * Contirmation modal component
 * @return {ReactElement}
 */
function OrganisationAssignModal (props: Props): ReactElement {
  const [org, setOrg] = React.useState(null)
  const toaster = useToaster()

  const {
    user,
    header = 'Assign user as an organisation admin',
    onConfirm = () => {},
    onClose = () => {},
  } = props

  const api = useApi(getOrganisations)
  const addUserOrgAdminQuery = useApi(addUserOrganisationAdmin)
  const organisationData = api.getResponse()

  useEffect(() => {
    api.sendRequest()
    return api.cleanup
  }, [])

  /**
   * Close the modal
   */
  const close = () => {
    onClose()
  }

  const message = (
    <Notification type="error" header="Error" closable>
      Unable to assign organisation admin, please try again or contact support.
    </Notification>
  )

  /**
   * Run the confirm action and close the modal
   */
  const confirm = () => {
    if (!user) {
      toaster.push(message, { placement: 'bottomStart', duration: 5000 })
    } else {
      addUserOrgAdminQuery.sendRequest({
        id: user,
        adminOrganisationId: org || '',
      })
    }
    onConfirm()
    onClose()
  }

  /**
   * Format organisation data for select picker
   */
  const formatData = (res: any) => res?.map(
    (data: OrganisationDTO) => ({ label: data.name, value: data.id })
  ) ?? []

  const organisations = organisationData.success ? formatData(organisationData.data?.items) : []

  return (
    <>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectPicker loading={organisationData.loading} value={org} onChange={setOrg} data={organisations} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={confirm} appearance="primary">Confirm</Button>
          <Button onClick={close} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  OrganisationAssignModal
}
