import React from 'react'

import './DashboardSubscriptionItem.css'
import { SubscriptionStatus } from '../types/enums'

/**
 *  Prop type for  {@link DashboardSubscriptionItem}
 */
type DashboardSubscriptionItemProps = {
  locationName: string,
  locationAddress: string,
  status: SubscriptionStatus
  loading?: boolean
}

/**
 *  Subscription item displayed on the customer dashbaord
 */
function DashboardSubscriptionItem (props: DashboardSubscriptionItemProps): React.ReactElement {
  const {
    loading, status, locationAddress, locationName,
  } = props

  let statusClass = ''
  if (status === SubscriptionStatus.ACTIVE) {
    statusClass = '-active'
  }

  return (
    <div className="dashboard-subscription-item">
      <div>
        <div className="title">
          {loading ? 'loading...' : locationName}
        </div>
        <div className="description">
          {loading ? 'Loading...' : locationAddress}
        </div>
      </div>
      <div className={`status ${statusClass}`}>
        {loading ? 'Loading...' : status}
      </div>
    </div>
  )
}

export {
  DashboardSubscriptionItem,
  type DashboardSubscriptionItemProps
}
