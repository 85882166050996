import React, { ReactElement } from 'react'

import { usePageTitle } from '../../app/hooks'
import { ErrorPage } from './ErrorPage'

/**
 * Not authorised page
 * @return {ReactElement}
 */
function NotAuthorised (): ReactElement {
  usePageTitle('Not authorised')
  return (
    <ErrorPage
      type="warning"
      title="Not authorised"
      message="You don't have the required permission to view this page"
    />
  )
}

export {
  NotAuthorised
}
