import React from 'react'
import { Col, FlexboxGrid, Panel } from 'rsuite'

import { Voucher } from '../../types/voucher'
import { DefinitionList, PanelHeader } from '../../components'
import QRDisplay from './QRDisplay'

export default function VoucherDefinition ({ voucher, onEdit }: { voucher: Voucher, onEdit: () => void }) {
  const voucherData = {
    Name: voucher.name,
    Type: voucher.type,
    'Car park': voucher.locationName,
    ...((voucher.type === 'PERIOD') && voucher.startDate && { 'Start date': voucher.startDate }),
    'Expiry date': voucher.expiryDate,
    ...(voucher.phoneNumber && { 'Recipient phone number': voucher.phoneNumber }),
    ...(voucher.email && { 'Recipient email': voucher.email }),
    Flags: voucher.flags?.length > 0 ? voucher.flags : 'No flags set',
  }

  return (
    <Panel
      data-testid="voucher-definition"
      header={(
        <PanelHeader
          header="Voucher Details"
          onEdit={onEdit}
        />
      )}
    >
      <FlexboxGrid>
        <FlexboxGrid.Item as={Col} colspan={18} md={12}>
          <DefinitionList data={voucherData} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={12} md={6}>
          <dl>
            <dt>Access Code</dt>
            <dd><QRDisplay voucher={voucher} /></dd>
          </dl>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  )
}
