import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Panel, Table
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import {
  TableData, ActionMenu, PanelHeader
} from '../../components'
import type { RowData } from '../../types/table'
import { OrganisationDTO } from '../../types/organisation'
import { updateOrganisationGroups } from '../../services/graphql/queries'
import { useApi } from '../../app/hooks'

const { Column, HeaderCell, Cell } = Table

/**
 * Group list component
 * @return {ReactElement}
 */
function OrganisationGroups ({ isAdmin, data }: { isAdmin: boolean, data: OrganisationDTO }): ReactElement {
  const navigate = useNavigate()

  const [groupData, setGroupData] = useState(data.groups ? JSON.parse(data.groups) : [])

  const editOrganisationGroupsApi = useApi(updateOrganisationGroups)
  const editResponse = editOrganisationGroupsApi.getResponse()
  const isLoading = editResponse.loading
  const responseData = editResponse.data

  useEffect(() => {
    if (responseData !== null) {
      setGroupData(responseData.groups ? JSON.parse(responseData.groups) : [])
    }
    return editOrganisationGroupsApi.cleanup
  }, [responseData])

  /**
   * Dropdown menu for a row in the group list table
   * @param {RowData} group - The group object of the row
   * @return {ReactElement}
   */
  const renderActions = (group: RowData): ReactElement => {
    const actions = [
      { label: 'Edit', action: () => navigate(`groups/${group.id}`) },
    ]
    if (isAdmin) {
      actions.push({
        label: 'Delete',
        action: () => {
          const groups = JSON.parse(data.groups) || []
          const groupIdxToRemove = groups.findIndex((g: any) => g.id === group.id)
          if (groupIdxToRemove !== -1) {
            groups.splice(groupIdxToRemove, 1)
          }
          const organisationUpdate = { organisationId: data.id, groups: JSON.stringify(groups) }
          editOrganisationGroupsApi.sendRequest(organisationUpdate)
        },
      })
    }
    return (
      <ActionMenu actions={actions} />
    )
  }

  return (
    <Panel
      header={(
        <PanelHeader
          header="Groups"
          onEdit={() => navigate('groups/new')}
          icon={<Icon as={FaPlus} />}
          editDisabled={!isAdmin}
        />
      )}
    >
      <Table
        autoHeight
        data={groupData}
        loading={isLoading}
      >
        <Column flexGrow={1}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column flexGrow={0.5}>
          <HeaderCell>Users assigned</HeaderCell>
          <Cell dataKey="spaceAllocation">
            {(rowData) => (
              <div>
                {rowData.vehicles?.length ?? 0}
              </div>
            )}
          </Cell>
        </Column>
        <Column flexGrow={0.5}>
          <HeaderCell>Actions</HeaderCell>
          <TableData dataKey="action" content={renderActions} />
        </Column>
      </Table>
    </Panel>
  )
}

export {
  OrganisationGroups
}
