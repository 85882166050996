import { GateStatus } from './enums'

/**
 * Determines if status is of type `GateStatus`
 * @param {any} status - the status to check
 */
function isGateStatus (status: any): status is GateStatus {
  return status === GateStatus.ACTIVE
    || status === GateStatus.OFFLINE
}

export {
  isGateStatus
}
