const ProductTypes = [
  {
    label: 'Visit',
    value: 'VISIT',
  },
  {
    label: 'Organisation',
    value: 'ORGANISATION',
  },
  {
    label: 'Subscription',
    value: 'SUBSCRIPTION',
  },
]

export {
  ProductTypes
}
