import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { Panel } from 'rsuite'

import { ConfirmAccountForm } from '../../forms/ConfirmAccountForm'
import {
  useSelector, useFormState, useDispatch, usePageTitle, useNavigateAction
} from '../../app/hooks'
import { userActions, userSelectors } from '../../store/user/slice'

/**
 * Account confirmation panel component
 * @return {ReactEelemnt}
 */
function Confirm (): ReactElement {
  usePageTitle('Confirm account')
  const dispatch = useDispatch()
  const user = useSelector(userSelectors.getUser)
  const status = useSelector(userSelectors.getStatus)
  const [formData, setField] = useFormState({ code: '' })

  useNavigateAction(() => {
    dispatch(userActions.clearUser())
  })

  if (!user || user.ready) return <Navigate replace to="/login" />

  /**
   * Submit the account confirmation form
   */
  const submit = () => dispatch(userActions.confirm({
    username: user.id,
    code: formData.code,
  }))

  /**
   * Request a new confirmation code
   */
  const requestCode = () => dispatch(userActions.resendCode({ username: user.id }))

  return (
    <div className="login-page">
      <Panel className="form -confirm" bordered header="Confirm account">
        <p>A code has been sent to your phone number. Please enter the code to confirm your account.</p>
        <ConfirmAccountForm
          formValue={formData}
          onChange={setField}
          onSubmit={submit}
          onRequestCode={requestCode}
          loading={status.loading}
          error={status.error}
        />
      </Panel>
    </div>
  )
}

export {
  Confirm
}
