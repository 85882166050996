import { PayloadAction } from '@reduxjs/toolkit'

import { UserState } from './slice'
import { StoreStatus } from '../../types/enums'
import type { AuthUser, UserError } from '../../types/user'

const setLoading = (state: UserState): void => {
  state.status = StoreStatus.LOADING
  state.error = null
}

const setDone = (state: UserState): void => {
  state.status = StoreStatus.IDLE
  state.error = null
}

const setFailed = (state: UserState, action: PayloadAction<UserError>): void => {
  state.error = action.payload
  state.status = StoreStatus.FAILED
}

const setUser = (state: UserState, action: PayloadAction<AuthUser>): void => {
  state.user = action.payload
  state.status = StoreStatus.IDLE
}

const updateUser = (state: UserState, action: PayloadAction<Partial<AuthUser>>): void => {
  if (state.user) {
    state.user = {
      ...state.user,
      ...action.payload,
    }
  }
  state.status = StoreStatus.IDLE
}

const clearUser = (state: UserState): void => {
  state.user = null
  state.status = StoreStatus.IDLE
  state.error = null
}

export {
  setLoading,
  setDone,
  setFailed,
  setUser,
  updateUser,
  clearUser
}
