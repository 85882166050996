import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserList } from '../../features/users/UserList'
import { UserDetails } from '../../features/users/UserDetails'
import { UserRole } from '../../types/enums'
import { NotFound } from '../../features/error-pages/NotFound'

/**
 * UsersPage route
 * @return {ReactElement}
 */
function UsersPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN, UserRole.OPERATOR]}>
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path="/new" element={<UserDetails />} />
        <Route path="/:userId" element={<UserDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RequireRole>
  )
}

export {
  UsersPage
}
