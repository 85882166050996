import React from 'react'
import { Table } from 'rsuite'

import { TableData } from '../../components'
import { LocationWithDefaultPrice } from '../../types/location'
import { RowData } from '../../types/table'

import './CustomerSubscriptionLocationTable.css'

type Props = {
  loading?:boolean,
  data: LocationWithDefaultPrice[]
  // eslint-disable-next-line no-unused-vars
  onSelectCb: (locationId:string)=>void
}

/**
 * Table for displaying the locations available to customers wishing to create a subscription
 * @param {Props} props
 * @param {Boolean} props.loading                       - Whether to display this component's loading state
 * @param {LocationWithDefaultPrice[]} props.data       - The table data to display
 * @param {(locationId:string)=>void} props.onSelectCb  - The Cb to fire when the user selects a location
 * @return {React.ReactElement}
 */
function CustomerSubscriptionLocationTable (props:Props): React.ReactElement {
  const { loading, data, onSelectCb } = props

  /**
   * Table.Cell child for rendering a locations's name
   * @param {Location} location
   * @return {React.ReactElement}
   */
  const renderName = (location: RowData): React.ReactElement => (
    <button className="button" type="button" onClick={() => onSelectCb(location?.id)}>
      {location.name}
    </button>
  )

  /**
   * Table.Cell child for rendering a locations's address
   * @param {LocationWithDefaultPrice} location
   * @return {React.ReactElement}
   */
  const renderAddress = (location: RowData): React.ReactElement => (
    location.address
  )

  /**
   * Table.Cell child for rendering the price of the location's
   * default plan
   * @param {LocationWithDefaultPrice} location
   * @return {React.ReactElement}
   */
  const renderPrice = (location: RowData): React.ReactElement => (
    <div>
      $
      {location.pricing.price}
    </div>
  )
  return (
    <div className="customer-subscription-location-table">
      <div className="section-title">Carparks</div>
      <p className="instruction">Select the carpark you would like to park at.</p>
      <Table
        data={data}
        loading={loading}
        autoHeight
        wordWrap="break-word"
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <TableData dataKey="address" content={renderAddress} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <TableData dataKey="price" content={renderPrice} />
        </Table.Column>
      </Table>
    </div>
  )
}

export { CustomerSubscriptionLocationTable }
