import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { CustomerVisitsList } from '../../features/visits/CustomerVisitsList'

/**
 * Customer visits route
 * @return {ReactElement}
 */
function CustomerVisitsPage (): ReactElement {
  return (
    <Routes>
      <Route path="/:month" element={<CustomerVisitsList />} />
      <Route path="/" element={<CustomerVisitsList />} />
    </Routes>
  )
}

export {
  CustomerVisitsPage
}
