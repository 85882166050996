import { useEffect, useState } from 'react'

import { paginateItems } from '../../helpers'
import { RequestPaginationOptions } from '../../types/api'

import { StoreStatus } from '../../types/enums'
import { PaginatedApiConfig, PaginatedApiHook } from '../../types/pagination'
import { useApi } from './api'

/**
 * Hook for handling a query with pagination, internally uses {@link useApi}
 */
export function usePaginatedApi<Type, Params extends RequestPaginationOptions> (
  config: PaginatedApiConfig<Type, Params>
): PaginatedApiHook<Type, Params> {
  const {
    query, fetchParams, itemsPerPage = 20, queryOptions,
  } = config

  const [params, setParams] = useState(fetchParams)

  const paginatedQuery = useApi(query, queryOptions)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const response = paginatedQuery.getResponse()
  const pages = response?.data?.items?.length ? paginateItems(response.data.items, itemsPerPage) : null

  useEffect(
    () => {
      paginatedQuery.sendRequest({ ...params, limit: itemsPerPage, next: null })
      return paginatedQuery.reset()
    },
    [params]
  )

  useEffect(() => paginatedQuery.cleanup, [])

  useEffect(() => {
    if (!pages?.pages[currentIndex] && currentIndex !== 0) {
      paginatedQuery.sendRequest({
        ...params,
        limit: itemsPerPage,
        next: response.data?.next || null,
      }, (data) => {
        if (!response.data?.items.length) {
          return data
        }
        return {
          ...data,
          items: [...response.data.items, ...data.items],
        }
      })
    }
  }, [currentIndex])

  const isPrev = currentIndex > 0
  let isNext = !!pages?.pages?.length

  if (currentIndex === (pages?.pages?.length ?? 0) - 1) {
    isNext = !!response?.data?.next
  }

  const next = () => {
    setCurrentIndex((prev) => prev + 1)
  }

  const prev = () => {
    setCurrentIndex((previousIndex) => previousIndex - 1)
  }

  const reset = () => {
    paginatedQuery.reset()
    setCurrentIndex(0)
  }

  const sendNewRequest = (newParams:Params) => {
    reset()
    setParams(newParams)
  }

  return {
    sendRequest: sendNewRequest,
    currentPage: pages?.pages[currentIndex] || null,
    loading: paginatedQuery.getResponse().status === StoreStatus.LOADING,
    reset,
    next: isNext ? next : null,
    prev: isPrev ? prev : null,
    apiHook: paginatedQuery,
  }
}
