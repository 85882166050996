import { RootState } from '../../app/store'
import { StoreStatus } from '../../types/enums'
import { initialResponseState } from './slice'
import type { StatusedApiResponse } from '../../types/api'

const getResponse = <Type>(requestId: string) => (
  (state: RootState): StatusedApiResponse<Type> => {
    const data = state.api[requestId] || initialResponseState
    return {
      ...data,
      loading: data.sent && data.status === StoreStatus.LOADING,
      success: data.sent && data.status === StoreStatus.IDLE,
    }
  }
)

export {
  getResponse
}
