import React from 'react'

import { CtaButton } from './CtaButton'
import './DashboardProfileCard.css'

const tick = (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* eslint-disable-next-line */}
                    <path d="M31.7656 14.9922C31.7656 14.5304 31.6172 14.151 31.3203 13.8542L29.069 11.6276C28.7556 11.3142 28.3846 11.1576 27.9557 11.1576C27.5269 11.1576 27.1558 11.3142 26.8424 11.6276L16.7487 21.6966L11.1576 16.1055C10.8442 15.7921 10.4731 15.6354 10.0443 15.6354C9.61545 15.6354 9.24436 15.7921 8.93099 16.1055L6.67969 18.332C6.38281 18.6289 6.23438 19.0082 6.23438 19.4701C6.23438 19.9154 6.38281 20.2865 6.67969 20.5833L15.6354 29.5391C15.9488 29.8524 16.3199 30.0091 16.7487 30.0091C17.194 30.0091 17.5733 29.8524 17.8867 29.5391L31.3203 16.1055C31.6172 15.8086 31.7656 15.4375 31.7656 14.9922ZM35.4518 9.47526C37.1506 12.3781 38 15.5529 38 19C38 22.4471 37.1506 25.6261 35.4518 28.5371C33.753 31.4481 31.4481 33.753 28.5371 35.4518C25.6261 37.1506 22.4471 38 19 38C15.5529 38 12.3739 37.1506 9.46289 35.4518C6.55185 33.753 4.24697 31.4481 2.54818 28.5371C0.849384 25.6261 0 22.4471 0 19C0 15.5529 0.849384 12.3739 2.54818 9.46289C4.24697 6.55185 6.55185 4.24697 9.46289 2.54818C12.3739 0.849384 15.5529 0 19 0C22.4471 0 25.6261 0.849384 28.5371 2.54818C31.4481 4.24697 33.753 6.55598 35.4518 9.47526Z" fill="#15BF78" />
  </svg>
)

/**
 * Prop type for {@link DashboardProfileCard}
 */
type DashboardProfileCardProps = {
  title: string,
  description: string,
  ctaLabel: string,
  ctaIcon: React.ReactElement,
  ctaAction: ()=>void,
  complete?: boolean
}

/**
 *  Card component used to display the steps user needs to setup their account
 */
function DashboardProfileCard (props: DashboardProfileCardProps): React.ReactElement {
  const {
    title, description, ctaLabel, ctaIcon, ctaAction, complete,
  } = props

  const completeClass = complete ? '-complete' : ''
  return (
    <div className={`dashboard-profile-card ${completeClass}`}>
      <div>
        <div className="title">
          {title}
        </div>
        <div className="subtitle">
          {description}
        </div>
      </div>
      <div className="bottom">
        { complete ? (
          <div className="profile-card-complete-tick">
            <div className="tick">
              {tick}
            </div>
          </div>
        )
          : <CtaButton variant="md" label={ctaLabel} onClick={ctaAction} icon={ctaIcon} />}
      </div>
    </div>
  )
}

export {
  DashboardProfileCard,
  type DashboardProfileCardProps
}
