/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FieldInputProps, FormikProps } from 'formik'
import {
  Button,
  DateInput,
  Input,
  InputNumber,
  Message,
  Panel,
  Table,
  Toggle
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'

import {
  CenteredLoader,
  PanelHeader
} from '../../components'

import './OrganisationGroupVehicles.css'
import { SearchBar } from '../../components/SearchBar'

import { useApi } from '../../app/hooks'
import { getOrganisation } from '../../services/graphql/queries'

const { Column, HeaderCell, Cell } = Table

interface RowData {
  id: string;
  name?: string
  email?: string
  license?: string
  make?: string
  model?: string
  phone?: string
  colour?: string
  state?: string
  active?: string
}

function ToggleCell (
  {
    rowData, dataKey, onChange, inputStyle, ...props
  }: { rowData?: RowData, dataKey: string, onChange: any, inputStyle?: any }
) {
  const data = rowData || { id: Date.now().toString() }
  return (
    <Cell id={data.id + dataKey} style={{ padding: 4 }} {...props}>
      <Toggle
        style={{ ...inputStyle, margin: '10%', display: 'inline-block' }}
        id={data.id + dataKey}
        checked={String(data[dataKey as keyof RowData]) === 'true'}
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        size="md"
        onChange={(v: any) => {
          onChange?.(rowData?.id, dataKey, v)
        }}
      />
    </Cell>
  )
}

function DeleteCell (
  {
    rowData, deleteRow, ...props
  }: { rowData?: RowData, dataKey: string, deleteRow: any }
) {
  return (
    <Cell {...props}>
      <Button
        style={{ padding: 4, fontWeight: 100 }}
        appearance="link"
        onClick={() => deleteRow(rowData)}
      >
        Delete
      </Button>
    </Cell>
  )
}

const filterTableRow = (vehicle: RowData, filterBy: string) => {
  const vehicleStr = `
    ${vehicle.name} ${vehicle.email} ${vehicle.license} ${vehicle.make} 
    ${vehicle.model} ${vehicle.phone} ${vehicle.active ? '~active' : '~inactive'}
  `
  let m = filterBy.toLowerCase()
  if ((m === 'active') || (m === 'on')) {
    m = '~active'
  } else if ((m === 'inactive') || (m === 'off')) {
    m = '~inactive'
  }
  return vehicleStr.toLowerCase().includes(m)
}

const EditableContext = React.createContext({
  editingId: null,
  editingKey: null,
  onEdit: (id: any, dataKey: any) => {},
  onEditFinished: () => {},
})

function toValueString (value: any, dataType: any) {
  return dataType === 'date' ? value?.toLocaleDateString() : value
}

const fieldMap = {
  string: Input,
  number: InputNumber,
  date: DateInput,
}

function focus (ref: any) {
  setTimeout(() => {
    if (ref.current?.tagName === 'INPUT' || ref.current?.getAttribute('tabindex') === '0') {
      ref.current.focus()
    } else if (ref.current instanceof HTMLElement) {
      ref.current.querySelector('input').focus()
    }
  }, 0)
}

function EditableCell ({
  rowData, dataType, dataKey, onChange, style, ...props
}: { rowData?: RowData, dataType: string, dataKey: string, style?: any, onChange: any }) {
  const {
    editingId, editingKey, onEdit, onEditFinished,
  } = React.useContext(EditableContext)
  const editing = rowData?.id === editingId && dataKey === editingKey
  const Field = fieldMap[dataType]
  let value
  if (rowData?.id != null) {
    value = rowData[dataKey]
  }
  const text = toValueString(value, dataType)
  const inputRef = React.useRef()
  const cellRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

  const handleEdit = () => {
    onEdit?.(rowData?.id, dataKey)
    focus(inputRef)
  }

  const handleFinished = (v) => {
    onChange?.(rowData?.id, dataKey, v)
    onEditFinished()
    focus(cellRef)
  }

  return (
    <Cell
      {...props}
      ref={cellRef}
      tabIndex={0}
      className={editing ? 'table-cell-editing' : 'table-cell'}
      onDoubleClick={handleEdit}
      style={style}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleEdit()
        }
      }}
    >
      {editing ? (
        <Field
          ref={inputRef}
          defaultValue={value}
          onBlur={() => {
            const c: any = inputRef?.current
            handleFinished(c?.value)
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              const c: any = inputRef?.current
              handleFinished(c?.value)
            }
          }}
          style={style}
          onChange={(v: any) => {
          //  onChange?.(rowData.id, dataKey, v)
          }}
        />
      ) : (
        text
      )}
    </Cell>
  )
}

/**
 * Group list component
 * @return {ReactElement}
 */
function OrganisationGroupVehicles ({
  field, form,
}: { field: FieldInputProps<'vehicles'>, form: FormikProps<any>}): ReactElement {
  const { organisationId } = useParams()
  const { vehicles: data } = form.values
  const [searchValue, setSearchValue] = React.useState('')
  const [tableData, setTableData] = React.useState(data || [])

  const fetchOrganisationGroupsApi = useApi(getOrganisation)
  const fetchResponse = fetchOrganisationGroupsApi.getResponse()
  const isLoading = fetchResponse.loading

  const [editingId, setEditingId] = React.useState(null)
  const [editingKey, setEditingKey] = React.useState(null)

  const handleChange = (id: any, key: string | number, value: any) => {
    const nextData = Object.assign([], data)
    nextData.find((item: { id: any }) => item.id === id)[key] = value
    setTableData(nextData)
  }

  const onEdit = (id: any, dataKey: any) => {
    setEditingId(id)
    setEditingKey(dataKey)
  }

  const onEditFinished = () => {
    setEditingId(null)
    setEditingKey(null)
  }

  useEffect(() => {
    if (organisationId) {
      fetchOrganisationGroupsApi.sendRequest({ organisationId })
    }
    return fetchOrganisationGroupsApi.cleanup
  }, [])

  const handleDeleteRow = (rowData: RowData) => {
    const nextData = [...data].filter((row) => row.id !== rowData.id)
    setTableData(nextData)
    form.setFieldValue(field.name, nextData)
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setTableData(data.filter((vehicle: RowData) => filterTableRow(vehicle, value)))
  }

  if (!fetchResponse.data || fetchResponse.loading) {
    return <CenteredLoader />
  }

  return (
    <Panel
      className="organisation-group-vehicles"
      header={(
        <PanelHeader
          loading={isLoading}
          header="Vehicles"
          onEdit={() => {
            const nextData = [
              {
                id: uuid(),
                name: '',
                email: '',
                license: '',
                make: '',
                model: '',
                phone: '',
                colour: '',
                state: '',
                active: false,
              },
              ...data,
            ]
            setSearchValue('')
            setTableData(nextData)
            form.setFieldValue(field.name, nextData)
          }}
          icon={<Icon as={FaPlus} />}
        />
      )}
    >
      <Message showIcon type="info">
        To edit a value, double-click or press the `Enter` key
      </Message>
      <SearchBar
        styles={{ marginBottom: 4 }}
        placeholder="Search rows"
        value={searchValue}
        onChange={handleSearchChange}
      />
      <EditableContext.Provider value={{
        editingId, editingKey, onEdit, onEditFinished,
      }}
      >
        <Table
          rowHeight={52}
          autoHeight
          bordered
          cellBordered
          data={tableData}
          className="group-table"
        >
          <Column flexGrow={0.75}>
            <HeaderCell>License Plate</HeaderCell>
            <EditableCell
              dataKey="license"
              dataType="string"
              onChange={handleChange}
              style={{ textTransform: 'uppercase' }}
            />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Make</HeaderCell>
            <EditableCell
              dataKey="make"
              dataType="string"
              onChange={handleChange}
            />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Model</HeaderCell>
            <EditableCell
              dataKey="model"
              dataType="string"
              onChange={handleChange}
            />
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>State</HeaderCell>
            <EditableCell
              dataKey="state"
              dataType="string"
              onChange={handleChange}
            />
          </Column>
          <Column flexGrow={0.75}>
            <HeaderCell>Colour</HeaderCell>
            <EditableCell
              dataKey="colour"
              dataType="string"
              onChange={handleChange}
            />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <EditableCell
              dataKey="name"
              dataType="string"
              onChange={handleChange}
            />
          </Column>
          <Column width={120}>
            <HeaderCell>Status</HeaderCell>
            <ToggleCell onChange={handleChange} dataKey="active" />
          </Column>
          <Column width={90}>
            <HeaderCell>...</HeaderCell>
            <DeleteCell deleteRow={handleDeleteRow} dataKey="id" />
          </Column>
        </Table>
      </EditableContext.Provider>
    </Panel>
  )
}

export {
  OrganisationGroupVehicles
}
