/* eslint-disable react/no-danger */
import React from 'react'

import { Voucher } from '../../types/voucher'

export default function QRDisplay ({ voucher }: { voucher: Voucher}) {
  return (
    voucher.code
      ? (
        <div
          style={{ width: 128, height: 128, paddingTop: 2 }}
          dangerouslySetInnerHTML={{ __html: voucher.code }}
        />
      )
      : <p>No QR code found</p>
  )
}
