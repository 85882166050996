import React, { ReactElement, ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Panel, Table } from 'rsuite'

import { useApi, usePageTitle } from '../../app/hooks'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import { DefinitionList, PaginationControls, TableData } from '../../components'

import {
  getUser, getUserVehicles, getSubscriptions, getUserVehicleVisits
} from '../../services/graphql/queries'
import { RowData } from '../../types/table'
import { formatUserDisplayName, formatUserRoles } from './userHelpers'
import { NotFound } from '../error-pages/NotFound'
import { getUserVehicleVisitsTableRow, visitDtoToVehicleVisit } from '../../types/visitHelpers'

import './UserDetails.css'
/**
 * Create/edit organisation component
 * @return {ReactElement}
 */
export function UserDetails (): ReactElement {
  usePageTitle('User details')
  const { userId = '' } = useParams()
  const userQuery = useApi(getUser, {
    initialFetch: true,
    initialFetchParams: { id: userId },
    cleanUpOnDismount: true,
  })

  const vehicleQuery = usePaginatedApi({
    query: getUserVehicles,
    fetchParams: { userId },
  })

  const subscriptionQuery = usePaginatedApi({
    query: getSubscriptions,
    fetchParams: { userId },
  })

  const visitsQuery = usePaginatedApi({
    query: getUserVehicleVisits,
    fetchParams: { userId },
  })

  const userResp = userQuery.getResponse()
  const user = userResp.data || null
  if (userResp.error) {
    return <NotFound />
  }

  const definition = user ? {
    Name: formatUserDisplayName(user),
    Email: user.email,
    Phone: user.phoneNumber,
    Roles: formatUserRoles(user),
    Flags: user.flags?.join(' '),
  } : {}

  /**
   * Render the car park name for a subscription
   * @param {RowData} subscription
   * @return {ReactNode}
   */
  const renderSubscriptionCarpark = (subscription: RowData): ReactNode => (
    subscription.location.name
  )

  /**
   * Render the plan name for a subscription
   * @param {RowData} subscription
   * @return {ReactNode}
   */
  const renderSubscriptionPlan = (subscription: RowData): ReactNode => (
    subscription.plan.name
  )

  const visitsTableData = useMemo(
    () => visitsQuery.currentPage?.map(visitDtoToVehicleVisit).map(getUserVehicleVisitsTableRow) ?? [],
    [visitsQuery.currentPage]
  )

  const renderParkingHistoryEntry = (parkingHistory: RowData): ReactNode => (
    parkingHistory.status === 'MISSING_ENTRY' ? '' : parkingHistory.entry
  )

  const renderParkingHistoryExit = (parkingHistory: RowData): ReactNode => (
    parkingHistory.status === 'MISSING_EXIT' ? '' : parkingHistory.exit
  )

  const renderParkingHistoryDuration = (parkingHistory: RowData): ReactNode => (
    (parkingHistory.status === 'MISSING_EXIT'
    || parkingHistory.status === 'MISSING_ENTRY') ? '' : parkingHistory.duration
  )

  return (
    <>
      <Panel
        header={(
          <h2>User details</h2>
        )}
      >
        <DefinitionList loading={userResp.loading} data={definition} />
      </Panel>
      <Panel
        header={(
          <h2>User vehicles</h2>
        )}
      >
        {
          !vehicleQuery.loading && !vehicleQuery.currentPage ? <div> User has no vehicles </div>
            : (
              <Table
                autoHeight
                wordWrap="break-word"
                data={vehicleQuery.currentPage || []}
                loading={vehicleQuery.loading}
              >
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Plate</Table.HeaderCell>
                  <TableData dataKey="rego" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Make</Table.HeaderCell>
                  <TableData dataKey="make" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Model</Table.HeaderCell>
                  <TableData dataKey="model" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>State</Table.HeaderCell>
                  <TableData dataKey="state" />
                </Table.Column>
              </Table>
            )
        }
      </Panel>
      <Panel
        header={(
          <h2>User subscriptions</h2>
        )}
      >
        {
          !subscriptionQuery.loading && !subscriptionQuery.currentPage ? <div>User has no subscriptions</div>
            : (
              <Table
                autoHeight
                wordWrap="break-word"
                data={subscriptionQuery.currentPage || []}
                loading={subscriptionQuery.loading}
              >
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Car park</Table.HeaderCell>
                  <TableData dataKey="carpark" content={renderSubscriptionCarpark} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Plan name</Table.HeaderCell>
                  <TableData dataKey="planName" content={renderSubscriptionPlan} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Parking bays</Table.HeaderCell>
                  <TableData dataKey="vehicles" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <TableData dataKey="status" format="tag" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>Start date</Table.HeaderCell>
                  <TableData dataKey="startDate" format="date" />
                </Table.Column>
                <Table.Column flexGrow={1}>
                  <Table.HeaderCell>End date</Table.HeaderCell>
                  <TableData dataKey="endDate" format="date" />
                </Table.Column>
              </Table>
            )
        }
      </Panel>
      <Panel
        header={(
          <h2>Parking history</h2>
        )}
      >
        {
          !visitsQuery.loading && !visitsQuery.currentPage ? <div>User has no visits</div>
            : (
              <div className="user-visits-list">
                <Table
                  autoHeight
                  wordWrap="break-word"
                  data={visitsTableData}
                  loading={visitsQuery.loading}
                >
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Car park</Table.HeaderCell>
                    <TableData dataKey="location" />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Vehicle</Table.HeaderCell>
                    <TableData dataKey="rego" />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Entry</Table.HeaderCell>
                    <TableData dataKey="entry" content={renderParkingHistoryEntry} />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Exit</Table.HeaderCell>
                    <TableData dataKey="exit" content={renderParkingHistoryExit} />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Duration</Table.HeaderCell>
                    <TableData dataKey="duration" content={renderParkingHistoryDuration} />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Access type</Table.HeaderCell>
                    <TableData format="tag" dataKey="accessType" />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Fee</Table.HeaderCell>
                    <TableData dataKey="fee" />
                  </Table.Column>
                  <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Total price</Table.HeaderCell>
                    <TableData dataKey="totalPrice" />
                  </Table.Column>
                </Table>
                <div className="pagination">
                  <PaginationControls
                    nextPage={visitsQuery.next || undefined}
                    prevPage={visitsQuery.prev || undefined}
                  />
                </div>
              </div>
            )
        }
      </Panel>
    </>
  )
}
