import { AccessListType } from '../../types/enums'

const accessListTypes = [
  {
    label: 'Allow list',
    value: AccessListType.ALLOW,
  },
  {
    label: 'Deny list',
    value: AccessListType.DENY,
  },
]

export {
  accessListTypes
}
