import { getPaymentMethod, getSubscriptions, getUserVehicles } from '../../services/graphql/queries'
import { CustomerDashboardApiData } from '../../types/dashboard'
import { useApi } from './api'
import { useUser } from './user'

/**
 *  Hook to retreive customer data for the dashboard
 */
function useCustomerDashboardData (): CustomerDashboardApiData {
  const user = useUser()
  const userId = user.id
  const vehicle = useApi(getUserVehicles, {
    displayErrorAlerts: false,
    initialFetch: true,
    initialFetchParams: { userId },
    cleanUpOnDismount: true,
  })
  const payment = useApi(getPaymentMethod, {
    displayErrorAlerts: false,
    initialFetch: true,
    initialFetchParams: { userId },
    cleanUpOnDismount: true,
  })
  const subscriptions = useApi(getSubscriptions, {
    displayErrorAlerts: false,
    initialFetch: true,
    initialFetchParams: { userId },
    cleanUpOnDismount: true,
  })

  const vehicleRes = vehicle.getResponse()
  const paymentRes = payment.getResponse()
  const subscriptionsRes = subscriptions.getResponse()

  return {
    vehicles: vehicleRes,
    paymentMethod: paymentRes,
    subscriptions: subscriptionsRes,
  }
}

export {
  useCustomerDashboardData
}
