import React from 'react'
import { Icon } from '@rsuite/icons'
import FileDownloadIcon from '@rsuite/icons/FileDownload'

function tableToCSV (columns: Array<{ key: String, label: String }>, data: Array<any>) {
  const blob: string[] = []
  blob.push(columns.map((c) => c.label).join())
  data.forEach((row) => {
    blob.push('\n')
    const orderedData: any[] = []
    const d: any[] = []
    columns.forEach((column) => orderedData.push(row[column.key as keyof typeof row] || 'null'))
    orderedData.forEach((o) => {
      let wrapInQuotes = false
      let v = String(o)
      if (v.indexOf('"') > -1) {
        v = v.replaceAll('"', '\'')
      }
      wrapInQuotes = (v.indexOf(',') > -1) || (v.indexOf('\n') > -1)

      if (wrapInQuotes) {
        v = `"${v}"`
      }
      d.push(v)
    })
    blob.push(d.join())
  })
  return blob
}

function DownloadTableToCsv ({
  url, fileName, text, style,
}: {url:any, fileName:any, text: String, style?: any}) {
  return (
    <a href={url} download={fileName} style={{ color: 'black', ...style }}>
      {text}
      {' '}
      <Icon as={FileDownloadIcon} height={18} width={18} fill="black" />
    </a>
  )
}

export {
  DownloadTableToCsv,
  tableToCSV
}
