/* eslint-disable max-len */
import React, { ReactElement, SVGProps } from 'react'

function CreditCard (props: SVGProps<SVGSVGElement>): ReactElement {
  /* eslint-disable react/jsx-props-no-spreading */
  const {
    width, height, children, ...rest
  } = props
  return (
    <svg viewBox="0 0 24 16" width={width} height={height} {...rest}>
      {children}
    </svg>
  )
}

export { CreditCard }
