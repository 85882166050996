/* eslint-disable no-param-reassign */
import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  Panel, Table, Tag, TagGroup
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { useApi, useModal } from '../../app/hooks'
import { getLocation } from '../../services/graphql/queries'
import {
  ConfirmModal, TableData, ActionMenu, Modal, PanelHeader
} from '../../components'
import { CameraGroup } from '../../types/location'
import { deleteFromPaginatedWithId } from '../../helpers'
import type { RowData } from '../../types/table'
import { deleteTouchscreen, getLocationTouchscreens } from '../../services/graphql/queries/touchscreens'
import { TouchscreenConfig } from '../../types/touchscreen'
import { GateStatus, ScreenType } from '../../types/enums'
import { isGateStatus } from '../../types/gateHelpers'
import { getGateStatusTagColor } from '../gates/helpers'

const {
  Column,
  HeaderCell,
  Cell,
} = Table

/**
* Table.Cell child for rendering a gate's status
* @param {RowData} gate
* @return {ReactElement}
*/
const renderStatus = (gate: RowData): React.ReactElement => {
  // hard coding for the moment - need to updated from devices
  if (isGateStatus(gate?.status)) {
    const color = getGateStatusTagColor(gate.status)
    return (
      <Tag color={color}>
        {gate.status}
      </Tag>
    )
  }
  const color = getGateStatusTagColor(GateStatus.OFFLINE)
  return (
    <Tag color={color}>
      OFFLINE
    </Tag>
  )
}
function LocationTouchscreenList (): ReactElement {
  const navigate = useNavigate()

  const { locationId } = useParams()
  const touchscreensQuery = useApi(getLocationTouchscreens)
  const locationQuery = useApi(getLocation)
  const deleteQuery = useApi(deleteTouchscreen)

  const touchscreenData = touchscreensQuery.getResponse()
  const locationData = locationQuery.getResponse()
  const modal = useModal()
  const [screenForRemoval, setScreenForRemoval] = useState<TouchscreenConfig>()

  useEffect(() => {
    if (locationId) {
      touchscreensQuery.sendRequest({ location: locationId })
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      touchscreensQuery.cleanup()
      locationQuery.cleanup()
      deleteQuery.cleanup()
    }
  }, [])

  const confirmRemoval = (touchscreen: RowData) => {
    setScreenForRemoval(touchscreen as TouchscreenConfig)
    modal.show()
  }

  const removeTouchscreen = () => {
    if (screenForRemoval) {
      deleteQuery.sendRequest({
        touchscreenId: screenForRemoval.id,
      }, () => {
        touchscreensQuery.setData((oldData: any) => deleteFromPaginatedWithId(oldData, screenForRemoval.id))
      })
    }
  }

  const renderTouchscreenActions = (screen: RowData): ReactElement => {
    const actions = [
      {
        label: 'Delete',
        action: () => confirmRemoval(screen),
      },
    ]
    return (
      <ActionMenu actions={actions} />
    )
  }

  const renderType = (touchscreen: RowData): ReactElement => (
    <TagGroup>
      <Tag>{touchscreen.screenType ?? 'Invalid Type'}</Tag>
    </TagGroup>
  )

  // as correct rendering is dependent on location data, wait its availability before render
  const touchscreens = (locationData.data && touchscreenData.data?.items) || []

  /**
   * Using the location query data retrieve the camera group name
   */
  const formatCameraGroup = (screen: RowData): string => {
    if (screen.screenType === ScreenType.KIOSK) return ''
    const cameraGroups = locationData.data?.cameraGroups || null
    const foundGroup = cameraGroups?.find((group: CameraGroup) => group.id === screen.gate)
    return foundGroup ? foundGroup.name : 'Invalid Group'
  }

  return (
    <>
      <Panel
        className="gates-list location-panel"
        header={(
          <PanelHeader
            header="Touchscreens"
            onEdit={() => navigate('touchscreens/new')}
            icon={<Icon as={FaPlus} />}
          />
        )}
      >
        <Table
          autoHeight
          data={touchscreens}
          loading={touchscreenData.loading}
        >
          <Column flexGrow={1}>
            <HeaderCell>Touchscreen name</HeaderCell>
            <Cell dataKey="name">
              {(rowData) => (
                <Link to={`touchscreens/${rowData.id}`}>
                  {rowData.name}
                </Link>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Screen Address</HeaderCell>
            <TableData dataKey="ip" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Type</HeaderCell>
            <TableData dataKey="screenType" content={renderType} />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Screen version</HeaderCell>
            <Cell dataKey="versions">
              {() => (
                'v0.0.1'
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Gate</HeaderCell>
            <Cell dataKey="gate">
              {(rowData) => (
                formatCameraGroup(rowData)
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <TableData dataKey="status" content={renderStatus} />
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>Actions</HeaderCell>
            <TableData dataKey="action" content={renderTouchscreenActions} />
          </Column>
        </Table>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`The ${screenForRemoval?.name} touchscreen will be removed from this location.`}
          onConfirm={removeTouchscreen}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  LocationTouchscreenList
}
