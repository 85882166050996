import { PricingType } from './enums'
import { Plan, Pricing } from './pricing'

/**
 *  Checks that pricing is of type plan
 *  @param {Pricing} pricing - the item to check
 *  @return {Boolean}
 */
function pricingIsPlan (pricing?: Pricing): pricing is Plan {
  return pricing?.type === PricingType.PLAN
}

export {
  pricingIsPlan
}
