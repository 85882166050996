import type { PaginatedResponse, Query } from '../../../types/api'
import { createQuery } from '../helpers'
import {
  CreateTouchscreenParams,
  GetLocationTouchscreenParam,
  TouchscreenConfig, UpdateTouchscreenParams
} from '../../../types/touchscreen'

const returnProps = `
  id
  name
  gate
  screenType
  ip
  location
`

// eslint-disable-next-line max-len
const getLocationTouchscreens: Query<PaginatedResponse<TouchscreenConfig>, GetLocationTouchscreenParam> = createQuery(`
  query getLocationTouchscreens($location: ID!, $limit: Int, $next: String) {
    getLocationTouchscreens (location: $location, limit: $limit, next: $next) { 
      items {
        ${returnProps} 
      }
      next
      limit
    }
  }
`)

const deleteTouchscreen: Query<void, { touchscreenId: string }> = createQuery(`
  mutation removeTouchscreen($touchscreenId: ID!) {
    removeTouchscreen(id: $touchscreenId)
  }
`)

const getTouchscreen: Query<TouchscreenConfig, { touchscreenId: string }> = createQuery(`
  query getTouchscreen($touchscreenId: ID!) {
    getTouchscreen (id: $touchscreenId) { ${returnProps} }
  }
`)

const createTouchscreen: Query<CreateTouchscreenParams> = createQuery(`
  mutation createTouchscreen(
    $location: ID!,
    $name: String!,
    $screenType: ScreenType,
    $ip: String!,
    $gate: ID,
  ) {
    createTouchscreen(
      location: $location,
      name: $name,
      screenType: $screenType,
      ip: $ip,
      gate: $gate,
    ) { ${returnProps} }
  }
`)

const updateTouchscreen: Query<UpdateTouchscreenParams> = createQuery(`
  mutation editTouchscreen(
    $id: ID!,
    $name: String,
    $screenType: ScreenType,
    $ip: String,
    $gate: ID,
  ) {
    editTouchscreen(
      id: $id,
      name: $name,
      screenType: $screenType,
      ip: $ip,
      gate: $gate,
    ) { ${returnProps} }
  }
`)

export {
  getLocationTouchscreens,
  deleteTouchscreen,
  getTouchscreen,
  createTouchscreen,
  updateTouchscreen
}
