import React, { ReactElement, useMemo } from 'react'
import {
  Panel, Table
} from 'rsuite'

import {
  usePageTitle, useUser, usePaginatedApi
} from '../../app/hooks'
import {
  getUserPayments
} from '../../services/graphql/queries'
import { PaginationControls, TableData } from '../../components'
import type { RowData } from '../../types/table'

import './PaymentHistoryList.css'

/**
 * payment history list component
 * @return {ReactElement}
 */
function PaymentHistoryList (): ReactElement|null {
  usePageTitle('Payments history')

  const user = useUser()

  const paymentsQuery = usePaginatedApi({
    query: getUserPayments,
    fetchParams: { userId: user.id },
  })

  /**
   * Render the product description for a payment
   * @param {RowData} payment
   * @return {string}
   */
  const renderProduct = (payment: RowData): string => (
    `${payment?.product.plan?.name ?? ''}
    (${payment?.location.name})`
  )

  /**
   * Render the product description for a payment
   * @param {RowData} payment
   * @return {string}
   */
  const renderAmount = (payment: RowData): string => (
    `${Number(payment.amount) + Number(payment.surcharge)}`
  )

  const paymentsTableData = useMemo(
    () => paymentsQuery.currentPage ?? [],
    [paymentsQuery.currentPage]
  )

  return (
    <Panel header={<h2>Payments history</h2>}>
      <Table
        autoHeight
        data={paymentsTableData}
        loading={paymentsQuery.loading}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <TableData dataKey="amount" content={renderAmount} format="money" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <TableData dataKey="processed" format="date" />
        </Table.Column>
        <Table.Column flexGrow={3}>
          <Table.HeaderCell>Product</Table.HeaderCell>
          <TableData dataKey="product" content={renderProduct} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <TableData dataKey="status" />
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls
          nextPage={paymentsQuery.next || undefined}
          prevPage={paymentsQuery.prev || undefined}
        />
      </div>
    </Panel>
  )
}

export {
  PaymentHistoryList
}
