import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { VoucherList } from '../../features/vouchers/VoucherList'
import { VoucherDetails } from '../../features/vouchers/VoucherDetails'
import { NotFound } from '../../features/error-pages/NotFound'
import VoucherEdit from '../../features/vouchers/VoucherEdit'

function VouchersPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN]}>
      <Routes>
        <Route path="/" element={<VoucherList />} />
        <Route path="/new" element={<VoucherEdit />} />
        <Route path="/:id" element={<VoucherDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RequireRole>
  )
}

export {
  VouchersPage
}
