import { PayloadAction } from '@reduxjs/toolkit'

import { ApiState, initialResponseState } from './slice'
import { StoreStatus } from '../../types/enums'
import type { ApiError } from '../../types/api'

/**
 * Prepare a place in the API state object for a single request
 * @param {ApiState} state
 * @param {string} id - The ID of the request to setup
 */
const setup = (state: ApiState, id: string) => {
  if (!state[id]) {
    state[id] = { ...initialResponseState }
  }
}

/**
 * Remove a single request from the API state object
 * @param {ApiState} state
 * @param {string} id - The ID of the request to remove
 */
const teardown = (state: ApiState, id: string) => {
  if (state[id]) {
    delete state[id]
  }
}

const setLoading = (state: ApiState, action: PayloadAction<string>): void => {
  const { payload: id } = action
  setup(state, id)
  state[id].status = StoreStatus.LOADING
  state[id].sent = true
}

const setFailed = (state: ApiState, action: PayloadAction<{ id: string, error: ApiError }>): void => {
  const { id, error } = action.payload
  setup(state, id)
  state[id].error = error
  state[id].status = StoreStatus.FAILED
}

const recieveResponse = <T>(
  state: ApiState,
  action: PayloadAction<{ id: string, data: T}>
): void => {
  const { id, data } = action.payload
  setup(state, id)
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      state[id].data = [...data]
    } else if (data === null) {
      state[id].data = data
    } else {
      state[id].data = { ...data }
    }
  } else {
    state[id].data = data
  }
  state[id].status = StoreStatus.IDLE
}

const reset = (state: ApiState, action: PayloadAction<string>): void => {
  const { payload: id } = action
  state[id] = { ...initialResponseState }
}

const clear = (state: ApiState, action: PayloadAction<string>): void => {
  const { payload: id } = action
  teardown(state, id)
}

const setData = <T={}>(state: ApiState, action: PayloadAction<{id:string, data:T}>) => {
  const { id, data } = action.payload
  setup(state, id)
  state[id].data = Array.isArray(data) ? [...data] : { ...data }
}

const clearAll = (state: ApiState): void => {
  Object.keys(state).forEach((id) => teardown(state, id))
}

const addItem = <T>(state: ApiState, action: PayloadAction<{ id: string, data: T }>): void => {
  const { id, data } = action.payload
  setup(state, id)
  state[id].data.push(data)
}

const removeItem = <T extends {id: string}>(
  state: ApiState,
  action: PayloadAction<{ id: string, data: T }>
): ApiState => {
  const { id, data } = action.payload
  setup(state, id)
  if (Array.isArray(state[id].data)) {
    return {
      ...state,
      [id]: {
        ...state[id],
        data: state[id].data.filter((item: any) => item.id !== data.id),
      },
    }
  }

  return state
}

const replaceItem = () => {
  throw new Error('Not implemented')
}

export {
  setLoading,
  setFailed,
  recieveResponse,
  reset,
  clear,
  setData,
  clearAll,
  addItem,
  removeItem,
  replaceItem
}
