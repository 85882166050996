import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * A hook for performing an action when the page location changes
 * @param {function} callback - The function to run
 */
const useNavigateAction = (callback: Function) => {
  const location = useLocation()
  const reference = useRef(location)
  useEffect(() => {
    if (location !== reference.current) {
      callback()
    }
  }, [location])
}

export {
  useNavigateAction
}
