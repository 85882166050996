import React, { ReactElement } from 'react'
import { Tag } from 'rsuite'

import packageInfo from '../../../package.json'

import './VersionInfo.css'

/**
 * Display the platform version number
 * @return {ReactElement}
 */
function VersionInfo (): ReactElement {
  return (
    <div className="version-info">
      <Tag>{`v${packageInfo.version}`}</Tag>
    </div>
  )
}

export {
  VersionInfo
}
