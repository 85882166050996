import { Form, Formik, FormikErrors } from 'formik'
import React from 'react'

import { CheckPicker, Input } from '@rsuite/formik'

import { Panel } from 'rsuite'

import FieldWithFormik from './formik-fields/FieldWithFormik'
import { usePaginatedApi } from '../app/hooks'
import { getUsersWithoutFlags } from '../services/graphql/queries'
import type { FormProps } from '../types/form'

interface FormValues {
  name: string;
  description: string;
  users: string[];
}

function validate (values: FormValues) {
  const errors: FormikErrors<FormValues> = {}

  if (!values.name) {
    errors.name = 'All groups must have a name.'
  }

  return errors
}

function LocationGroupForm ({ onSubmit, formId, formValue }: FormProps) {
  const usersQuery = usePaginatedApi({
    query: getUsersWithoutFlags,
    fetchParams: {},
  })

  const users = usersQuery.currentPage || []

  /**
   *  Resets the query data and makes a new request with
   *  the search string in the search bar
   *  @param {String?} query          - The search string
   */
  const performSearch = (query: string | null) => {
    usersQuery.reset()
    const hook = usersQuery.apiHook
    hook.sendRequest({
      query: query ?? undefined,
    })
  }
  /**
       *  Cb when the search button is clicked
       */
  const searchUsers = (searchKeyword: string) => {
    performSearch(searchKeyword)
  }

  return (
    <Formik
      validate={validate}
      initialValues={{
        name: formValue?.name ?? '',
        description: formValue?.description ?? '',
        users: formValue?.users ?? [],
      }}
      onSubmit={(values, actions) => {
        const errs = validate(values)
        if (Object.keys(errs).length === 0) {
          setTimeout(() => {
            onSubmit(values)
            actions.setSubmitting(false)
          }, 1000)
        }
      }}
    >
      {
        (form) => (
          <Form className="rs-form-group noover" id={formId}>
            <Panel header="Group details">
              <FieldWithFormik
                errors={form.errors}
                touched={form.touched}
                required
                name="name"
                label="Enter a unique name for the group:"
                component={Input}
              />
              <FieldWithFormik
                errors={form.errors}
                touched={form.touched}
                name="description"
                label="Enter a description for the group:"
                component={Input}
              />
            </Panel>
            <Panel header="Assign members and subscribers to the group">
              <FieldWithFormik
                errors={form.errors}
                touched={form.touched}
                name="users"
                label="Add or remove users from the group:"
                data={users.map((user) => ({ label: user.email, value: user.id }))}
                loading={usersQuery.loading}
                onSearch={searchUsers}
                component={CheckPicker}
                sticky
                sort={() => (a: any, b: any) => {
                  const labelA = a.label.toUpperCase() // ignore upper and lowercase
                  const labelB = b.label.toUpperCase() // ignore upper and lowercase
                  if (labelA < labelB) {
                    return -1
                  }
                  if (labelA > labelB) {
                    return 1
                  }

                  // names must be equal
                  return 0
                }}
              />
            </Panel>
          </Form>
        )
      }
    </Formik>
  )
}

export { LocationGroupForm }
