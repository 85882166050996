import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useModal, usePageTitle } from '../../app/hooks'
import { useAdminDashboardData } from '../../app/hooks/adminDashboard'
import { CenteredLoader, Modal } from '../../components'
import { AdminDashboard } from '../../features/admin-dashboard/AdminDashboard'
import { MissingPaymentMethodModal } from '../../features/account/MissingPaymentMethodModal'
import { useFeatureFlag } from '../../app/hooks/featureFlag'

/**
 * Dashboard route
 * @return {ReactElement}
 */
function Dashboard (): ReactElement {
  usePageTitle('Dashboard')

  const data = useAdminDashboardData()
  const navigate = useNavigate()

  const loading = !data.sent || data.loading

  const modal = useModal()

  const addLocationCb = (id: string) => {
    navigate(`/admin/location/${id}`)
  }
  const { enabled: showSubscriptionBanner } = useFeatureFlag('showSubscriptionBanner')

  return (
    <>
      { loading ? <CenteredLoader /> : (
        <AdminDashboard
          showSubscriptionHeader={showSubscriptionBanner}
          addLocationCb={addLocationCb}
          data={data.data}
        />
      )}
      <Modal hook={modal}>
        <MissingPaymentMethodModal onClose={modal.hide} />
      </Modal>
    </>
  )
}

export {
  Dashboard
}
