import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { useUser } from '../../app/hooks'

/**
 * Banner alert to remind users that their email has not been verified.
 * @return {ReactElement}
 */
export function EmailVerficationReminder (): null {
  const user = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !location.pathname.startsWith('/account/verify-email')
      && !user.emailVerified
    ) {
      const message = 'Verify your email to complete your account'
      toast.info('Email not verified', {
        description: message,
        duration: 10000,
        className: 'highlight',
        cancel: {
          label: 'Verify',
          onClick: () => navigate('/account/verify-email'),
        },
      })
    }
  }, [user.emailVerified])

  return null
}
