import React, { ReactElement, useEffect } from 'react'
import { Panel } from 'rsuite'

import { useApi, useFormState, usePageTitle } from '../../app/hooks'
import { PanelHeader } from '../../components'
import { OrganisationForm } from '../../forms/OrganisationForm'
import { Organisation } from '../../types/organisation'
import { updateOrganisation } from '../../services/graphql/queries'

type EditOrganisationDetailsFormData = {
  location: string,
  name: string,
  description: string,
  spaces: number,
}

type Props = {
  organisation: Organisation
  onFinish : () => void
}

/**
 * Component to edit a organisation
 * @param {Props} props
 * @param {() => void} props.onFinish - Cb when editing is finished
 * @param {Organisation} props.organisation - Organisation object to edit
 * @returns {ReactElement}
 */
function OrganisationEdit (props:Props): ReactElement {
  const { onFinish, organisation } = props

  const editOrganisationApi = useApi(updateOrganisation)
  const editResponse = editOrganisationApi.getResponse()
  const responseData = editResponse.data
  const isLoading = editResponse.loading
  const { error } = editResponse

  usePageTitle('Edit organisation')

  useEffect(() => {
    if (responseData !== null) {
      onFinish()
    }
    return editOrganisationApi.cleanup
  }, [responseData])

  const [formData, setField] = useFormState<EditOrganisationDetailsFormData>({
    name: organisation.name,
    description: organisation.description,
    spaces: organisation.spaces,
    location: organisation.location,
  })

  const onFormSubmit = () => {
    const requestParams = {
      organisationId: organisation.id,
      name: formData.name,
      location: formData.location,
      description: formData.description,
      spaces: formData.spaces,
    }
    editOrganisationApi.sendRequest(requestParams)
  }

  const formId = 'organisation-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          editing
          formId={formId}
          onCancel={onFinish}
          header="Edit organisation"
          loading={isLoading}
        />
      )}
    >
      <OrganisationForm
        formId={formId}
        formValue={formData}
        onChange={setField}
        onSubmit={onFormSubmit}
        error={error}
        mode="edit"
      />
    </Panel>
  )
}

export { OrganisationEdit }
