import React from 'react'
import dayjs from 'dayjs'
import { Button, Panel } from 'rsuite'

import { InvoiceDTO } from '../types/payment'
import { DefinitionList } from './DefinitionList'
import { InvoiceTable } from './InvoiceTable'

import './Invoice.css'
import { NooverLogo } from './icons/NooverLogo'

type InvoiceProps = {
  invoice: InvoiceDTO,
}

/**
 * Component which which displays an invoice
 * @param {InvoiceProps} props
 * @return {React.ReactElement}
 */
export function Invoice (props:InvoiceProps) : React.ReactElement {
  const { invoice } = props
  const billingDate = dayjs(invoice.billingDate)
  const billingDateStr = billingDate.format('DD/MM/YYYY')
  const details = {
    ABN: '57 664 215 565',
    Address: 'Level 5/81 Elizabeth St, Hobart TAS 7000',
    Date: billingDateStr,
  }

  const summary = {
    Total: `$${invoice.total}`,
    Paid: `$${invoice.amountPaid}`,
    Outstanding: `$${invoice.outstanding}`,
  }

  return (
    <div className="noover-invoice">
      <Panel>
        <div className="invoice-header">
          <div className="left">
            <NooverLogo />
          </div>
          <div className="right">
            <div className="details"><DefinitionList data={details} /></div>
          </div>
        </div>
        <div className="items">
          <InvoiceTable items={invoice.items} />
        </div>
        <div className="summary">
          <DefinitionList data={summary} />
          {
            invoice.isCancelled && <div className="cancelled">Invoice cancelled</div>
          }
        </div>
        <div className="printbutton">
          <Button appearance="primary" onClick={() => { window.print() }}>Print</Button>
        </div>
      </Panel>
    </div>
  )
}
