import React, { ChangeEvent, ReactElement } from 'react'
import {
  Checkbox, CheckboxProps, Form, FormControlProps
} from 'rsuite'
import { FormControlBaseProps } from 'rsuite/esm/internals/types'
import { ValueType } from 'rsuite/esm/Checkbox'

import './FormControlCheckbox.css'

type InnerCheckboxProps = {
  formControlBaseProps: FormControlBaseProps,
  checkboxProps?: CheckboxProps,
  checkboxChildren?: React.ReactNode,
}

/**
 * The acceptor component of FormControlCheckbox
 * @param {InnerCheckboxProps} props
 * @param {FormControlBaseProps} props.formControlBaseProps - Props passed by the FormControl component
 * @param {CheckboxProps} props.checkboxProps - Props passed into the Checkbox component
 * @param {React.ReactNode} props.checkboxChildren - The children of the Checkbox component
 * @return {ReactElement}
 */
function InnerCheckbox (props: InnerCheckboxProps): ReactElement {
  const { formControlBaseProps, checkboxProps, checkboxChildren } = props
  const { onChange: onFormChange, value } = formControlBaseProps

  /**
   * Cb for when checkbox has been checked or unchecked
   * @param checkboxGroupValue - The given value of the Checkbox within a CheckboxGroup
   * @param checked - Whether the checkbox is checked
   * @param event - Generated event object by the Checkbox
   */
  const localHandleCheck = (
    checkboxGroupValue: ValueType | undefined,
    checked: boolean,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    onFormChange?.(checked ? 1 : 0, event)
    checkboxProps?.onChange?.(checkboxGroupValue, checked, event)
  }

  return (
    <div className="form-control-checkbox">
      <Checkbox
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...checkboxProps}
        onChange={localHandleCheck}
        checked={value === 1}
      >
        {checkboxChildren}
      </Checkbox>
    </div>
  )
}

type Props = {
  formControlProps: FormControlProps,
  checkboxProps?: CheckboxProps,
  children?: React.ReactNode
}

/**
 *  A wrapper for placing a Rsuite Checkbox in a FormControl
 *
 *  A FormControl component passes an onChange cb into its acceptor.
 *  This onChange cb takes in only 2 parameters, value and target.
 *  However, the onChange property on a Rsuite Checkbox recieves a cb with 3 parameters
 *  value, checked and event. Value is always undefined when Checkbox
 *  is used outside a CheckboxGroup. The checked parameter is the boolean
 *  indicating whether the box is checked. This wrapper takes this checked parameter,
 *  converts it into either a 1 or 0, then passes it as the first argument of
 *  the onChange cb passed by the FormControl.
 * @param {Props} props
 * @param {CheckboxProps} props.checkboxProps - The props passed to the wrapped Checkbox component
 * @param {FormControlProps} props.formControlProps - The props passed to the FormControl component
 * @param {React.ReactNode} props.children - Any children of the wrapped Checkbox component
 * @return {ReactElement}
 */
function FormControlCheckbox (props: Props): ReactElement {
  const { checkboxProps, formControlProps, children } = props

  return (
    <Form.Control
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formControlProps}
      // eslint-disable-next-line react/no-unstable-nested-components
      accepter={(controlProps: FormControlBaseProps) => (
        <InnerCheckbox
          formControlBaseProps={controlProps}
          checkboxProps={checkboxProps}
          checkboxChildren={children}
        />
      )}
    />
  )
}

export {
  FormControlCheckbox
}
