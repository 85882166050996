import { createSlice } from '@reduxjs/toolkit'

import * as reducers from './reducers'
import * as selectors from './selectors'
import * as thunks from './thunks'
import { StoreStatus } from '../../types/enums'
import type { ApiError } from '../../types/api'

export type ApiResponseState<Type> = {
  data: Type|null,
  status: StoreStatus,
  error: ApiError|null,
  sent: boolean,
}

export type ApiState = {
  [key: string]: ApiResponseState<any>
}

export const initialResponseState: ApiResponseState<any> = {
  status: StoreStatus.LOADING,
  data: null,
  error: null,
  sent: false,
}

const initialState: ApiState = {}

const slice = createSlice({
  name: 'api',
  initialState,
  reducers,
})

const apiActions = { ...slice.actions, ...thunks }
const apiReducer = slice.reducer
const apiSelectors = selectors

export {
  apiActions,
  apiReducer,
  apiSelectors
}
