import React, { ReactElement } from 'react'
import {
  Modal, Button, ButtonToolbar, Notification, useToaster, Loader
} from 'rsuite'

import { useApi } from '../app/hooks'

import { usePaginatedApi } from '../app/hooks/paginatedApi'

import { disableUser, getSubscriptions } from '../services/graphql/queries'
import { formatUserDisplayName } from '../features/users/userHelpers'

export type Props = {
  user: any | null,
  header?: string,
  onConfirm?: () => void,
  onClose: () => void,
}

/**
 * Contirmation modal component
 * @return {ReactElement}
 */
function DeleteUserModal (props: Props): ReactElement {
  const toaster = useToaster()

  const {
    user,
    header = 'Delete user',
    onConfirm = () => {},
    onClose = () => {},
  } = props

  const disableUserQuery = useApi(disableUser)
  const subscriptionQuery = usePaginatedApi({
    query: getSubscriptions,
    fetchParams: { userId: user?.id || '' },
  })

  /**
   * Close the modal
   */
  const close = () => {
    onClose()
  }

  const message = (
    <Notification type="error" header="Error" closable>
      Unable to delete&nbsp;
      {formatUserDisplayName(user)}
      , please try again or contact support.
    </Notification>
  )

  /**
   * Run the confirm action and close the modal
   */
  const confirm = async () => {
    if (!user) {
      toaster.push(message, { placement: 'bottomStart', duration: 5000 })
      return
    }
    await disableUserQuery.sendRequest({ id: user?.id })
    onConfirm()
    onClose()
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(subscriptionQuery.loading) && (
          <Loader>
            {' '}
            Loading...
          </Loader>
        )}
        { !subscriptionQuery.loading && (subscriptionQuery.currentPage?.length || 0) > 0
          ? (
            <div>
              {formatUserDisplayName(user)}
              &nbsp;has subscriptions. Cancel the user&apos;s subscription before deleting.
            </div>
          )
          : !subscriptionQuery.loading
            && (
              <div>
                Are you sure you want to delete&nbsp;
                {formatUserDisplayName(user)}
                ?
              </div>
            )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          {
            (subscriptionQuery.currentPage?.length || 0) !== 0
              ? <Button onClick={confirm} appearance="primary" disabled>Confirm</Button>
              : !subscriptionQuery.loading && <Button onClick={confirm} appearance="primary">Confirm</Button>
          }
          <Button onClick={close} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  DeleteUserModal
}
