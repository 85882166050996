/* eslint  no-redeclare: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint react/jsx-props-no-spreading: 0 */

import React from 'react'

import { FieldProps } from 'formik'

export interface RSuiteStyleControlledComponentProps {
  name?: string;
  value?: any;
  onChange?: (newValue: any, ...args: any[]) => void;
}

function formikCompatible<P extends RSuiteStyleControlledComponentProps, WP = FieldProps & P>(
  Component: React.ComponentType<P>
): React.ComponentType<WP>

function formikCompatible (Component: React.ComponentType<any>): any {
  function WrappedComponent ({
    field,
    meta,
    form,
    onChange,
    ...props
  }: FieldProps & RSuiteStyleControlledComponentProps) {
    return (
      <Component
        {...field}
        onChange={(newValue: any, ...args: any[]) => {
          form.setFieldValue(field.name, newValue)
          onChange?.(newValue, ...args)
        }}
        {...props}
      />
    )
  }
  WrappedComponent.displayName = `formikCompatible(${Component.displayName ?? Component.name})`
  return WrappedComponent
}

export default formikCompatible
