/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react'

import moment from 'moment-timezone'
import humanizeDuration from 'humanize-duration'

import './DashboardCarparkCard.css'
import { Tag, TagGroup } from 'rsuite'

import { SparkLineChart } from '@mui/x-charts/SparkLineChart'

import { ActionMenu } from './ActionMenu'
import { ItemActions } from '../types/actions'

/**
 * Prop type for {@link DashboardProfileCard}
 */
type DashboardCarparkCardProps = {
  name: string,
  status: string,
  lastSync: string,
  capacityAvailable: number,
  capacityTotal: number,
  occupancy: any[],
  location: any,
  navigate: any,
}

/**
   * Render a status cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
const renderStatus = (status: string): ReactElement => {
  if (status === 'ACTIVE') {
    return (
      <TagGroup>
        <Tag color="green">ONLINE</Tag>
      </TagGroup>
    )
  }
  return (
    <TagGroup>
      <Tag color="red">{status}</Tag>
    </TagGroup>
  )
}

const getUsageData = (val: any): any[] => {
  const data: any[] = []
  const v = [...val]
  v.sort((a: any, b: any) => (((a.day + a.hour) > (b.day + b.hour)) ? 1 : -1))
  v.forEach((e: any) => {
    data.push(e.allowed + e.casual + e.subscription)
  })
  return data
}

const hasUsageData = (val: any): boolean => {
  let cnt = 0
  val.forEach((e: any) => {
    cnt += e.allowed + e.casual + e.subscription
  })
  return cnt > 0
}

const renderActions = (location: any, navigate: any): ReactElement => {
  const actions: ItemActions = []
  actions.push({ label: 'View Carpark', action: () => navigate(`locations/${location.id}`) })
  actions.push({ label: 'View Occupancy', action: () => navigate(`locations/${location.id}/occupancy`) })
  return (
    <ActionMenu actions={actions} />
  )
}

const getLastSync = (val: string): string => {
  const entry = moment.tz(val, 'utc')
  const current = moment()
  const ms = moment.duration(current.diff(entry)).asMilliseconds()
  const duration = humanizeDuration(
    ms,
    {
      units: ['d', 'h', 'm'],
      conjunction: ' and ',
      serialComma: false,
      maxDecimalPoints: 0,
    }
  )
  return duration
}

const renderLastSync = (val: string): string => (
  val ? getLastSync(val) : '-'
)

const palette = ['#19d8c8']
const paletteOffline = ['#ffabab']

/**
 *  Card component used to display the steps user needs to setup their account
 */
function DashboardCarparkCard (props: DashboardCarparkCardProps): React.ReactElement {
  const {
    name, status, lastSync, capacityAvailable, capacityTotal, occupancy, location, navigate,
  } = props

  const okClass = status === 'ACTIVE' ? '-active' : '-offline'
  return (
    <div
      className={`dashboard-carpark-card ${okClass}`}
      style={{ position: 'relative' }}
    >
      <div className="title">
        {name}
      </div>
      <div style={{ position: 'absolute', right: '24px' }}>
        {renderActions(location, navigate)}
      </div>
      <div style={{ position: 'absolute', right: '64px' }}>
        {renderStatus(status)}
      </div>
      <div className="subtitle" style={{ marginBottom: '12px' }}>
        Last online:
        &nbsp;
        {renderLastSync(lastSync)}
      </div>
      <div className="dashboard-carpark-content">
        <div className="subtitle">
          { hasUsageData(occupancy) ? (
            <div>
              <div className="dashboard-carpark-usage-title" style={{ marginBottom: '8px' }}>
                <b>
                  Visits 24 hours
                </b>
              </div>
              <SparkLineChart
                data={getUsageData(occupancy)}
                height={80}
                colors={status !== 'OFFLINE' ? palette : paletteOffline}
                curve="catmullRom"
                area
                showHighlight
                showTooltip
              />
            </div>
          )
            : ''}
        </div>
        <div className="subtitle">
          <div className="dashboard-carpark-capacity-title">
            <b>Capacity</b>
          </div>
          <div style={{ marginLeft: '28px' }}>
            <table style={{ width: '80%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '50%' }}>Used:</td>
                  <td style={{ textAlign: 'right' }}>
                    {capacityTotal - capacityAvailable}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>Available:</td>
                  <td style={{ textAlign: 'right' }}>
                    {capacityAvailable}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>Total:</td>
                  <td style={{ textAlign: 'right' }}>
                    {capacityTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="profile-card-complete-tick">
         &nbsp;
        </div>
      </div>
    </div>
  )
}

export {
  DashboardCarparkCard,
  type DashboardCarparkCardProps
}
