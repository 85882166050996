/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  Panel, SelectPicker
} from 'rsuite'

import { UserRole } from '../../types/enums'
import { RequireRole } from '../access/RequireRole'

import { SubscribersReport } from './SubscribersReport'
import { MembersReport } from './MembersReport'

const reportdata = ['Members', 'Subscribers'].map(
  (item) => ({ label: item, value: item })
)

function AdminUserReportsDetails () {
  const [report, setReport] = React.useState(null)

  // eslint-disable-next-line react/jsx-filename-extension
  let component = <div style={{ margin: 20 }}>No report selected</div>
  if (report === 'Members') {
    component = <MembersReport />
  }
  if (report === 'Subscribers') {
    component = <SubscribersReport />
  }

  return (
    <RequireRole roles={[UserRole.ORGANISATION, UserRole.ADMIN]}>
      <Panel header={(<h2>User reports</h2>)}>
        <div style={{ width: 200, marginBottom: 12 }}>
          Select a report:
          <SelectPicker onChange={setReport} value={report} data={reportdata as any} block />
        </div>
        <hr />

        {component}

      </Panel>
    </RequireRole>
  )
}

export {
  AdminUserReportsDetails
}
