import React, { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EventDetailIcon from '@rsuite/icons/EventDetail'

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  startOfDay,
  endOfDay
} from 'date-fns'

import './LocationTariffTesting.css'

import {
  Formik, Form, FormikErrors
} from 'formik'
import {
  Panel, Divider, Grid, Row, Col, Message, Stack, Loader
} from 'rsuite'
import { SelectPicker } from '@rsuite/formik'

import FieldWithFormik from '../../forms/formik-fields/FieldWithFormik'
import { useApi, usePageTitle, useUser } from '../../app/hooks'
import {
  getOrganisations,
  getLocationGroups,
  getTariffConfig,
  getTestTariff,
  getLocation
} from '../../services/graphql/queries'
import { PanelHeader } from '../../components'

import { TestTariffParams, TestTariffResult } from '../../types/tariff'
import { DatePicker } from '../../forms/formik-fields/DatePicker'

interface FormValues {
  locationId: string;
  tariffId: string;
  entryDateTime: Date | null;
  exitDateTime: Date | null;
  group: string | null;
  organisation: string | null;
  cameraGroup: string | null;
}

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {}
  // validate the form entryTime is before the exitTime
  if (values.entryDateTime && values.exitDateTime && values.entryDateTime > values.exitDateTime) {
    errors.entryDateTime = 'Entry must be before exit'
    errors.exitDateTime = 'Exit must be after time'
  }
  // validate all fields are filled
  if (!values.entryDateTime) {
    errors.entryDateTime = 'Entry date and time is required'
  }
  if (!values.exitDateTime) {
    errors.exitDateTime = 'Exit date and time is required'
  }

  return errors
}

type TariffIconProps = {
  size: string
}

function TariffIcon (props: TariffIconProps): ReactElement {
  const { size } = props
  return <EventDetailIcon style={{ fontSize: size, marginRight: 10 }} />
}

function TestResults ({ values }: { values: TestTariffResult | null }): ReactElement {
  const isMissing = values?.id === null
  const isError = !isMissing && ((values?.status || '').length > 0)
  return (
    <>
      <Divider style={{ margin: '0 0 40px 0' }} />
      <div style={{ marginBottom: 10 }}>
        {
          isError && (
            <Message showIcon type="error">
              {values?.status}
            </Message>
          )
        }
        {
          isMissing && (
            <Message showIcon type="error">
              Error: Conditions not met for tariff scheme
            </Message>
          )
        }
      </div>
      {
        !isError && !isMissing && (
          <Panel header="Tariff results that would be applied:">
            <Row>
              <Col md={6} sm={12}>
                <Panel
                  style={{ padding: '20px 5px', textTransform: 'capitalize' }}
                  bordered
                  header="Rate type:"
                >
                  { values?.tariffRateType }
                  &nbsp; - &nbsp;
                  { values?.invoiceType }
                </Panel>
              </Col>
              <Col md={6} sm={12}>
                <Panel style={{ padding: '20px 5px' }} bordered header="Total time:">
                  { values?.time }
                </Panel>
              </Col>
              <Col md={6} sm={12}>
                <Panel style={{ padding: '20px 5px' }} bordered header="Total charge:">
                  { values?.charge }
                </Panel>
              </Col>
            </Row>
          </Panel>
        )
      }
    </>
  )
}

function LocationTariffTesting (): ReactElement {
  const navigate = useNavigate()
  const user = useUser()
  const { locationId, tariffId } = useParams()
  const pageTitle = tariffId ? 'Test tariff' : 'Test tariff'
  usePageTitle(pageTitle)

  const tariffApi = useApi(getTariffConfig)
  const testTariffApi = useApi(getTestTariff)
  const tariffData = tariffApi.getResponse()
  const initialValues = tariffData?.data ?? {
    name: '', organisation: null, group: null, cameraGroup: null,
  }

  const organisationApi = useApi(getOrganisations)
  const organisationData = organisationApi.getResponse()

  const organisationGroups: {label: string, value: string, role: string}[] = []

  if (organisationData.success) {
    organisationData.data?.items.forEach((org: any) => {
      if (org.groups) {
        const groups = JSON.parse(org.groups)
        groups.forEach((g: any) => {
          organisationGroups.push({ label: g.name, value: `${org.id}:${g.id}`, role: org.name })
        })
      }
    })
  }

  const groupApi = useApi(getLocationGroups)
  const groupData = groupApi.getResponse()
  const groups = groupData.success
    ? groupData.data?.items.map((grp: any) => ({ label: grp.name, value: grp.id })) ?? [] : []

  const locationQuery = useApi(getLocation)
  const locationData = locationQuery.getResponse()

  React.useEffect(() => {
    organisationApi.sendRequest()
    if (locationId) {
      groupApi.sendRequest({ locationId })
      locationQuery.sendRequest({ locationId })
    }
    if (tariffId) {
      tariffApi.sendRequest({ tariffId })
    }
    return () => {
      organisationApi.cleanup()
      groupApi.cleanup()
      tariffApi.cleanup()
      locationQuery.cleanup()
    }
  }, [])

  const cameraGroups = React.useMemo(
    () => {
      if (!locationData.data || !locationData.data.cameraGroups) {
        return []
      }
      return locationData.data.cameraGroups.map(({ name }) => ({ label: name, value: name }))
    },
    [locationData]
  )

  /**
   * Return to the location list screen
   */
  const cancel = () => {
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
  }

  /**
   * Review cb when user clicks either reject or accept
   * @param {ReviewSubscriptionAction} action - The desired action to take with the review
   */
  const testCb = (params: TestTariffParams) => {
    testTariffApi.sendRequest(params)
  }

  const rangesTodayStart = [
    {
      label: 'Today',
      value: startOfDay(new Date()),
    },
  ]
  const rangesTodayEnd = [
    {
      label: 'Today',
      value: endOfDay(new Date()),
    },
  ]

  const formId = 'tariff-testing-form'

  return (
    <div>
      { tariffApi.getResponse().loading
        ? <Loader center content="Loading..." />
        : (
          <Panel
            header={(
              <PanelHeader
                editing
                loading={testTariffApi.getResponse().loading}
                formId={formId}
                onCancel={cancel}
                header="Test tariff"
                saveButtonText="Run test"
              />
            )}
          >
            <Panel style={{ marginBottom: 20 }}>
              <Stack
                spacing={6}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <TariffIcon size="2em" />
                <div>
                  <b>Tariff: </b>
                  { tariffData.data?.name }
                </div>
              </Stack>
            </Panel>
            <Formik
              validate={validate}
              enableReinitialize
              initialValues={{
                tariffId: tariffId || '',
                locationId: locationId || '',
                entryDateTime: startOfDay(new Date()),
                exitDateTime: endOfDay(new Date()),
                group: initialValues.group,
                organisation: initialValues.organisation,
                cameraGroup: initialValues.cameraGroup,
              }}
              onSubmit={async (values, actions) => {
                const errs = validate(values)
                if (Object.keys(errs).length === 0) {
                  testCb({ ...values })
                  actions.setSubmitting(false)
                  actions.setTouched({})
                }
              }}
            >
              {
                ({ errors, touched }) => (
                  <Form className="noover" id={formId} autoComplete="off">
                    <Panel header="Enter test details:">
                      <Grid fluid>
                        <Row>
                          <Col xs={22} sm={10}>
                            <FieldWithFormik
                              required
                              errors={errors}
                              touched={touched}
                              name="entryDateTime"
                              label="Enter vehicle entry date and time:"
                              component={DatePicker}
                              defaultCalendarValue={[new Date('2022-02-01 00:00'),
                                new Date('2022-05-01 23:59')]}
                              format="yyyy-MM-dd HH:mm"
                              placeholder="Select Date"
                              style={{ maxWidth: 360, marginBottom: 18 }}
                              ranges={rangesTodayStart}
                            />

                          </Col>
                          <Col xs={22} sm={10}>
                            <FieldWithFormik
                              required
                              errors={errors}
                              touched={touched}
                              name="exitDateTime"
                              label="Enter vehicle exit date and time:"
                              component={DatePicker}
                              defaultCalendarValue={[new Date('2022-02-01 00:00'),
                                new Date('2022-05-01 23:59')]}
                              format="yyyy-MM-dd HH:mm"
                              placeholder="Select Date"
                              style={{ maxWidth: 360, marginBottom: 18 }}
                              ranges={rangesTodayEnd}
                            />

                          </Col>
                        </Row>
                        <Row>
                          <Col xs={22} sm={10}>
                            <FieldWithFormik
                              errors={errors}
                              touched={touched}
                              name="group"
                              label="Select the car park group:"
                              data={groups}
                              style={{ marginBottom: 18 }}
                              component={SelectPicker}
                            />
                          </Col>
                          <Col xs={22} sm={10}>
                            <FieldWithFormik
                              errors={errors}
                              touched={touched}
                              name="organisation"
                              label="Select the organisation:"
                              data={organisationGroups}
                              groupBy="role"
                              style={{ marginBottom: 18 }}
                              component={SelectPicker}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={22} sm={10}>
                            <FieldWithFormik
                              errors={errors}
                              touched={touched}
                              name="cameraGroup"
                              label="Select the camera groups:"
                              data={cameraGroups}
                              style={{ marginBottom: 18 }}
                              component={SelectPicker}
                            />
                          </Col>
                        </Row>
                      </Grid>
                    </Panel>
                    {
                      touched.cameraGroup
                        || touched.entryDateTime
                        || touched.exitDateTime
                        || touched.organisation
                        || touched.group
                        || (!testTariffApi.getResponse().loading
                        && !testTariffApi.getResponse().data)
                        ? <> </>
                        : (
                          <TestResults values={testTariffApi.getResponse().data} />
                        )
                    }
                  </Form>
                )
              }
            </Formik>
          </Panel>
        )}
    </div>
  )
}

export {
  LocationTariffTesting
}
