import React, { ReactElement } from 'react'
import { Tag } from 'rsuite'
import { TypeAttributes } from 'rsuite/esm/internals/types'

type Props = {
  access: string
}

function AccessTag (props: Props): ReactElement {
  const { access } = props

  let color: TypeAttributes.Color = 'yellow'

  switch (access) {
    case 'SUBSCRIPTION':
    case 'MEMBER':
      color = 'blue'
      break
    case 'CASUAL':
      color = 'green'
      break
    case 'ORGANISATION':
      color = 'cyan'
      break
    case 'ALLOWED':
    case 'ACCESSLIST':
      color = 'orange'
      break
    default:
      color = 'yellow'
      break
  }
  return <Tag color={color}>{access}</Tag>
}

export {
  AccessTag
}
