import { createQuery } from '../helpers'
import type { PaginatedResponse, Query } from '../../../types/api'
import { CreateVisitOverrideParams, VisitOverride } from '../../../types/visit-override'

/**
 * @var string
 * The properties to get back in any VisitOverride response
 */
const returnProps = `
    id
    rego
    location
    tariff
    startTime
    endTime
    comment
`

const createVisitOverride: Query<VisitOverride, CreateVisitOverrideParams> = createQuery(`
  mutation createVisitOverride(
    $rego: String!,
    $location: ID!, 
    $tariff: ID!,
    $startTime: AWSDateTime!,
    $comment: String,
  ) {
    createVisitOverride(
      rego: $rego,
      location: $location,
      tariff: $tariff,
      startTime: $startTime,
      comment: $comment,
    ) { ${returnProps} }
  }
`)

const getRegoVisitOverrides: Query<PaginatedResponse<VisitOverride>> = createQuery(`
query getRegoVisitOverrides($locationId: ID!, $rego: String, $limit: Int, $next: String) {
  getRegoVisitOverrides (location: $locationId, rego: $rego, next: $next, limit: $limit) { 
    items {
      ${returnProps} 
    }
    next
    limit
  }
}
`)

export {
  createVisitOverride,
  getRegoVisitOverrides
}
