/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  Panel, SelectPicker
} from 'rsuite'

import { UserRole } from '../../types/enums'
import { RequireRole } from '../access/RequireRole'

import { DeclinedPaymentsReport } from './DeclinedPaymentsReport'
import { PaymentsReport } from './PaymentsReport'
import { TotalMonthlySalesReport } from './TotalMonthlySalesReport'
import { DailyRevenueReport } from './DailyRevenueReport'
import { AveragePaymentPerSpaceReport } from './AveragePaymentPerSpaceReport'
import { TotalWeeklySalesReport } from './TotalWeeklySalesReport'
import { TotalDailySalesReport } from './TotalDailySalesReport'

const reportdata = [
  'Average monthly revenue',
  'Daily revenue (casuals)',
  'Declined transactions',
  'Transactions',
  'Total sales (monthly)',
  'Total sales (weekly)',
  'Total sales (daily)',
].map(
  (item) => ({ label: item, value: item })
)

function AdminFinanceReportsDetails () {
  const [report, setReport] = React.useState(null)

  // eslint-disable-next-line react/jsx-filename-extension
  let component = <div style={{ margin: 20 }}>No report selected</div>
  if (report === 'Declined transactions') {
    component = <DeclinedPaymentsReport />
  }
  if (report === 'Transactions') {
    component = <PaymentsReport />
  }
  if (report === 'Average monthly revenue') {
    component = <AveragePaymentPerSpaceReport />
  }
  if (report === 'Total sales (monthly)') {
    component = <TotalMonthlySalesReport />
  }
  if (report === 'Total sales (weekly)') {
    component = <TotalWeeklySalesReport />
  }
  if (report === 'Total sales (daily)') {
    component = <TotalDailySalesReport />
  }
  if (report === 'Daily revenue (casuals)') {
    component = <DailyRevenueReport />
  }

  return (
    <RequireRole roles={[UserRole.ORGANISATION, UserRole.ADMIN]}>
      <Panel header={(<h2>Finance reports</h2>)}>
        <div style={{ width: 200, marginBottom: 12 }}>
          Select a report:
          <SelectPicker onChange={setReport} value={report} data={reportdata as any} block />
        </div>
        <hr />

        {component}

      </Panel>
    </RequireRole>
  )
}

export {
  AdminFinanceReportsDetails
}
