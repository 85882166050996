/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  FieldInputProps, FormikProps
} from 'formik'
import {
  Table, Button, InputPicker, Input, InputNumber, InputGroup
} from 'rsuite'

import { useWindowDimensions } from '../../helpers'

const { Column, HeaderCell, Cell } = Table
const DEFAULT_DATA = {
  step: 0, period: 0, unit: '', rate: 0,
}

interface RowData {
  step: number
  period: number
  unit: string
  rate: number
}

function removeAtIndex<T> (arr: T[], index: number): T[] {
  // gracefully reset table if something went wrong
  if (index < 0 || index >= arr.length) {
    return []
  }

  const nextData = arr.filter((_, i) => i !== index).map((item, i) => ({ ...item, step: i + 1 }))
  return nextData
}

function InputCell (
  {
    rowData, dataKey, updateRow, inputAddon, ...props
  }: { rowData?: RowData, dataKey: string, inputAddon?: string, updateRow: any }
) {
  const data = rowData || DEFAULT_DATA

  return (
    <Cell {...props}>
      <InputGroup inside>
        {inputAddon ? <InputGroup.Addon>{inputAddon}</InputGroup.Addon> : null}
        <Input
          defaultValue={data[dataKey as keyof RowData]}
          onChange={(value) => {
            updateRow({ ...rowData, [dataKey]: value })
          }}
        />
      </InputGroup>
    </Cell>
  )
}

function InputNumberCell (
  {
    rowData, dataKey, updateRow, prefix, ...props
  }: { rowData?: RowData, dataKey: string, prefix?: string, updateRow: any }
) {
  const data = rowData || DEFAULT_DATA

  return (
    <Cell {...props}>
      <InputNumber
        defaultValue={data[dataKey as keyof RowData]}
        prefix={prefix}
        onChange={(value) => {
          updateRow({ ...rowData, [dataKey]: Number(value).toFixed(2) })
        }}
      />
    </Cell>
  )
}

function InputPickerCell (
  {
    rowData, dataKey, updateRow, ...props
  }: { rowData?: RowData, dataKey: string, updateRow: any }
) {
  const data = rowData || DEFAULT_DATA

  const options = [
    'minutes',
    'hours',
    'days',
  ].map((item) => ({ label: item, value: item }))

  return (
    <Cell {...props}>
      <InputPicker
        defaultValue={data[dataKey as keyof RowData]}
        onChange={(value) => {
          updateRow({ ...rowData, [dataKey]: value })
        }}
        data={options}
      />
    </Cell>
  )
}

function EditableTable ({
  field, form,
}: { field: FieldInputProps<'incrementalRate'>, form: FormikProps<any>}) {
  const { incrementalRate: data } = form.values
  const { innerHeight } = useWindowDimensions()

  const handleUpdateRow = (rowData: RowData) => {
    const nextData = [...data]
    const itemIndex = nextData.findIndex((item) => item.step === rowData.step)
    if (itemIndex >= 0) nextData[itemIndex] = { ...rowData }
    form.setFieldValue(field.name, nextData)
  }

  return (
    <>
      <Table
        rowHeight={64}
        autoHeight
        data={data}
      >
        <Column width={50} align="center" verticalAlign="middle" fixed>
          <HeaderCell>Step</HeaderCell>
          <Cell dataKey="step" />
        </Column>

        <Column flexGrow={1} verticalAlign="middle">
          <HeaderCell>Duration</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="period" />
        </Column>

        <Column flexGrow={1} verticalAlign="middle">
          <HeaderCell>Unit</HeaderCell>
          <InputPickerCell updateRow={handleUpdateRow} dataKey="unit" />
        </Column>

        <Column width={130} verticalAlign="middle">
          <HeaderCell>Rate ($)</HeaderCell>
          <InputNumberCell updateRow={handleUpdateRow} dataKey="rate" prefix="$" />
        </Column>
        <Column width={80}>
          <HeaderCell>...</HeaderCell>
          <Cell className="celllink">
            {(rowData) => (rowData.step === data.length ? (
              <Button
                appearance="link"
                onClick={() => {
                  // TODO: Figure out why the data and UI are out of sync when we allow user to delete any row
                  const newData: any[] = removeAtIndex(data, rowData.step - 1)
                  form.setFieldValue(field.name, newData)
                }}
              >
                Delete
              </Button>
            ) : null)}
          </Cell>
        </Column>
      </Table>
      <Button
        style={{ borderRadius: 0, margin: '20px' }}
        onClick={() => form.setFieldValue(field.name, [...data, { ...DEFAULT_DATA, step: data.length + 1 }])}
      >
        Add rate
      </Button>
    </>
  )
}

export default EditableTable
