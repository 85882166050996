import React from 'react'
import { Input, InputGroup } from 'rsuite'
import SearchIcon from '@rsuite/icons/Search'

type Props = {
  value: string,
  onChange: (value: string) => void,
  placeholder?: string,
  styles?: any,
}

export function SearchBar ({
  placeholder, styles, value, onChange,
}: Props) {
  return (
    <InputGroup size="md" style={styles}>
      <Input style={{ marginTop: 2 }} placeholder={placeholder} value={value} onChange={onChange} />
      <InputGroup.Addon style={{ height: 38 }}>
        <SearchIcon />
      </InputGroup.Addon>
    </InputGroup>
  )
}
