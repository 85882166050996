import { FieldProps } from 'formik'
import {
  DatePicker as RsDatePicker,
  DatePickerProps as RsDatePickerProps
} from 'rsuite'

import formikCompatible from './formikCompatible'

export interface DateRangeProps
  extends FieldProps<[Date]>,
  RsDatePickerProps {}

export const DatePicker = formikCompatible(RsDatePicker)
