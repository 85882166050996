/* eslint-disable camelcase */

// AWS Amplify configuration for user system (Cognito) and GraphQL
import { ResourcesConfig } from 'aws-amplify'

const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || '',
      userPoolClientId: process.env.REACT_APP_COGNITO_WEBCLIENT_ID || '',
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID || '',
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_TICKETLESS_API_ENDPOINT || '',
      region: process.env.REACT_APP_AWS_REGION,
      defaultAuthMode: 'iam',
    },
  },
}

export {
  config
}
