import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Panel, IconButton, ButtonToolbar, Table
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { usePageTitle, useApi } from '../../app/hooks'
import { getOrganisations } from '../../services/graphql/queries'
import { TableData } from '../../components'
import type { RowData } from '../../types/table'

/**
 * Organisation list component
 * @return {ReactElement}
 */
function OrganisationList (): ReactElement {
  usePageTitle('Organisations')
  const api = useApi(getOrganisations)
  const organisationData = api.getResponse()

  useEffect(() => {
    api.sendRequest()
    return api.cleanup
  }, [])

  /**
   * Render a name cell for a single organisation
   * @param {Organisation} organisation
   * @return {ReactElement}
   */
  const renderName = (organisation: RowData): ReactElement => (
    <Link to={organisation.id}>{organisation.name}</Link>
  )

  const organisations = organisationData.success ? organisationData.data?.items : []

  return (
    <Panel
      header={(
        <>
          <h2>Organisations</h2>
          <ButtonToolbar>
            <IconButton
              appearance="subtle"
              icon={<Icon as={FaPlus} />}
              as={Link}
              to="new"
            />
          </ButtonToolbar>
        </>
      )}
      className="organisation-list"
    >
      <Table
        autoHeight
        wordWrap="break-word"
        data={organisations || []}
        loading={organisationData.loading}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Organisation name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
      </Table>
    </Panel>
  )
}

export {
  OrganisationList
}
