import React, { ReactElement, ReactNode } from 'react'

import './CardGrid.css'

type Props = {
  children: ReactNode,
}

/**
 * Card grid component
 * @return {ReactElement}
 */
function CardGrid (props: Props): ReactElement {
  const { children } = props
  return (
    <div className="card-grid">
      {children}
    </div>
  )
}

export {
  CardGrid
}
