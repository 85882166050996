import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { AccountDetails } from '../../features/account/AccountDetails'
import { ThreeDSResult } from '../../features/account/ThreeDSResult'
import { VerifyEmail } from '../../features/account/VerifyEmail'

/**
 * AccountPage route
 * @return {ReactElement}
 */
function AccountPage (): ReactElement {
  return (
    <Routes>
      <Route path="/" element={<AccountDetails />} />
      <Route path="/3ds" element={<ThreeDSResult />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
    </Routes>
  )
}

export {
  AccountPage
}
