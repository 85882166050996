/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Field } from 'formik'
import {
  Form as RForm
} from 'rsuite'

interface FieldWithFormikProps {
  name: string;
  label: string;
  errors: {[key: string]:any};
  touched: {[key: string]:any};
  component: React.ElementType;
  required?: boolean;
  [key: string]: any;
}

function FieldWithFormik ({
  errors,
  touched,
  name,
  label,
  required,
  component,
  ...props
}: FieldWithFormikProps): JSX.Element {
  const showError = errors[name] && touched[name]
  return (
    <>
      <RForm.ControlLabel htmlFor={name} className="rs-form-control-label">
        {label}
      </RForm.ControlLabel>
      {required && <RForm.HelpText>Required</RForm.HelpText>}
      <Field
        id={name}
        name={name}
        style={{ marginBottom: showError ? 0 : 40 }}
        component={component}
        placement="auto"
        {...props}
      />
      {showError ? (
        <p style={{
          color: '#c9444d',
          fontSize: '0.75rem',
          marginTop: '0.25rem',
          marginBottom: '1.5rem',
        }}
        >
          {errors[name]}
        </p>
      ) : null}
    </>
  )
}

export default FieldWithFormik
