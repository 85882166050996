import { SubscriptionStatus } from './enums'

/**
 * Determines if status is of type `SubscriptionStatus`
 * @param {any} status - the status to check
 */
function isSubscriptionStatus (status:any): status is SubscriptionStatus {
  return status === SubscriptionStatus.ACTIVE
    || status === SubscriptionStatus.REJECTED
      || status === SubscriptionStatus.CANCELLED
        || status === SubscriptionStatus.PENDING
          || status === SubscriptionStatus.AMEND
}

export {
  isSubscriptionStatus
}
