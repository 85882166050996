import { Icon } from '@rsuite/icons'
import React, { ReactElement } from 'react'
import { FaCar, FaCreditCard, FaMagic } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { useModal, usePageTitle } from '../../app/hooks'
import { useCustomerDashboardData } from '../../app/hooks/customerDashboard'
import { CenteredLoader, Modal } from '../../components'
import { CustomerDashboard } from '../../features/dashboard/CustomerDashboard'
import { MissingPaymentMethodModal } from '../../features/account/MissingPaymentMethodModal'
import { useFeatureFlag } from '../../app/hooks/featureFlag'

/**
 * Dashboard route
 * @return {ReactElement}
 */
function Dashboard (): ReactElement {
  usePageTitle('Dashboard')

  const { vehicles, paymentMethod, subscriptions } = useCustomerDashboardData()
  const navigate = useNavigate()

  const loading = !vehicles.sent
  || vehicles.loading
  || paymentMethod.loading
  || !paymentMethod.sent
  || !subscriptions.sent
  || subscriptions.loading

  const vehiclesActive = !!vehicles.data?.items?.length
  const subscriptionsActive = !!subscriptions.data?.items?.length
  const paymentMethodExists = !!paymentMethod.data

  const vehicleIcon = <Icon as={FaCar} />
  const subscriptionIcon = <Icon as={FaMagic} />
  const paymentIcon = <Icon as={FaCreditCard} />

  const modal = useModal()

  const addSubscriptionCb = () => {
    if (paymentMethod.data !== null) { navigate('/subscriptions/new') }
    modal.show()
    // showModal({
    //   onOk: () => { navigate('/account') },
    // })
  }

  const addPaymentMethodCb = () => {
    navigate('/account')
  }

  const addVehicleCb = () => {
    navigate('/vehicles/new')
  }

  const vehicleMangeRoute = '/vehicles'
  const subscriptionsManageRoute = '/subscriptions'
  const { enabled: useCasualDashboard } = useFeatureFlag('useCasualDashboard')
  const { enabled: showSubscriptionBanner } = useFeatureFlag('showSubscriptionBanner')

  return (
    <>
      { loading ? <CenteredLoader /> : (
        <CustomerDashboard
          casualDashboard={useCasualDashboard}
          showSubscriptionHeader={showSubscriptionBanner}
          addSubscriptionCb={addSubscriptionCb}
          addPaymentMethodCb={addPaymentMethodCb}
          addVehicleCb={addVehicleCb}
          vehiclesActive={vehiclesActive}
          vehicleIcon={vehicleIcon}
          subscriptionsActive={subscriptionsActive}
          subscriptionIcon={subscriptionIcon}
          paymentIcon={paymentIcon}
          paymentMethodExists={paymentMethodExists}
          vehicles={{
            data: vehicles.data?.items || [],
            addItemCb: addVehicleCb,
            title: 'Vehicles',
            description: '',
            manageRoute: vehicleMangeRoute,
            addItemLabel: 'Add a vehicle',
            addItemDescription: 'Add as many vehicles as you please',
            itemIcon: vehicleIcon,
          }}
          subscriptions={{
            data: subscriptions.data?.items || [],
            addItemCb: addSubscriptionCb,
            title: 'Subscriptions',
            description: '',
            manageRoute: subscriptionsManageRoute,
            addItemDescription: 'Subscribe to a Noover car park of your choice',
            addItemLabel: 'Add a subscription',
            itemIcon: subscriptionIcon,
          }}
        />
      )}
      <Modal hook={modal}>
        <MissingPaymentMethodModal onClose={modal.hide} />
      </Modal>
    </>
  )
}

export {
  Dashboard
}
