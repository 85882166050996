import React from 'react'
import {
  FieldProps
} from 'formik'
import { Input as RsInput, InputProps as RsInputProps } from 'rsuite'

export interface InputProps extends FieldProps<string>, Omit<RsInputProps, 'form'> {}

function Input ({
  field, form, onChange, ...props
}: InputProps) {
  return (
    <RsInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
      onChange={(newValue, event) => {
        form.setFieldValue(field.name, newValue)
        onChange?.(newValue, event)
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export { Input }
