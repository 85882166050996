import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import { useUser } from '../../app/hooks'
import { UserRole } from '../../types/enums'

/**
 * Login redirect handler
 * @return {ReactElement}
 */
function LoginRedirect (): ReactElement {
  const user = useUser()
  let path = '/'
  if (user.hasRole(UserRole.ADMIN)) path = '/admin/locations'
  if (user.hasRole(UserRole.OPERATOR)) path = '/operator/locations'
  if (user.hasRole(UserRole.ATTENDANT)) path = '/attendant/users'
  return <Navigate to={path} />
}

export {
  LoginRedirect
}
