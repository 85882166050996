import { createQuery } from '../helpers'
import type {
  PaymentMethod, GetPaymentTokenisationUrlResponse,
  GetPaymentTokenisationUrlParams,
  UpdateCardVerificationSessionParams,
  ConfirmCardVerificationParams
} from '../../../types/payment-method'
import type { Query } from '../../../types/api'

/**
 * @var string
 * The properties to get back in any payment method response
 */
const returnProps = `
  last4
  expiry
  expired
`

const getPaymentMethod: Query<PaymentMethod | null, { userId: string }> = createQuery(`
  query getUserPaymentMethod($userId: ID!) {
    getUserPaymentMethod(user: $userId) { ${returnProps} }
  }
`)

const setPaymentMethod: Query<PaymentMethod, {
  userId: string,
  sessionId: string,
  last4: string
}> = createQuery(`
  mutation setUserPaymentMethod(
    $userId: ID!,
    $sessionId: String!,
    $last4: String!,
  ) {
    setUserPaymentMethod(
      user: $userId,
      session: $sessionId,
      last4: $last4,
    ) { ${returnProps} }
  }
`)

const confirmCardVerificationQuery = `
  mutation confirmCardVerification(
    $userId: ID!,
    $verificationId: ID!,
    $verificationResult: CardVerificationResult!
  ){
    confirmCardVerification(
      user: $userId,
      verificationId: $verificationId,
      verificationResult: $verificationResult,
    )
  }
`
const confirmCardVerification:
Query<boolean, ConfirmCardVerificationParams> = createQuery(confirmCardVerificationQuery)

const updateCardVerificationSession: Query<boolean, UpdateCardVerificationSessionParams> = createQuery(`
  mutation updateCardVerificationSession(
    $userId: ID!,
    $sessionId:ID!,
    $verificationId: ID!,
    $last4: String!
  ) {
    updateCardVerificationSession(
      user:$userId,
      sessionId:$sessionId,
      verificationId:$verificationId,
      last4:$last4,
    )
  }
`)

const removePaymentMethod: Query<void, { userId: string }> = createQuery(`
  mutation removeUserPaymentMethod($userId: ID!) {
    removeUserPaymentMethod(user: $userId)
  }
`)

const getPaymentTokenisationUrl: Query<
GetPaymentTokenisationUrlResponse,
GetPaymentTokenisationUrlParams
> = createQuery(`
  query getPaymentTokenisationUrl($userId: ID!) {
    getPaymentTokenisationUrl(user: $userId) {
      url,
      verificationId
    }
  }
`)

export {
  confirmCardVerification,
  updateCardVerificationSession,
  getPaymentMethod,
  setPaymentMethod,
  removePaymentMethod,
  getPaymentTokenisationUrl
}
