import { LocationWithDefaultPrice, Location, Carpark } from './location'

/**
 * Turns a {@link Location} into a {@link locationToLocationWithDefaultPrice}. Will however throw a
 * type error if no pricing items are found in the location object.
 * @param {Location} location - The object to transform
 * @return {LocationWithDefaultPrice}
 */
function locationToLocationWithDefaultPrice (location:Location):LocationWithDefaultPrice {
  if (!location.pricing.length) {
    throw new TypeError(
      'No pricing items found in location, please enter a location with at least 1 pricing item'
    )
  }
  return {
    ...location,
    pricing: location.pricing[0],
  }
}

/**
 * Determine whether a location is a Carpark
 * @param {*} location
 * @return {boolean}
 */
function locationIsCarpark (location?: any): location is Carpark {
  return typeof location?.capacity === 'object'
}

export {
  locationIsCarpark,
  locationToLocationWithDefaultPrice
}
