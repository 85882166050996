import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner'

import { useUser, useApi } from '../../app/hooks'
import { getOutstandingPayments } from '../../services/graphql/queries'

/**
 * Payment reminder alert trigger component
 * Checks for outstanding payments on the API and triggers an alert if any are found
 * @return {ReactElement}
 */
function PaymentReminder (): null {
  const user = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  const paymentQuery = useApi(getOutstandingPayments)

  useEffect(() => {
    paymentQuery.sendRequest({ userId: user.id })
  }, [])

  const payments = paymentQuery.getResponse().data || []
  useEffect(() => {
    if (
      !location.pathname.startsWith('/payments/outstanding')
      && payments.length > 0
    ) {
      const message = `You have ${payments.length} outstanding payment${payments.length === 1 ? '' : 's'}
        on your account. You may not be able to park with Noover until this has been resolved.`

      toast.warning('Access suspended', {
        description: message,
        duration: 10000,
        cancel: {
          label: 'Pay',
          onClick: () => navigate('/payments/outstanding'),
        },
      })
    }
  }, [payments])

  return null
}

export {
  PaymentReminder
}
