/* eslint-disable camelcase */
import { Amplify } from 'aws-amplify'
import * as Auth from 'aws-amplify/auth'

import { config } from '../config'
import { LoginStatus, UserRole } from '../../types/enums'
import { mapAttributeNames } from './helpers'
import type {
  UserDetails, AuthUser, Credentials
} from '../../types/user'

Amplify.configure(config)

// Error response codes
const errors = {
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
}

/**
 * Create a new Cognito user
 * @param {UserDetails} details
 * @param {Credentials} credentials
 * @return {Promise<AuthUser>}
 */
const createUser = async (details: UserDetails, credentials: Credentials): Promise<AuthUser> => {
  const response = await Auth.signUp({
    username: credentials.username,
    password: credentials.password,
    options: {
      userAttributes: {
        email: details.email,
        phone_number: details.phone,
        given_name: details.givenName,
        family_name: details.familyName,
      },
    },
  })
  return {
    id: response.userId || '',
    login: LoginStatus.UNCONFIRMED,
    emailVerified: false,
    roles: [],
  }
}

/**
 * Confirm a new Cognito user account
 * @param {string} username
 * @return {Promise}
 */
const confirmUser = async (username: string, confirmationCode: string): Promise<void> => {
  await Auth.confirmSignUp({ username, confirmationCode, options: { forceAliasCreation: true } })
}

/**
 * Request a new account confirmation code
 * @param {string} username
 * @return {Promise}
 */
const resendConfirmationCode = async (username: string): Promise<void> => {
  await Auth.resendSignUpCode({ username })
}

/**
 * Get the current user from the Amplify session
 * @return {Promise<AuthUser>}
 */
const getCurrentUser = async (): Promise<AuthUser> => {
  const user = await Auth.getCurrentUser()
  const session = await Auth.fetchAuthSession()
  const attributes = await Auth.fetchUserAttributes()
  const response = { ...user, ...session, attributes }
  return {
    id: response.username,
    login: LoginStatus.AUTHENTICATED,
    roles: response.tokens?.accessToken.payload['cognito:groups'] as UserRole[],
    email: response.attributes.email,
    givenName: response.attributes.given_name,
    familyName: response.attributes.family_name,
    emailVerified: response.attributes?.email_verified === 'true',
    phone: response.attributes.phone_number,
    adminOrganisationId: response.attributes.adminOrganisationId,
  }
}

/**
 * Authenticate an existing user
 */
const authenticateUser = async ({ username, password }: Credentials): Promise<AuthUser> => {
  await Auth.signIn({ username, password })
  return getCurrentUser()
}

/**
 * Begins the email verification flow by sending the current user a code via email
 */
const startVerifyEmailFlow = async () => {
  await Auth.sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
}

/**
 * Confirms the email verification code by submitting the verification code
 * @param {String} code - The code recieved via email
 */
const submitEmailVerificationCode = async (confirmationCode: string) => {
  await Auth.confirmUserAttribute({ userAttributeKey: 'email', confirmationCode })
}

/**
 * Request a password reset for a user
 * @param {string} username
 * @return {Promise}
 */
const resetPassword = async (username: string): Promise<void> => {
  await Auth.resetPassword({ username })
}

/**
 * Set a new password for a user after initiating a reset
 * @param {string} username
 * @param {string} newPassword
 * @param {string} confirmationCode
 * @return {Promise}
 */
const setPassword = (username: string, newPassword: string, confirmationCode: string): Promise<void> => (
  // The forgotPasswordSubmit incorrectly reports its resolve type as string
  Auth.confirmResetPassword({ username, confirmationCode, newPassword }).then(() => {})
)

/**
 * Update attributes for the current user
 * @param {Object} user
 * @return {Promise}
 */
const updateUser = async (details: Partial<UserDetails>): Promise<void> => {
  await Auth.updateUserAttributes({ userAttributes: mapAttributeNames(details) })
}

/**
 * Clear the current user session
 * @return {Promise}
 */
const clearUser = async (): Promise<void> => (
  Auth.signOut()
)

export {
  errors,
  createUser,
  confirmUser,
  resendConfirmationCode,
  authenticateUser,
  getCurrentUser,
  resetPassword,
  setPassword,
  startVerifyEmailFlow,
  submitEmailVerificationCode,
  updateUser,
  clearUser
}
