import { UserDTO } from '../../types/user'

export function formatUserDisplayName (user?: UserDTO) {
  return `${user?.givenName} ${user?.familyName}`
}

export function formatUserRoles (user?: UserDTO) {
  if (!user?.groups?.length) {
    return ''
  }
  let rolesLabel = ''
  user.groups.forEach((e:string, i:number) => {
    let roleName = e.replace(/s$/, '')
    if (i !== (user.groups?.length || 0) - 1) {
      roleName += ', '
    }
    rolesLabel += roleName
  })
  return rolesLabel
}
