import React from 'react'
import { Panel, Table } from 'rsuite'

import { useApi, usePageTitle } from '../../app/hooks'
import { DefinitionList, TableData } from '../../components'

import { RequireRole } from '../../features/access/RequireRole'
import { getReportingData } from '../../services/graphql/queries'
import { UserRole } from '../../types/enums'

/**
 *  Reporting page for organisation and global admins
 *  @return {React.ReactElement}
 */
function ReportingPage (): React.ReactElement {
  const dataQuery = useApi(getReportingData, {
    // this is disgusting will retype it at some point
    initialFetchParams: undefined,
    initialFetch: true,
    cleanUpOnDismount: true,
  })
  const res = dataQuery.getResponse()

  const subscriptionDefinition = {
    'Active subscriptions': res.data?.subscriptionsTotal ?? 0,
    'Active subscriptions currently at a car park': res.data?.subscriptionsAtCarpark ?? 0,
  }

  const isCustomerDataAvailable = typeof res.data?.customersTotal === 'number'
  && typeof res.data?.customersWithSubscriptionTotal === 'number'

  const customerDefinition = {
    Customers: res.data?.customersTotal ?? 0,
    'Customers with an active subscription': res.data?.customersWithSubscriptionTotal ?? 0,
  }

  usePageTitle('Reporting')

  return (
    <RequireRole roles={[UserRole.OPERATOR]}>
      <Panel header={(<h2>Metrics</h2>)}>
        <DefinitionList data={subscriptionDefinition} loading={res.loading} />
        {
          isCustomerDataAvailable && <DefinitionList data={customerDefinition} />
        }
      </Panel>
      <Panel header={(<h2>Subscription breakdown</h2>)}>
        <Table
          autoHeight
          wordWrap="break-word"
          data={res.data?.subscriptionBreakdown || []}
          loading={res.loading}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Car park</Table.HeaderCell>
            <TableData dataKey="location" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Plan</Table.HeaderCell>
            <TableData dataKey="plan" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Num. subscriptions</Table.HeaderCell>
            <TableData dataKey="subscriptionCount" />
          </Table.Column>
        </Table>
      </Panel>
    </RequireRole>
  )
}

export {
  ReportingPage
}
