import { useSelector } from '.'
import { userSelectors } from '../../store/user/slice'
import { UserRole } from '../../types/enums'

const EMPLOYEE_ROLES = [UserRole.ADMIN, UserRole.OPERATOR, UserRole.ATTENDANT]

/**
 * A hook for getting read-only details of the current user. For full
 * control over the user data, use the user store directly
 */
const useUser = () => {
  const user = useSelector(userSelectors.getAuthUser)

  /**
   * Check if the current user has a role
   * @param {UserRole[]} roles - One or more roles to check
   * @return {boolean}
   */
  const hasRole = (...roles: Array<UserRole>): boolean => (
    !!user && roles.some((r) => (user.roles || []).includes(r))
  )

  /**
   * Return users employee role
   * @return {string | undefined} The users employee role, or undefined.
   */
  const getRoleForRoute = () => {
    const role = user.roles.find((r) => EMPLOYEE_ROLES.includes(r))
    if (!role) return undefined
    return role.slice(0, -1).toLowerCase()
  }

  return {
    ...user,
    hasRole,
    getRoleForRoute,
  }
}

export {
  useUser
}
