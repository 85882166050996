/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Loader } from 'rsuite'

import {
  usePageTitle, useFormState, useApi, useApiGroup
} from '../../app/hooks'
import { getOrganisation, createOrganisation, updateOrganisation } from '../../services/graphql/queries'
import { CreateOrganisationParams as OrganisationDetailsType } from '../../types/organisation'
import { NotFound } from '../error-pages/NotFound'
import { OrganisationDefinition } from './OrganisationDefinition'
import { OrganisationEdit } from './OrganisationEdit'

/**
 * Create/edit organisation component
 * @return {ReactElement}
 */
function OrganisationDetails (): ReactElement {
  const [editing, setEditing] = useState(false)
  const { organisationId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getOrganisation),
    create: useApi(createOrganisation),
    update: useApi(updateOrganisation),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = organisationId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  const initialValues = organisationId ? fetchResponse.data : { name: '', description: '' }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (organisationId) {
      api.requests.fetch.sendRequest({ organisationId })
    }
    return () => {
      api.requests.fetch.cleanup()
    }
  }, [])

  const onEdit = () => {
    setEditing(true)
  }

  const onEditFinish = () => {
    setEditing(false)
    if (organisationId) {
      api.requests.fetch.sendRequest({ organisationId })
    }
  }
  return (
    <>
      {(fetchResponse.data === null || fetchResponse.loading) && <Loader center />}
      { !editing && fetchResponse.data && (
        <OrganisationDefinition
          onEdit={onEdit}
          organisation={fetchResponse.data}
        />
      ) }
      { editing && fetchResponse.data && (
        <OrganisationEdit organisation={fetchResponse.data} onFinish={onEditFinish} />
      )}
    </>
  )
}

export {
  OrganisationDetails
}
