import React from 'react'

import { Form, InputPicker } from 'rsuite'

import { ValidatedForm } from '../components'

import { FormProps } from '../types/form'
import { Validator } from './validation/validator'
import * as schemas from './validation/schemas'

type EditSubscriptionFormProps = FormProps & {
  pricingPlans: {value: string, label: string}[]
}

/**
 * Form for an admin to edit a customer's subscription
 * @return {React.ReactElement}
 */
export function EditSubscriptionForm (props: EditSubscriptionFormProps): React.ReactElement {
  const validator = new Validator({
    vehicles: {
      model: schemas.positiveInt('Please enter a valid vehicle count'),
    },

  })

  const {
    formValue,
    formId,
    onChange,
    onSubmit,
    error,
    pricingPlans,
  } = props
  return (
    <ValidatedForm
      fluid
      id={formId}
      onSubmit={onSubmit}
      validator={validator}
      onChange={onChange}
      error={error}
      formValue={formValue}
    >
      <Form.Group controlId="vehicles">
        <Form.ControlLabel>Vehicle bays</Form.ControlLabel>
        <Form.Control name="vehicles" type="text" />
      </Form.Group>
      <Form.Group controlId="plan">
        <Form.ControlLabel>Plan</Form.ControlLabel>
        <Form.Control name="plan" accepter={InputPicker} data={pricingPlans} />
      </Form.Group>
    </ValidatedForm>
  )
}
