import React, { ReactElement, useState } from 'react'
import { Panel } from 'rsuite'

import { useSelector, useFormState, useDispatch } from '../../app/hooks'
import { userSelectors, userActions } from '../../store/user/slice'
import { DefinitionList, PanelHeader } from '../../components'
import { UserDetailsForm } from '../../forms/UserDetailsForm'

/**
 * User details component
 * @return {ReactElement}
 */
function UserDetails (): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector(userSelectors.getAuthUser)
  const status = useSelector(userSelectors.getStatus)

  const [editing, setEditing] = useState(false)
  const [formData, setField] = useFormState(user)

  const userData = status.loading ? {} : {
    Name: `${user.givenName} ${user.familyName}`,
    Email: user.email,
    'Phone number': user.phone,
  }

  /**
   * Enter edit mode
   */
  const editOn = () => setEditing(true)

  /**
   * Exit edit mode
   */
  const editOff = () => setEditing(false)

  /**
   * Save the user details
   */
  const save = () => {
    const { ...data } = formData
    dispatch(userActions.update(data)).then(editOff)
  }

  const formId = 'user-details-form'

  return (
    <Panel
      header={(
        <PanelHeader
          loading={status.loading}
          editing={editing}
          onEdit={editOn}
          formId={formId}
          onCancel={editOff}
          header="Account details"
        />
      )}
    >
      {!editing ? (
        <DefinitionList data={userData} loading={status.loading} />
      ) : (
        <UserDetailsForm
          formId={formId}
          formValue={formData}
          onChange={setField}
          onSubmit={save}
          error={status.error}
        />
      )}
    </Panel>
  )
}

export {
  UserDetails
}
