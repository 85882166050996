import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { UserRole } from '../../types/enums'
import { AdminUserReportsDetails } from '../../features/admin-reports/AdminUserReportsDetails'
import { AdminOccupancyReportsDetails } from '../../features/admin-reports/AdminOccupancyReportsDetails'
import { AdminOperationsReportsDetails } from '../../features/admin-reports/AdminOperationsReportsDetails'
import { AdminFinanceReportsDetails } from '../../features/admin-reports/AdminFinanceReportsDetails'

/**
 * ReportsPage route
 * @return {ReactElement}
 */
function ReportingPage (): ReactElement {
  return (
    <RequireRole roles={[UserRole.ADMIN, UserRole.ORGANISATION, UserRole.OPERATOR]}>
      <Routes>
        <Route
          path="/users"
          element={<AdminUserReportsDetails />}
        />
        <Route
          path="/occupancy"
          element={<AdminOccupancyReportsDetails />}
        />
        <Route
          path="/finance"
          element={<AdminFinanceReportsDetails />}
        />
        <Route
          path="/operations"
          element={<AdminOperationsReportsDetails />}
        />
      </Routes>
    </RequireRole>
  )
}

export {
  ReportingPage
}
