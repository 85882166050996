import React, { ReactElement } from 'react'

import { Button, ButtonToolbar, Form } from 'rsuite'

import { FormControlCheckbox, ValidatedForm } from '../components'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import type { FormProps } from '../types/form'

const AGREEMENT_DOCUMENT_LINK = 'https://noover.com/portal-terms'

/**
 * Confirm subscription form component
 * @return {ReactEelement}
 */
function ConfirmSubscriptionForm (props: FormProps): ReactElement {
  const {
    formValue,
    onChange,
    onSubmit,
    loading,
    error,
  } = props

  const validator = new Validator({
    numVehicles: {
      model: schemas.positiveInt('You must subscribe for at least 1 vehicle'),
    },
    agreementCheckbox: {
      model: schemas.checkedBox('You must accept the terms and conditions to create a subscription'),
    },

  })

  return (
    <ValidatedForm
      fluid
      validator={validator}
      formValue={formValue}
      onSubmit={onSubmit}
      onChange={onChange}
      error={error}
    >
      <Form.Group controlId="numVehicles">
        <Form.ControlLabel> Number of bays </Form.ControlLabel>
        <Form.Control name="numVehicles" type="number" />
      </Form.Group>
      <Form.Group controlId="agreementCheckbox">
        <FormControlCheckbox
          checkboxProps={{ name: 'agreementCheckbox' }}
          formControlProps={{ name: 'agreementCheckbox' }}
        >
          I accept the following
          {' '}
          <a rel="noopener noreferrer" target="_blank" href={AGREEMENT_DOCUMENT_LINK}>terms and conditions</a>
          .
        </FormControlCheckbox>
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" loading={loading} type="submit">Subscribe</Button>
        </ButtonToolbar>
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  ConfirmSubscriptionForm
}
